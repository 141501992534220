import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { COMPOSITION_BUFFER_MODE, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './login/login.module';
import { MainModule } from './main/main.module';
import { UtilsService } from './shared/utils.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { GoodsModule } from './goods/goods.module';
import { EnvModule } from './env/env.module';
import { HelpsModule } from './helps/helps.module';
// import { SideComponent } from './commons/side/side.component';
import { SharedModule } from '../app/shared/shared.module';
import { Routes, RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'assets/**', redirectTo: '/assets', pathMatch: 'full' },
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
  //   { path: '', component: LoginComponent },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterModule.forRoot(routes),
    BrowserModule,
    AppRoutingModule,
    LoginModule,
    FormsModule,
    MainModule,
    GoodsModule,
    EnvModule,
    HelpsModule,
    SharedModule,
    BrowserAnimationsModule, // required for toastr animations

    ToastrModule.forRoot({
      timeOut: 1000, // Duration in milliseconds
      positionClass: 'toast-top-right', // Position of the toast
      preventDuplicates: true, // Prevent duplicate toasts
    }), // ToastrModule added
    NgxSpinnerModule,
  ],
  providers: [
    UtilsService,
    { provide: COMPOSITION_BUFFER_MODE, useValue: false },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
