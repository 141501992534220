import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CategoryComponent } from './category/category.component';
import { GoodsComponent } from './goods/goods.component';
import { MiddleCategoryComponent } from './middle-category/middle-category.component';
import { SmallCategoryComponent } from './small-category/small-category.component';
import { MygoodsComponent } from './mygoods/mygoods.component';

const routes: Routes = [
  {
    path: 'goods',
    children: [
      {
        path: 'category',
        component: CategoryComponent,
      },
      {
        path: 'middlecategory',
        component: MiddleCategoryComponent,
      },
      {
        path: 'smallcategory',
        component: SmallCategoryComponent,
      },
      {
        path: 'goods',
        component: GoodsComponent,
      },
      {
        path: 'mygoods',
        component: MygoodsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GoodsRoutingModule {}
