<div class="login_wrap">
  <div class="login_cont">
    <div class="logo">
      <!-- <img [src]="'assets/img/logo.png'" alt="HANASIS" /> -->
      <img class="login-logo" alt="HANASIS" />
      <!-- <img [src]="logoUrl" alt="HANASIS" /> -->
      <!-- <div class="login-logo"></div> -->
      <span>하나시스 상품등록 관리자</span>
    </div>
    <div class="form">
      <ul>
        <li>
          <input
            type="text"
            placeholder="아이디를 입력하세요."
            (keyup.enter)="btnLoginClick()"
            #idInput
            [(ngModel)]="id"
          />
        </li>
        <li>
          <input
            type="password"
            placeholder="비밀번호를 입력하세요."
            (keyup.enter)="btnLoginClick()"
            #passInput
            [(ngModel)]="pass"
          />
        </li>
      </ul>
      <div class="btn_login">
        <button type="button" class="btn" (click)="btnLoginClick()">
          로그인
        </button>
      </div>
    </div>
    <div class="auto_login">
      <div class="custom_checkbox">
        <input
          type="checkbox"
          id="id_remember"
          [(ngModel)]="remember"
          #idRemember
        />
        <label for="id_remember">아이디 기억하기</label>
      </div>
      <div class="ml-auto">
        <div class="custom_checkbox">
          <input
            type="checkbox"
            id="auto_login"
            [(ngModel)]="auto"
            #autoLogin
          />
          <label for="auto_login">자동 로그인</label>
        </div>
      </div>
    </div>
    <div class="btm_txt">
      <p>
        계정 및 로그인 관련 문의사항은<br />
        아래 연락처로 문의해주시기 바랍니다.
      </p>
      <div class="tel">T.1800-1185</div>
    </div>
  </div>
  <!-- loding -->
  <div [class]="'loding ' + (isLoading ? 'active' : '')">
    <!-- active 클래스 추가시 활성화 -->
    <div>
      <div class="loader"></div>
      <span class="loding_txt">Loding...</span>
    </div>
  </div>
  <!-- //loding -->
</div>
