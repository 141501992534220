<div class="top_select_bg">
  <app-side (sendPosInfo)="onReceivePosInfo($event)"></app-side>
</div>

<section class="container pb_0">
  <div class="sub_cont">
    <div class="page_top_title">
      <a class="page_back" (click)="goBack()">뒤로</a>
      <h1>환경설정</h1>
      <div class="ml-auto">
        <!-- <button
          type="button"
          class="icon_plus"
          (click)="popup_open('.add_popup')"
        >
          추가
        </button> -->
      </div>
    </div>
    <!-- search -->
    <div class="search_araa">
      <div class="search">
        <input
          type="text"
          placeholder="검색어를 입력해주세요."
          [(ngModel)]="keyword"
          (keyup.enter)="onSearch()"
        />
        <button type="button" class="btn_search" (click)="onSearch()">
          검색
        </button>
      </div>
    </div>
    <!-- //search -->

    <div class="setting_list">
      <ul>
        <li *ngFor="let item of envList; let i = index">
          <button type="button" (click)="onDetail(item, '.add_popup')">
            {{ item.NAME }} ({{ item.FLAG }})
          </button>
        </li>
      </ul>
    </div>
  </div>

  <!-- 삭제팝업 -->
  <div class="alert_popup">
    <div class="popup_content">
      <div class="txt_area">
        <p>리스트를 삭제 하시겠습니까?</p>
      </div>
      <div class="btn_area">
        <ul>
          <li>
            <button
              type="button"
              class="btn btn-gray-bd"
              onclick="popup_close('.delete_popup');"
            >
              취소
            </button>
          </li>
          <li>
            <button type="button" class="btn btn-blue-bg">확인</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- //삭제팝업 -->

  <!-- 추가 팝업 -->
  <div class="popup add_popup">
    <div class="popup_content">
      <div class="title_area">
        <button
          type="button"
          class="page_back"
          (click)="popup_close('.add_popup')"
        ></button>
        <h1>상세</h1>
      </div>
      <div class="popup_inner">
        <ul class="form_list">
          <li>
            <div class="tit">ID</div>
            <div class="input_area">
              <input type="text" placeholder="" #_id disabled />
            </div>
          </li>
          <li>
            <div class="tit">이름</div>
            <div class="input_area">
              <input type="text" placeholder="이름" #_name disabled />
            </div>
          </li>
          <li>
            <div class="tit">값</div>
            <div class="input_area">
              <input type="text" placeholder="값" #_value disabled />
            </div>
          </li>
          <li>
            <div class="tit">타입</div>
            <div class="input_area">
              <div class="custom_select2">
                <button
                  type="button"
                  class="select_list_open"
                  #_flag
                  disabled
                ></button>
              </div>
            </div>
          </li>
          <li>
            <div class="tit">옵션1</div>
            <div class="input_area">
              <div class="mb_20">
                <input type="text" placeholder="이름" #_optName1 disabled />
              </div>
              <div><input type="text" placeholder="값" #_optValue1 /></div>
            </div>
          </li>
          <li>
            <div class="tit">옵션2</div>
            <div class="input_area">
              <div class="mb_20">
                <input type="text" placeholder="이름" #_optName2 disabled />
              </div>
              <div><input type="text" placeholder="값" #_optValue2 /></div>
            </div>
          </li>
          <li>
            <div class="tit">옵션3</div>
            <div class="input_area">
              <div class="mb_20">
                <input type="text" placeholder="이름" #_optName3 disabled />
              </div>
              <div><input type="text" placeholder="값" #_optValue3 /></div>
            </div>
          </li>
          <li>
            <div class="tit">옵션4</div>
            <div class="input_area">
              <div class="mb_20">
                <input type="text" placeholder="이름" #_optName4 disabled />
              </div>
              <div><input type="text" placeholder="값" #_optValue4 /></div>
            </div>
          </li>
          <li>
            <div class="tit">옵션5</div>
            <div class="input_area">
              <div class="mb_20">
                <input type="text" placeholder="이름" #_optName5 disabled />
              </div>
              <div><input type="text" placeholder="값" #_optValue5 /></div>
            </div>
          </li>
          <li>
            <div class="tit">옵션6</div>
            <div class="input_area">
              <div class="mb_20">
                <input type="text" placeholder="이름" #_optName6 disabled />
              </div>
              <div><input type="text" placeholder="값" #_optValue6 /></div>
            </div>
          </li>
          <li>
            <div class="tit">옵션7</div>
            <div class="input_area">
              <div class="mb_20">
                <input type="text" placeholder="이름" #_optName7 disabled />
              </div>
              <div><input type="text" placeholder="값" #_optValue7 /></div>
            </div>
          </li>
          <li>
            <div class="tit">옵션8</div>
            <div class="input_area">
              <div class="mb_20">
                <input type="text" placeholder="이름" #_optName8 disabled />
              </div>
              <div><input type="text" placeholder="값" #_optValue8 /></div>
            </div>
          </li>
          <li>
            <div class="tit">옵션9</div>
            <div class="input_area">
              <div class="mb_20">
                <input type="text" placeholder="이름" #_optName9 disabled />
              </div>
              <div><input type="text" placeholder="값" #_optValue9 /></div>
            </div>
          </li>
          <li>
            <div class="tit">옵션10</div>
            <div class="input_area">
              <div class="mb_20">
                <input type="text" placeholder="이름" #_optName10 disabled />
              </div>
              <div><input type="text" placeholder="값" #_optValue10 /></div>
            </div>
          </li>
        </ul>
      </div>

      <div class="fixed_btn">
        <div>
          <button type="button" class="btn btn-blue-bg" (click)="onSave()">
            적용하기
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- //추가 팝업 -->
  <div [class]="'loding ' + (isLoading ? 'active' : '')">
    <!-- active 클래스 추가시 활성화 -->
    <div>
      <div class="loader"></div>
      <span class="loding_txt">Loding...</span>
    </div>
  </div>
  <!-- //loding -->
</section>
