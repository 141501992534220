import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UtilsService } from '../../shared/utils.service';
import { SideComponent } from '../../commons/side/side.component';
import { ToastrService } from 'ngx-toastr';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  UrlSegment,
} from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';

import {
  ImageCropperComponent,
  ImageCroppedEvent,
  LoadedImage,
  base64ToFile,
} from 'ngx-image-cropper';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

interface PosInfo {
  AGENCY_CODE: string;
  FRANCHISE_CODE: string;
  BRAND_CODE: string;
  STORE_CODE: string;
  POS_NO: string;
  POS_TYPE_CODE: number;
  MODEL_CODE: string;
  USE_YN: string;
}
@Component({
  selector: 'app-goods',
  templateUrl: './goods.component.html',
  styleUrls: ['./goods.component.scss'],
})
export class GoodsComponent implements AfterViewInit, OnInit {
  @ViewChild(SideComponent) sideComponent!: SideComponent;
  @ViewChild('_bigCategory') _bigCategory: ElementRef | undefined;
  @ViewChild('_middleCategory') _middleCategory: ElementRef | undefined;
  @ViewChild('_smallCategory') _smallCategory: ElementRef | undefined;
  @ViewChild('_imgFile') _imgFile: ElementRef<HTMLInputElement> | undefined;
  @ViewChild('_korean') _korean: ElementRef | undefined;
  @ViewChild('_english') _english: ElementRef | undefined;
  @ViewChild('_japanese') _japanese: ElementRef | undefined;
  @ViewChild('_chinese') _chinese: ElementRef | undefined;
  @ViewChild('_price') _price: ElementRef | undefined;
  @ViewChild('_rate') _rate: ElementRef | undefined;
  @ViewChild('_ratePrice') _ratePrice: ElementRef | undefined;
  @ViewChild('_itemCode') _itemCode: ElementRef | undefined;
  @ViewChild('_goodsName') _goodsName: ElementRef | undefined;
  @ViewChild('_useYN') _useYN: ElementRef | undefined;
  @ViewChild('_optEnable') _optEnable: ElementRef | undefined;
  @ViewChild('_adultYN') _adultYN: ElementRef | undefined;
  @ViewChild('_taxYN') _taxYN: ElementRef | undefined;

  @ViewChild('_NameChecked') _NameChecked: ElementRef | undefined;

  // 수정관련
  @ViewChild('_editImg') _editImg: ElementRef | undefined;
  @ViewChild('_editImgFile') _editImgFile: ElementRef | undefined;

  @ViewChild('_edit_bigCategory') _edit_bigCategory: ElementRef | undefined;
  @ViewChild('_edit_middleCategory') _edit_middleCategory:
    | ElementRef
    | undefined;
  @ViewChild('_edit_smallCategory') _edit_smallCategory: ElementRef | undefined;

  @ViewChild('_edit_goods_name') _edit_goods_name: ElementRef | undefined;
  @ViewChild('_edit_goods_code') _edit_goods_code: ElementRef | undefined;
  @ViewChild('_edit_sale_yn') _edit_sale_yn: ElementRef | undefined;
  @ViewChild('_edit_optEnable') _edit_optEnable: ElementRef | undefined;
  @ViewChild('_edit_adult_yn') _edit_adult_yn: ElementRef | undefined;
  @ViewChild('_edit_taxYN') _edit_taxYN: ElementRef | undefined;

  @ViewChild('_edit_korean') _edit_korean: ElementRef | undefined;
  @ViewChild('_edit_english') _edit_english: ElementRef | undefined;
  @ViewChild('_edit_japanese') _edit_japanese: ElementRef | undefined;
  @ViewChild('_edit_chinese') _edit_chinese: ElementRef | undefined;
  @ViewChild('_edit_price') _edit_price: ElementRef | undefined;

  @ViewChild('_edit_rate') _edit_rate: ElementRef | undefined;
  @ViewChild('_edit_ratePrice') _edit_ratePrice: ElementRef | undefined;
  @ViewChild('_add_img') _add_img: ElementRef | undefined;

  @ViewChild('_img_del') _img_del: ElementRef | undefined;

  selectedFile: File | undefined;

  receivedPosInfo: PosInfo | null = null;
  keyword: string = '';
  totCnt: string = '';
  goodsList: any = [];

  editMode: boolean = false;

  customSelects!: HTMLElement[];
  checkboxes!: HTMLInputElement[];
  modifyModeButton!: HTMLButtonElement;
  listWrap!: HTMLElement;

  bigCategoryList: any = [];
  middleCategoryList: any = [];
  smallCategoryList: any = [];

  selectBigCategoryCode: string = '';
  selectBigCategroyName: string = '';

  selectMiddleCategoryCode: string = '';
  selectMiddleCategroyName: string = '';

  selectSmallCategoryCode: string = '';
  selectSmallCategroyName: string = '';

  selectedCurItem: any;

  isImageDeleteButtonVisible = false;

  isNameChecked: boolean = false;
  isEditNameChecked: boolean = false;

  isLoading: boolean = false;

  imageChangedEvent: Event | null = null;
  croppedImage: SafeUrl = '';

  imageChangedEvent2: Event | null = null;
  croppedImage2: SafeUrl = '';

  file: File | null = null;
  file2: File | null = null;

  startLoading() {
    this.isLoading = true;
  }

  stopLoading() {
    this.isLoading = false;
  }

  goBack() {
    this.router.navigate(['/main']);
  }

  onFileSelected(event: Event) {
    this.imageChangedEvent = event;

    console.log('change event');
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      this.selectedFile = inputElement.files[0];
      console.log('Selected file:', this.selectedFile);

      if (this._add_img) {
        if (this.selectedFile) {
          const imgUrl = URL.createObjectURL(this.selectedFile);
          this._add_img.nativeElement.src = imgUrl;
          this._add_img.nativeElement.hidden = false;
          if (this._img_del) {
            this.isImageDeleteButtonVisible = true;
          }
        }

        //
      }
    }
  }

  onImgDel() {
    console.group('이미지삭제');
    console.log('이미지삭제');

    console.groupEnd();
  }

  OnImgSelect() {
    console.log('이미지 셀렉트');
    this._imgFile?.nativeElement.click();
  }

  async bigBtnDetailClick(item: any) {
    console.log('bigBtnDetailClick', item.CATEGORYCODE);

    this.selectBigCategoryCode = item.CATEGORYCODE;
    this.selectBigCategroyName = item.CATEGORYNAME;

    // alert(item.CATEGORYCODE);

    const resMiddle = await this.getMiddleCategoryList(item.CATEGORYCODE);
    console.log('resMiddle', resMiddle);
    if (resMiddle) {
      if (resMiddle.header) {
        if (resMiddle.header.res_code === 'ok') {
          this.middleCategoryList = resMiddle.data;
          this.selectMiddleCategoryCode = resMiddle.data[0].CATEGORYCODE;
          this.selectMiddleCategroyName = resMiddle.data[0].CATEGORYNAME;
          if (this._middleCategory) {
            this._middleCategory.nativeElement.textContent =
              resMiddle.data[0].CATEGORYNAME;
          }

          console.log(
            'this.selectMiddleCategoryCode',
            this.selectMiddleCategoryCode,
            'this.selectMiddleCategroyName',
            this.selectMiddleCategroyName,
            this._middleCategory?.nativeElement.textContent
          );
        } else {
          console.log('중분류 값이 없습니다.');
          this.middleCategoryList = [];
          this.selectMiddleCategoryCode = '';
          this.selectMiddleCategroyName = '';
        }
      } else {
        console.log('중분류 헤더값이 없습니다.');
        this.middleCategoryList = [];
        this.selectMiddleCategoryCode = '';
        this.selectMiddleCategroyName = '';
      }
    } else {
      console.log('중분류 응답값 자제가 없습니다.');
      this.middleCategoryList = [];
      this.selectMiddleCategoryCode = '';
      this.selectMiddleCategroyName = '';
    }

    const resSmall = await this.getSmallCategoryList(
      this.selectMiddleCategoryCode
    );

    if (resSmall) {
      if (resSmall.header) {
        if (resSmall.header.res_code === 'ok') {
          this.smallCategoryList = resSmall.data;
          this.selectSmallCategoryCode = resSmall.data[0].CATEGORYCODE;
          this.selectSmallCategroyName = resSmall.data[0].CATEGORYNAME;
          if (this._smallCategory) {
            this._smallCategory.nativeElement.textContent =
              resSmall.data[0].CATEGORYNAME;
          }
        } else {
          console.log('소분류 값이 없습니다.');
          this.smallCategoryList = [];
          this.selectSmallCategoryCode = '';
          this.selectSmallCategroyName = '';
        }
      } else {
        console.log('소분류 헤더값이 없습니다.');
        this.smallCategoryList = [];
        this.selectSmallCategoryCode = '';
        this.selectSmallCategroyName = '';
      }
    } else {
      console.log('소분류 응답값 자제가 없습니다.');
      this.smallCategoryList = [];
      this.selectSmallCategoryCode = '';
      this.selectSmallCategroyName = '';
    }

    console.log('resSmall', resSmall);
  }

  async middleBtnDetailClick(item: any) {
    console.log('middleBtnDetailClick', item);
    const resSmall = await this.getSmallCategoryList(
      this.selectMiddleCategoryCode
    );

    if (resSmall) {
      if (resSmall.header) {
        if (resSmall.header.res_code === 'ok') {
          this.smallCategoryList = resSmall.data;
          this.selectSmallCategoryCode = resSmall.data[0].CATEGORYCODE;
          this.selectSmallCategroyName = resSmall.data[0].CATEGORYNAME;
        } else {
          console.log('소분류 값이 없습니다.');
          this.smallCategoryList = [];
          this.selectSmallCategoryCode = '';
          this.selectSmallCategroyName = '';
        }
      } else {
        console.log('소분류 헤더값이 없습니다.');
        this.smallCategoryList = [];
        this.selectSmallCategoryCode = '';
        this.selectSmallCategroyName = '';
      }
    } else {
      console.log('소분류 응답값 자제가 없습니다.');
      this.smallCategoryList = [];
      this.selectSmallCategoryCode = '';
      this.selectSmallCategroyName = '';
    }

    console.log('resSmall', resSmall);
  }

  // 자식에게서 받는 데이터
  async onReceivePosInfo(posInfo: PosInfo) {
    console.group('OnRecivePosInfo');

    this.receivedPosInfo = posInfo;
    console.log('자식에게서 받아온값 ', this.receivedPosInfo);
    await this.search(posInfo);

    const bigList = await this.getBigCategoryList();
    if (bigList) {
      if (bigList.header) {
        if (bigList.header.res_code === 'ok') {
          this.bigCategoryList = bigList.data;
          this.selectBigCategoryCode = bigList.data[0].CATEGORYCODE;
          this.selectBigCategroyName = bigList.data[0].CATEGORYNAME;
        } else {
          this.bigCategoryList = [];
          this.selectBigCategoryCode = '';
          this.selectBigCategroyName = '';
        }
      } else {
        this.bigCategoryList = [];
        this.selectBigCategoryCode = '';
        this.selectBigCategroyName = '';
      }
    } else {
      this.bigCategoryList = [];
      this.selectBigCategoryCode = '';
      this.selectBigCategroyName = '';
    }
    console.log('bigList', bigList);

    const resMiddle = await this.getMiddleCategoryList(
      this.selectBigCategoryCode
    );
    if (resMiddle) {
      if (resMiddle.header) {
        if (resMiddle.header.res_code === 'ok') {
          this.middleCategoryList = resMiddle.data;
          this.selectMiddleCategoryCode = resMiddle.data[0].CATEGORYCODE;
          this.selectMiddleCategroyName = resMiddle.data[0].CATEGORYNAME;
        } else {
          console.log('중분류 값이 없습니다.');
          this.middleCategoryList = [];
          this.selectMiddleCategoryCode = '';
          this.selectMiddleCategroyName = '';
        }
      } else {
        console.log('중분류 헤더값이 없습니다.');
        this.middleCategoryList = [];
        this.selectMiddleCategoryCode = '';
        this.selectMiddleCategroyName = '';
      }
    } else {
      console.log('중분류 응답값 자제가 없습니다.');
      this.middleCategoryList = [];
      this.selectMiddleCategoryCode = '';
      this.selectMiddleCategroyName = '';
    }

    console.log('resMiddle', resMiddle);

    const resSmall = await this.getSmallCategoryList(
      this.selectMiddleCategoryCode
    );

    if (resSmall) {
      if (resSmall.header) {
        if (resSmall.header.res_code === 'ok') {
          this.smallCategoryList = resSmall.data;
          this.selectSmallCategoryCode = resSmall.data[0].CATEGORYCODE;
          this.selectSmallCategroyName = resSmall.data[0].CATEGORYNAME;
        } else {
          console.log('소분류 값이 없습니다.');
          this.smallCategoryList = [];
          this.selectSmallCategoryCode = '';
          this.selectSmallCategroyName = '';
        }
      } else {
        console.log('소분류 헤더값이 없습니다.');
        this.smallCategoryList = [];
        this.selectSmallCategoryCode = '';
        this.selectSmallCategroyName = '';
      }
    } else {
      console.log('소분류 응답값 자제가 없습니다.');
      this.smallCategoryList = [];
      this.selectSmallCategoryCode = '';
      this.selectSmallCategroyName = '';
    }

    console.log('resSmall', resSmall);

    console.groupEnd();
  }

  bigBtnClick(buttonRef: HTMLButtonElement) {
    //console.log(buttonRef?.nativeElement.textContent);
    console.log('Big Click', this._bigCategory?.nativeElement.textContent);
  }

  bigBtnChange(buttonRef: HTMLButtonElement) {
    //console.log(buttonRef?.nativeElement.textContent);
    console.log('Big Change', this._bigCategory?.nativeElement.textContent);
  }

  constructor(
    private utilsService: UtilsService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer
  ) {
    this.sanitizer = sanitizer;
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log('Image cropped');
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl!);

    // #imgFile에 크롭된 이미지를 설정
    // #imgFile에 크롭된 이미지를 설정
    //this.setFileInput(this.croppedImage as string);
    console.log('this.croppedImage', this.croppedImage);

    // var file = this.dataURLtoFile(event.base64, 'image.png');
    console.log('event', event);

    // const datas = event.blob;

    if (this._imgFile) {
      const files = this._imgFile.nativeElement.files;
      this.file = new File([event.blob!], files![0].name, {
        type: event.blob!.type,
      });
    }
    console.log('file', this.file);
  }

  imageCropped2(event: ImageCroppedEvent) {
    console.log('Image cropped2');
    this.croppedImage2 = this.sanitizer.bypassSecurityTrustUrl(
      event.objectUrl!
    );

    // #imgFile에 크롭된 이미지를 설정
    // #imgFile에 크롭된 이미지를 설정
    //this.setFileInput(this.croppedImage as string);
    console.log('this.croppedImage2', this.croppedImage2);

    // var file = this.dataURLtoFile(event.base64, 'image.png');
    console.log('event', event);

    // const datas = event.blob;

    if (this._editImgFile) {
      const files = this._editImgFile.nativeElement.files;
      this.file2 = new File([event.blob!], files![0].name, {
        type: event.blob!.type,
      });
    }
    console.log('file2', this.file2);
  }

  base64ToFile(data: any, filename: string) {
    console.log(data, filename);

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  imageLoaded(event: any) {
    // show cropper
    console.log('image loaded', event);
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {}

  imageLoaded2(event: any) {
    // show cropper
    console.log('image loaded', event);
  }
  cropperReady2() {
    // cropper ready
  }
  loadImageFailed2() {}

  ngOnInit(): void {
    console.group('상품관리');
    const login_info = window.localStorage.getItem('login_info') || null;
    if (!login_info) {
      this.router.navigate(['/login']);
    }
    console.groupEnd();
  }

  ngAfterViewInit(): void {
    console.log('goods ngAfgerViewInit >>');

    this.imageChangedEvent = null;

    const checkboxes = document.querySelectorAll(
      '.custom_checkbox input[type="checkbox"]'
    );

    console.log('checkbox len', checkboxes.length);

    if (checkboxes.length > 0) {
      checkboxes.forEach((checkbox) => {
        checkbox.addEventListener('change', (event) => {
          const li = (event.target as HTMLInputElement).closest('li');
          if ((event.target as HTMLInputElement).checked) {
            li?.classList.add('select');
          } else {
            li?.classList.remove('select');
          }
        });
      });
    }

    //자바 스크립트 관련
    this.customSelects = Array.from(
      document.querySelectorAll('.custom_select')
    ) as HTMLElement[];
    this.checkboxes = Array.from(
      document.querySelectorAll('.custom_checkbox input[type="checkbox"]')
    ) as HTMLInputElement[];
    this.modifyModeButton = document.querySelector(
      '.btn_modify_mode'
    ) as HTMLButtonElement;
    this.listWrap = document.querySelector('.list_wrap') as HTMLElement;

    this.initCustomSelect();

    //this.initModifyMode();

    // var btnTop = document.querySelector('.btn_top');
    // var btnDown = document.querySelector('.btn_down');

    var btnTopList = document.querySelectorAll('.btn_top');
    var btnDownList = document.querySelectorAll('.btn_down');

    function smoothScroll(targetPosition: number, duration: number) {
      var startPosition = window.pageYOffset;
      var distance = targetPosition - startPosition;
      var startTime: number | null = null;

      function animation(currentTime: number | null) {
        if (startTime === null) startTime = currentTime;
        var timeElapsed = currentTime! - startTime!;
        var run = ease(timeElapsed, startPosition, distance, duration);
        window.scrollTo(0, run);
        if (timeElapsed < duration) requestAnimationFrame(animation);
      }

      function ease(t: number, b: number, c: number, d: number) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      }

      requestAnimationFrame(animation);
    }

    // btnTop!.addEventListener('click', function () {
    //   smoothScroll(0, 500);
    // });

    // btnDown!.addEventListener('click', function () {
    //   smoothScroll(
    //     document.documentElement.scrollHeight - window.innerHeight,
    //     500
    //   );
    // });

    btnTopList.forEach((btn) => {
      btn.addEventListener('click', function () {
        smoothScroll(0, 500);
        console.log('go to top');
      });
    });

    btnDownList.forEach((btn) => {
      btn.addEventListener('click', function () {
        smoothScroll(
          document.documentElement.scrollHeight - window.innerHeight,
          500
        );
        console.log('gotoDown');
      });
    });
  }

  async onListClick(data: any) {
    // if (this.editMode === true) {
    //   return;
    // }

    console.group('리스트');
    console.log('리스트 클릭', data);

    // this['router'].navigate(['/goods/middlecategory'], {
    //   queryParams: { data: JSON.stringify(data) },
    // });
    console.groupEnd();
  }

  async search(item: any) {
    const data = await this.getTotCnt(item, '1');
    console.log('서버에게서 받아온값', data);
    if (data) {
      if (data.header) {
        if (data.header.res_code === 'ok') {
          this.totCnt = data.data[0].cnt;
        } else {
          console.log('에러 > ', data.header.res_msg);
        }
      } else {
        console.log('헤더가 없음');
      }
    } else {
      console.log('data 가 없음');
    }
    const goodsList = await this.getGoodsList(item, '1');
    console.log('goodsList', goodsList);
    if (goodsList) {
      if (goodsList.header) {
        if (goodsList.header.res_code === 'ok') {
          this.goodsList = goodsList.data;
        } else {
          console.log('에러', goodsList.header.res_msg);
          this.goodsList = [];
        }
      } else {
        this.goodsList = [];
        console.log('헤더가 없음');
      }
    } else {
      this.goodsList = [];
      console.log('리스트가 없음');
    }
  }

  async getTotCnt(posInfo: PosInfo, lvl: string) {
    try {
      // 서버와 통신
      const url = `${this.utilsService.getAPIsUrl()}/goods/getGoodsCntList?agency=${
        posInfo.AGENCY_CODE
      }
	  &fran=${posInfo.FRANCHISE_CODE}&brand=${posInfo.BRAND_CODE}
	  &store=${posInfo.STORE_CODE}
	  &pos=${posInfo.POS_NO}
	  &goods_nm=${this.keyword}`;
      this.startLoading();
      const response = await fetch(url);
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async getGoodsList(posInfo: PosInfo, lvl: string) {
    try {
      // 서버와 통신
      const url = `${this.utilsService.getAPIsUrl()}/goods/getGoodsList?agency=${
        posInfo.AGENCY_CODE
      }&fran=${posInfo.FRANCHISE_CODE}&brand=${posInfo.BRAND_CODE}&store=${
        posInfo.STORE_CODE
      }&pos=${posInfo.POS_NO}&goods_nm=${this.keyword}`;
      this.startLoading();
      const response = await fetch(url);
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async onSearch() {
    console.group('검색버튼');
    console.log('검색');
    console.groupEnd();
    await this.search(this.receivedPosInfo);
  }

  btnAllClick() {
    console.log('전체선택 클릭');
    const allChk = document.getElementById('allChk') as HTMLInputElement;
    const checkboxes = document.querySelectorAll(
      '.custom_checkbox input[type="checkbox"]'
    );

    checkboxes.forEach((checkbox) => {
      (checkbox as HTMLInputElement).checked = allChk.checked;
    });
  }

  popup_open(name: string) {
    let i = 0;

    const checkboxes = document.querySelectorAll(
      '.custom_checkbox input[type="checkbox"]'
    );

    checkboxes.forEach((checkbox) => {
      if ((checkbox as HTMLInputElement).checked) {
        const li = checkbox.closest('li');
        if (li) {
          console.log('있음');
          i++;
        } else {
          console.log('없음');
        }
      }
    });

    if (i < 1) {
      this.toastr.error('선택된 항목이 없습니다.', '선택오류');
      return;
    }

    console.log('checked checkbox cnt', i);

    const obj: any = document.querySelector(name);
    obj.classList.add('active');
  }

  async popup_open2(name: string, item: any) {
    console.group('수정 시작');
    this.selectedCurItem = item;
    console.log('수정시작', this.selectedCurItem);

    //this.aspectRatio = 3 / 4;

    // 서버이미지
    if (this._editImg) {
      this._editImg.nativeElement.src = item.ServerFolderPath;
    }
    // if (this._editImgFile) {
    //   const fileInput = this._editImgFile.nativeElement as HTMLInputElement;
    //   fileInput.value = item.ServerFolderPath;
    // }

    // 다국어
    const lang = await this.getOneLang(
      item.AGENCY_CODE,
      item.FRANCHISE_CODE,
      item.BRAND_CODE,
      item.STORE_CD,
      item.POS_NO,
      item.PLU_CD
    );
    console.log('다국어', lang);
    if (lang) {
      if (lang.header) {
        if (lang.header.res_code === 'ok') {
          if (this._edit_korean) {
            this._edit_korean.nativeElement.value = lang.data[0].KOREAN;
          }
          if (this._edit_english) {
            this._edit_english.nativeElement.value = lang.data[0].ENGLISH;
          }
          if (this._edit_japanese) {
            this._edit_japanese.nativeElement.value = lang.data[0].JAPANESE;
          }
          if (this._edit_chinese) {
            this._edit_chinese.nativeElement.value = lang.data[0].CHINESE;
          }
        } else {
          if (this._edit_korean) {
            this._edit_korean.nativeElement.value = '';
          }
          if (this._edit_english) {
            this._edit_english.nativeElement.value = '';
          }
          if (this._edit_japanese) {
            this._edit_japanese.nativeElement.value = '';
          }
          if (this._edit_chinese) {
            this._edit_chinese.nativeElement.value = '';
          }
        }
      } else {
        if (this._edit_korean) {
          this._edit_korean.nativeElement.value = '';
        }
        if (this._edit_english) {
          this._edit_english.nativeElement.value = '';
        }
        if (this._edit_japanese) {
          this._edit_japanese.nativeElement.value = '';
        }
        if (this._edit_chinese) {
          this._edit_chinese.nativeElement.value = '';
        }
      }
    } else {
      if (this._edit_korean) {
        this._edit_korean.nativeElement.value = '';
      }
      if (this._edit_english) {
        this._edit_english.nativeElement.value = '';
      }
      if (this._edit_japanese) {
        this._edit_japanese.nativeElement.value = '';
      }
      if (this._edit_chinese) {
        this._edit_chinese.nativeElement.value = '';
      }
    }

    // 다국어

    await this.getBigCategoryList();

    await this.getMiddleCategoryList(item.ITEM_HEAD);

    await this.getSmallCategoryList(item.ITEM_MEDIUM);

    // 대분류
    this.selectBigCategoryCode = item.ITEM_HEAD;
    this.selectBigCategroyName = item.ITEM_HEAD_NM;
    // 중분류
    this.selectMiddleCategoryCode = item.ITEM_MEDIUM;
    this.selectMiddleCategroyName = item.ITEM_MEDIUM_NM;
    // 소분류
    this.selectSmallCategoryCode = item.ITEM_DETAIL;
    this.selectSmallCategroyName = item.ITEM_DETAIL_NM;

    // 상품명
    if (this._edit_goods_name) {
      this._edit_goods_name.nativeElement.value = item.GOODS_NM;
    }

    console.log('상품코드', item.PLU_CD);
    // 상품코드
    if (this._edit_goods_code) {
      this._edit_goods_code.nativeElement.value = item.PLU_CD;
    } else {
      console.log('상품코드를 넣을수 없다');
    }

    if (this._edit_sale_yn) {
      item.SALE_YN === 'Y'
        ? (this._edit_sale_yn.nativeElement.checked = true)
        : (this._edit_sale_yn.nativeElement.checked = false);
    }

    if (this._edit_optEnable) {
      item.hasOption === 'Y'
        ? (this._edit_optEnable.nativeElement.checked = true)
        : (this._edit_optEnable.nativeElement.checked = false);
    }

    if (this._edit_adult_yn) {
      item.JUNIOR_YN === 'Y'
        ? (this._edit_adult_yn.nativeElement.checked = false)
        : (this._edit_adult_yn.nativeElement.checked = true);
    }

    if (this._edit_taxYN) {
      if (item.TAX_GUB === '1' || item.TAX_GUB === 'Y') {
        this._edit_taxYN.nativeElement.checked = true;
      } else {
        this._edit_taxYN.nativeElement.checked = false;
      }
    }

    if (this._edit_price) {
      this._edit_price.nativeElement.value = item.SALEPRICE;
    }

    if (this._edit_rate) {
      this._edit_rate.nativeElement.value = item.offPercent;
    }

    if (this._edit_ratePrice) {
      this._edit_ratePrice.nativeElement.value = item.offPrice;
    }

    console.groupEnd();
    const obj: any = document.querySelector(name);
    obj.classList.add('active');
  }

  async onEdit() {
    console.group('수정하기');
    console.log('수정하기');
    // UI 체크 하기

    // 이미지
    if (this._editImg) {
      //console.log(this._editImg.nativeElement.src);

      const img = this._editImg.nativeElement.src;
      console.log(img);
      console.log(typeof img, img.includes('null'));

      if (
        (typeof img === 'string' && img.includes('null')) ||
        img === '' ||
        img === null
      ) {
        console.log('이미지 없음');
        if (this._editImgFile) {
          const files = this._editImgFile.nativeElement.files;
          console.log('files', files, 'files.length', files?.length);
          if (files && files.length > 0) {
            console.log('File selected: >> ', files[0]);
          } else {
            console.log('No file selected');
            this.toastr.error('이미지를 선택해 주세요', '입력오류');
            return;
          }
        } else {
          console.log('imgFile null');
        }
      } else {
        console.log('이미지 있음', img);
      }
    } else {
      console.log('이미지가 없음');
    }

    // 대분류
    if (this._edit_bigCategory) {
      if (
        this._edit_bigCategory.nativeElement.value.trim() === '' ||
        this._edit_bigCategory.nativeElement.textContent.trim() === ''
      ) {
        this.toastr.error('대분류를 선택해 주세요', '입력오류');
        this._edit_bigCategory.nativeElement.focus();
        return;
      }
    }
    // 중분류
    if (this._edit_middleCategory) {
      if (
        this._edit_middleCategory.nativeElement.value.trim() === '' ||
        this._edit_middleCategory.nativeElement.textContent.trim() === ''
      ) {
        this.toastr.error('중분류를 선택해 주세요', '입력오류');
        this._edit_middleCategory.nativeElement.focus();
        return;
      }
    }
    // 소분류
    if (this._edit_smallCategory) {
      console.log('소분류', this._edit_smallCategory.nativeElement.textContent);
      if (
        this._edit_smallCategory.nativeElement.value.trim() === '' ||
        this._edit_smallCategory.nativeElement.textContent.trim() === ''
      ) {
        this.toastr.error('소분류를 선택해 주세요', '입력오류');
        this._edit_smallCategory.nativeElement.focus();
        return;
      }
    }

    //상품명
    if (this._edit_goods_name) {
      if (this._edit_goods_name.nativeElement.value.trim() === '') {
        this._edit_goods_name.nativeElement.focus();
        this.toastr.error('상품명을 입력해 주세요', '입력오류');
        return;
      }
    }

    // 한국어
    if (this._edit_korean) {
      if (this._edit_korean.nativeElement.value.trim() === '') {
        this._edit_korean.nativeElement.focus();
        this.toastr.error('한국어를 입력해 주세요', '입력오류');
        return;
      }
    }

    // 영어
    if (this._edit_english) {
      if (this._edit_english.nativeElement.value.trim() === '') {
        this._edit_english.nativeElement.focus();
        this.toastr.error('영어를 입력해 주세요', '입력오류');
        return;
      }
    }

    // 일본어
    if (this._edit_japanese) {
      if (this._edit_japanese.nativeElement.value.trim() === '') {
        this._edit_japanese.nativeElement.focus();
        this.toastr.error('일본어를 입력해 주세요', '입력오류');
        return;
      }
    }

    // 중국어
    if (this._edit_chinese) {
      if (this._edit_chinese.nativeElement.value.trim() === '') {
        this._edit_chinese.nativeElement.focus();
        this.toastr.error('중국어를 입력해 주세요', '입력오류');
        return;
      }
    }

    // 상품코드
    if (this._edit_goods_code) {
      if (this._edit_goods_code.nativeElement.value.trim() === '') {
        this.toastr.error('상품코드를 입력해 주세요', '입력오류');
        this._edit_goods_code.nativeElement.focus();
        return;
      }
    }

    this.startLoading();

    let cnt: number = 0;
    // 상품 수정
    const resGoods = await this.editGood();
    if (resGoods) {
      if (resGoods.header.res_code === 'ok') {
        console.log(resGoods.header.res_msg);
        cnt++;
      }
    }

    // 다국어 수정
    const resLang = await this.editLang(
      this._edit_goods_code?.nativeElement.value.trim()
    );

    if (resLang) {
      if (resLang.header.res_code === 'ok') {
        console.log(resLang.header.res_msg);
        cnt++;
      }
    }

    if (cnt >= 2) {
      this.toastr.success('상품 과 다국어 수정에 성공하였습니다', '수정성공');
    }

    this.search(this.receivedPosInfo!);

    this.stopLoading();
    this.popup_close('.modify_popup');

    console.groupEnd();
  }

  popup_close(name: string) {
    console.group('popup close');
    console.log('popup close');
    console.groupEnd();

    this.imageChangedEvent = null;
    this.croppedImage = '';

    this.imageChangedEvent2 = null;
    this.croppedImage2 = '';

    const obj: any = document.querySelector(name);
    obj.classList.remove('active');

    this.isEditNameChecked = false;
  }

  async popup_ok(name: string) {
    console.group('삭제 OK');

    const obj: any = document.querySelector(name);
    obj.classList.remove('active');

    const checkboxes = document.querySelectorAll(
      '.custom_checkbox input[type="checkbox"]'
    );

    checkboxes.forEach(async (checkbox) => {
      let goodsCnt = 0;
      let langCnt = 0;
      if ((checkbox as HTMLInputElement).checked) {
        const li = checkbox.closest('li');
        if (li) {
          const dataValue = checkbox.getAttribute('data-datas');

          if (dataValue) {
            const itemData = JSON.parse(dataValue);
            console.log('Item data:', itemData);

            const resGoods = await this.deleteGoods(itemData);
            if (resGoods) {
              if (resGoods.header) {
                if (resGoods.header.res_code === 'ok') {
                  goodsCnt++;
                } else {
                }
              }
            }
            console.log('resGoods', resGoods);
            const resLang = await this.deleteLang(itemData);
            console.log('resLang', resLang);
            if (resLang) {
              if (resLang.header) {
                if (resLang.header.res_code === 'ok') {
                  langCnt++;
                } else {
                }
              }
            }

            // if (goodsCnt > 0) {
            //   this.toastr.success(`상품 삭제가 성공하였습니다.`, '삭제 성공');
            // } else {
            //   this.toastr.error('상품 삭제가 실패하였습니다.', '삭제 오류');
            // }

            // if (langCnt > 0) {
            //   this.toastr.success(`다국어 삭제가 성공하였습니다.`, '삭제 성공');
            // } else {
            //   this.toastr.error('다국어 삭제가 실패하였습니다.', '삭제 오류');
            // }

            if (goodsCnt > 0 && langCnt > 0) {
              this.toastr.success(
                `상품 및 다국어 삭제가 성공하였습니다.`,
                '삭제 성공'
              );
            } else {
              this.toastr.error(
                '상품 및 다국어 삭제가 실패하였습니다.',
                '삭제 오류'
              );
            }

            await this.search(this.receivedPosInfo!);
          } else {
            console.log('No data-datas attribute found');
            await this.search(this.receivedPosInfo!);
          }
        } else {
          console.log('없음');
          await this.search(this.receivedPosInfo!);
        }
      }
    });

    //

    console.groupEnd();
  }

  onEditChange() {
    console.log('change');

    const wrap = document.getElementById('list_wraps');

    if (wrap) {
      if (this.editMode === true) {
        console.log('수정모드');
        wrap?.classList.remove('modify_mode');
        this.editMode = false;
      } else {
        console.log('수정모드 아님');
        wrap.classList.add('modify_mode');
        this.editMode = true;
      }
    } else {
      console.log('null');
    }

    const editBtn = document.getElementById('editBtn');
    if (editBtn) {
      if (editBtn.classList.contains('cancel')) {
        console.log('cancel');
        editBtn.classList.remove('cancel');
        editBtn.textContent = '편집';
      } else if (editBtn.classList.contains('btn_modify_mode')) {
        console.log('modify_mode');
        editBtn.classList.add('cancel');
        editBtn.textContent = '편집취소';
      }
    }
  }

  initCustomSelect(): void {
    this.customSelects.forEach((select) => {
      const button = select.querySelector(
        '.select_list_open'
      ) as HTMLButtonElement;
      const options = Array.from(
        select.querySelectorAll('.select_list button')
      ) as HTMLButtonElement[];

      button.addEventListener('click', () => {
        this.customSelects.forEach((s) => {
          if (s !== select) {
            console.log('active');
            s.classList.remove('active');
          }
        });
        select.classList.toggle('active');
      });

      options.forEach((option) => {
        option.addEventListener('click', () => {
          console.log('option');
          button.textContent = option.textContent;
          select.classList.remove('active');
        });
      });
    });

    document.addEventListener('click', (event: any) => {
      if (!event.target.closest('.custom_select')) {
        this.customSelects.forEach((select) => {
          select.classList.remove('active');
        });
      }
    });

    // custom select2

    document.querySelectorAll('.select_list_open').forEach((button) => {
      button.addEventListener('click', () => {
        const customSelect = button.closest('.custom_select2');
        if (customSelect) {
          const options = customSelect.querySelectorAll('.select_list button');
          customSelect.classList.add('active');
          options.forEach((option) => {
            option.addEventListener('click', () => {
              button.textContent = option.textContent;
              customSelect.classList.remove('active');
            });
          });
        }
      });
    });

    document.querySelectorAll('.select_close').forEach(function (button) {
      button.addEventListener('click', function () {
        const customSelect = button.closest('.custom_select2');
        if (customSelect) {
          customSelect.classList.remove('active');
        }
      });
    });
  }

  initModifyMode(): void {
    this.modifyModeButton.addEventListener('click', () => {
      this.listWrap.classList.toggle('modify_mode');
      if (this.listWrap.classList.contains('modify_mode')) {
        this.modifyModeButton.classList.add('cancel');
        this.modifyModeButton.textContent = '편집취소';
      } else {
        this.modifyModeButton.classList.remove('cancel');
        this.modifyModeButton.textContent = '편집';
      }

      const checkboxes = document.querySelectorAll(
        '.custom_checkbox input[type="checkbox"]'
      );

      checkboxes.forEach((checkbox) => {
        (checkbox as HTMLInputElement).checked = false;
      });
    });
  }

  async deleteGoods(item: any) {
    try {
      //서버와 통신
      const url = `${this.utilsService.getAPIsUrl()}/goods/deletGoods?agency=${
        item.AGENCY_CODE
      }&fran=${item.FRANCHISE_CODE}&brand=${item.BRAND_CODE}&store=${
        item.STORE_CD
      }&pos=${item.POS_NO}&goodsId=${item.PLU_CD}&imageName=${item.imageName}`;

      console.group('Delete Goods');
      console.log('url', url);
      console.groupEnd();
      this.startLoading();
      const response = await fetch(url, { method: 'DELETE' });
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async deleteLang(item: any) {
    try {
      //서버와 통신

      let url = `${this.utilsService.getAPIsUrl()}/lang/deleteLang?agency=${
        item.AGENCY_CODE
      }&fran=${item.FRANCHISE_CODE}&brand=${item.BRAND_CODE}&store=${
        item.STORE_CD
      }&pos=${item.POS_NO}&code=${item.PLU_CD}
        &gubun=goods&big=${item.ITEM_HEAD}&middle=${item.ITEM_MEDIUM}&small=${
        item.ITEM_DETAIL
      }&lvl=0`;

      console.group('Delete Lang');
      console.log('받는 item', item);
      console.log('url', url);
      console.groupEnd();
      this.startLoading();
      const response = await fetch(url, { method: 'DELETE' });
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  getItemData(item: any) {
    return JSON.stringify(item);
  }

  popup_add_modify(name: string) {
    console.group('신규추가버튼');
    console.log('button 클릭');

    const obj: any = document.querySelector(name);
    obj.classList.add('active');

    if (name === '.add_popup') {
      console.log('신규추가');

      this.isNameChecked = false;

      if (this._NameChecked?.nativeElement) {
        this._NameChecked.nativeElement.checked = false;
      }

      this.imageChangedEvent = null;
      this.croppedImage = '';

      if (this._add_img) {
        this._add_img.nativeElement.hidden = true;
      }

      if (this._img_del) {
        this.isImageDeleteButtonVisible = false;
      }

      if (this._imgFile) {
        this._imgFile.nativeElement.value = '';
      }

      if (this._goodsName) {
        this._goodsName.nativeElement.value = '';
      }
      if (this._korean) {
        this._korean.nativeElement.value = '';
      }
      if (this._english) {
        this._english.nativeElement.value = '';
      }
      if (this._japanese) {
        this._japanese.nativeElement.value = '';
      }
      if (this._chinese) {
        this._chinese.nativeElement.value = '';
      }

      if (this._useYN) {
        this._useYN.nativeElement.checked = true;
      }

      if (this._optEnable) {
        this._optEnable.nativeElement.checked = false;
      }

      if (this._adultYN) {
        this._adultYN.nativeElement.checked = false;
      }

      if (this._taxYN) {
        this._taxYN.nativeElement.checked = true;
      }

      if (this._price) {
        this._price.nativeElement.value = '';
      }

      if (this._rate) {
        this._rate.nativeElement.value = '';
      }

      if (this._ratePrice) {
        this._ratePrice.nativeElement.value = '';
      }
    }

    console.log('동일하게 체크', this.isNameChecked);

    console.groupEnd();
  }

  onImgBtnChange(event: Event) {
    console.group('이미지 변경하기 버튼');
    console.log('이미지 변경');
    if (this._editImgFile) {
      this._editImgFile.nativeElement.click();
    }
    console.groupEnd();
  }

  onFileImgSelect(event: Event) {
    this.imageChangedEvent2 = event;
    console.log('File Selected');

    if (this._editImgFile) {
      if (this._editImg) {
        const fileInput = this._editImgFile.nativeElement as HTMLInputElement;
        const selectedFile = fileInput.files?.[0];
        if (selectedFile) {
          const imgUrl = URL.createObjectURL(selectedFile);
          this._editImg.nativeElement.src = imgUrl;
        }
      }
    }
  }

  async onBtnSave() {
    console.group('등록하기');
    console.log('등록버튼 클릭');
    console.log('imgFile', this._imgFile);
    console.log(
      'bigCategory',
      this._bigCategory?.nativeElement.textContent,
      this.selectBigCategroyName
    );
    console.log(
      'middleCategory',
      this._middleCategory?.nativeElement.textContent,
      this.selectMiddleCategroyName
    );
    console.log(
      'smallCategory',
      this._smallCategory?.nativeElement.textContent,
      this.selectSmallCategroyName
    );

    if (this._imgFile) {
      const files = this._imgFile.nativeElement.files;
      console.log('files', files, 'files.length', files?.length);
      if (files && files.length > 0) {
        console.log('File selected: >> ', files[0]);
      } else {
        console.log('No file selected');
        this.toastr.error('이미지를 선택해 주세요', '입력오류');
        return;
      }
    } else {
      console.log('imgFile null');
    }

    if (
      this._bigCategory?.nativeElement.textContent === '' ||
      this._bigCategory?.nativeElement.textContent === undefined
    ) {
      this.toastr.error('대분류를 선택하세요', '입력오류');
      this._bigCategory?.nativeElement.focus();
      return;
    }

    if (
      this._middleCategory?.nativeElement.textContent === '' ||
      this._middleCategory?.nativeElement.textContent === undefined
    ) {
      this.toastr.error('중분류를 선택하세요', '입력오류');
      this._middleCategory?.nativeElement.focus();
      return;
    }

    if (
      this._smallCategory?.nativeElement.textContent === '' ||
      this._smallCategory?.nativeElement.textContent === undefined
    ) {
      this.toastr.error('소분류를 선택하세요', '입력오류');
      this._smallCategory?.nativeElement.focus();
      return;
    }

    if (
      this._goodsName?.nativeElement.value === '' ||
      this._goodsName?.nativeElement.value === undefined
    ) {
      this.toastr.error('상품명을 선택하세요', '입력오류');
      this._goodsName?.nativeElement.focus();
      return;
    }

    console.log('한국어', this._korean?.nativeElement.value);
    if (
      this._korean?.nativeElement.value === '' ||
      this._korean?.nativeElement.value === undefined
    ) {
      this.toastr.error('한국어를 입력하세요', '입력오류');
      this._korean?.nativeElement.focus();
      return;
    }

    if (
      this._english?.nativeElement.value === '' ||
      this._english?.nativeElement.value === undefined
    ) {
      this.toastr.error('영어를 입력하세요', '입력오류');
      this._english?.nativeElement.focus();
      return;
    }

    if (
      this._japanese?.nativeElement.value === '' ||
      this._japanese?.nativeElement.value === undefined
    ) {
      this.toastr.error('일본어를 입력하세요', '입력오류');
      this._japanese?.nativeElement.focus();
      return;
    }

    if (
      this._chinese?.nativeElement.value === '' ||
      this._chinese?.nativeElement.value === undefined
    ) {
      this.toastr.error('중국어를 입력하세요', '입력오류');
      this._chinese?.nativeElement.focus();
      return;
    }

    if (
      this._price?.nativeElement.value === '' ||
      this._price?.nativeElement.value === undefined
    ) {
      this.toastr.error('가격을 입력하세요', '입력오류');
      this._price?.nativeElement.focus();
      return;
    }

    let cnt: number = 0;

    this.startLoading();
    const resGoods = await this.addGood();

    console.log('저장 결과값', resGoods);

    if (resGoods) {
      if (resGoods.header.res_code === 'ok') {
        cnt++;
        const resLang = await this.addLang(resGoods.data[0].max_goods_Id);
        if (resLang) {
          if (resLang.header) {
            if (resLang.header.res_code === 'ok') {
              cnt++;
              //   this.toastr.success(
              //     resLang.header.res_msg || '다국어 저장에 성공하였습니다',
              //     '저장성공'
              //   );
            } else {
              //   this.toastr.success(
              //     resLang.header.res_msg || '다국어 저장에 실패하였습니다',
              //     '저장실패'
              //   );
            }
          } else {
            this.toastr.error('다국어 저장에 실패하였습니다[헤더]', '저장실패');
          }
        } else {
          this.toastr.error('다국어 저장에 실패하였습니다[에러]', '저장실패');
        }
      }
    }

    if (cnt >= 2) {
      this.toastr.success('상품 과 다국어 저장에 성공하였습니다', '저장성공');
    }

    this.stopLoading();

    await this.search(this.receivedPosInfo);

    console.groupEnd();
    this.popup_close('.add_popup');
  }

  onFileClick(event: Event) {
    console.log('click');
    this._imgFile?.nativeElement.click();
  }

  dataURLtoFile(dataurl: any, filename: string) {
    console.log('dataurl', dataurl);

    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  async addGood() {
    console.group('저장 통신');
    const formData = new FormData();

    if (this._imgFile) {
      const files = this._imgFile.nativeElement.files;
      console.log('files', files, 'files.length', files?.length);
      if (files && files.length > 0) {
        console.log('File selected: >> ', files[0]);
        //const reduceFile = await this.reduceFileSize(files[0], 605, 550);
        const reduceFile = await this.reduceFileSize(this.file!, 605, 550);

        //formData.append('file', reduceFile);
        formData.append('file', this.file!);
        console.log('reduceFile', reduceFile);
      } else {
        console.log('No file selected');
        //this.toastr.error('이미지를 선택해 주세요', '입력오류');
        //return;
      }
    } else {
    }

    let rate = '0';

    if (
      this._rate?.nativeElement.value === '' ||
      this._rate?.nativeElement.value === undefined
    ) {
      rate = '0';
    } else {
      rate = this._rate?.nativeElement.value;
      //console.log(typeof rate, rate);
    }

    let ratePrice = '0';
    if (
      this._ratePrice?.nativeElement.value === '' ||
      this._ratePrice?.nativeElement.value === undefined
    ) {
      ratePrice = '0';
    } else {
      ratePrice = this._ratePrice?.nativeElement.value;
    }

    if (this.receivedPosInfo) {
      formData.append('agency', this.receivedPosInfo.AGENCY_CODE);
      formData.append('fran', this.receivedPosInfo.FRANCHISE_CODE);
      formData.append('brand', this.receivedPosInfo.BRAND_CODE);
      formData.append('store', this.receivedPosInfo.STORE_CODE);
      formData.append('goodsid', '');
      formData.append('pos', this.receivedPosInfo.POS_NO);
      formData.append('goodsname', this._goodsName?.nativeElement.value);
      formData.append('categoryBig', this.selectBigCategoryCode);
      formData.append('categoryMiddle', this.selectMiddleCategoryCode);
      formData.append('categorySmall', this.selectSmallCategoryCode);
      formData.append('salePrice', this._price?.nativeElement.value);
      formData.append('eventSdate', '');
      formData.append('eventEdate', '');
      formData.append('eventPrice', '0');
      formData.append('eventYN', 'N');
      formData.append('saleYN', this._useYN?.nativeElement.checked ? 'Y' : 'N');
      formData.append('custCD', '');
      formData.append('taxYN', this._taxYN?.nativeElement.checked ? 'Y' : 'N');
      formData.append('bottleCD', '');
      formData.append('bottleAmt', '0');
      formData.append('deleteDate', '');
      formData.append('goodsBigNm', this.selectBigCategroyName);
      formData.append('goodsMiddileNm', this.selectMiddleCategroyName);
      formData.append(
        'juniorYN',
        this._adultYN?.nativeElement.checked ? 'N' : 'Y'
      );
      formData.append('pointSaveYN', 'N');
      formData.append('itemCommissionYN', 'N');
      formData.append('itemCommissionGB', '10');
      formData.append('itemCommissionRate', '0');
      formData.append('itemCashgateHead', '');
      formData.append('itemCashgateHeadNM', '');
      formData.append('itemCashgateStCD', '');
      formData.append('itemCashgateStNM', '');
      formData.append('itemCashgateStMethod', '');
      formData.append('itemCashgateSEQDFT', '0');
      formData.append('onlyCashYN', 'N');
      formData.append('korailPassDcType', '');
      formData.append('harmYN', 'N');
      formData.append('harmCN', '');
      formData.append('expCheckYN', 'N');
      formData.append('expCheckType', '');
      formData.append('expTime', '');
      formData.append('timeBarcodeType', '');
      formData.append('cookYN', 'N');
      formData.append('CookClassNo', '');
      formData.append('dcYN', 'N');
      formData.append('orderPrinterYN', 'N');
      formData.append('cultureYN', 'N');
      formData.append('description', '');
      formData.append('itemDetailNM', this.selectSmallCategroyName);
      formData.append(
        'hasOption',
        this._optEnable?.nativeElement.checked ? 'Y' : 'N'
      );
      formData.append('offPrice', ratePrice);
      formData.append('offPercent', rate);
      formData.append('isDetail', 'N');
      formData.append('imageName', '');
      formData.append('filePath', './img/goods/');
      formData.append('serverFolderPath', '');
      formData.append('imageIsChange', 'false');
      formData.append('oldImageFile', '');
    }

    console.log('formData', formData);

    const controller = new AbortController();
    const timeout = setTimeout(() => {
      controller.abort();
    }, 60 * 1000); // 60초 타임아웃

    const url = `${this.utilsService.getAPIsUrl()}/goods/addGoods`;
    this.isLoading = true;
    this.spinner.show();
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          // 'Content-Type': 'multipart/form-data' 넣지마시오!!
        },
        body: formData,
        signal: controller.signal,
      });

      const data = await response.json();
      console.log('Response:', data);
      console.log(data.header.res_code);

      if (data.header.res_code === 'ok') {
        console.log(data.header.res_msg);
        // this.toastr.success(
        //   data.header.res_msg + ' 상품' || '상품 저장에 성공하였습니다',
        //   '저장성공'
        // );
      } else {
        console.log(data.header.res_msg);
        this.toastr.error(
          data.header.res_msg + ' 상품' || '상품 저장에 실패하였습니다',
          '저장실패'
        );
      }

      return data;
    } catch (err) {
      console.error(err);
      this.toastr.error(
        err instanceof Error ? err.message : 'An unknown error occurred',
        '오류발생'
      );
      this.isLoading = false;
      this.spinner.hide();
      throw err;
    } finally {
      clearTimeout(timeout);
      console.log('finally');
      this.isLoading = false;
      this.spinner.hide();
      console.groupEnd();
    }
  }

  async editGood() {
    console.group('수정 통신');
    const formData = new FormData();
    try {
      let rate = '0';

      if (
        this._edit_rate?.nativeElement.value === '' ||
        this._edit_rate?.nativeElement.value === undefined
      ) {
        rate = '0';
      } else {
        rate = this._edit_rate?.nativeElement.value;
        //console.log(typeof rate, rate);
      }

      let ratePrice = '0';
      if (
        this._edit_ratePrice?.nativeElement.value === '' ||
        this._edit_ratePrice?.nativeElement.value === undefined
      ) {
        ratePrice = '0';
      } else {
        ratePrice = this._edit_ratePrice?.nativeElement.value;
      }

      let imgChange = false;

      const img = this._editImg?.nativeElement.src;
      if (
        (typeof img === 'string' && img.includes('null')) ||
        img === '' ||
        img === null
      ) {
        console.log('1. 선택된 이미지 없음');
        imgChange = false;
      } else {
        if (this._editImgFile) {
          const files = this._editImgFile.nativeElement.files;
          console.log('files', files, 'files.length', files?.length);
          if (files && files.length > 0) {
            console.log('2. 파일 이미지 있음 >> ', files[0]);
            // 이경우는 사용자가 이미지 교체를 위해서 선택한 경우
            imgChange = true;
            if (this._editImgFile) {
              const files = this._editImgFile.nativeElement.files;
              console.log('files', files, 'files.length', files?.length);
              if (files && files.length > 0) {
                console.log('File selected: >> ', files[0]);
                const reduceFile = await this.reduceFileSize(
                  this.file2!,
                  605,
                  550
                );
                formData.append('file', reduceFile);
                //formData.append('file', this.file2!);
                formData.append(
                  'oldImageFile',
                  this.selectedCurItem.ServerFolderPath
                );
                formData.append('serverFolderPath', '');
                formData.append('imageName', '');
                //imageName
              } else {
                console.log('No file selected');
                imgChange = false;
              }
            } else {
              imgChange = false;
              formData.append('imageName', this.selectedCurItem.imageName);
              formData.append(
                'serverFolderPath',
                this.selectedCurItem.ServerFolderPath
              );
              formData.append(
                'oldImageFile',
                this.selectedCurItem.ServerFolderPath
              );
            }
          } else {
            console.log('3. 파일 이미지 없음');
            // 사용자가 이미지를 선택하지 않음. 그래도 간다는 의미
            imgChange = false;
            formData.append('imageName', this.selectedCurItem.imageName);
            formData.append(
              'serverFolderPath',
              this.selectedCurItem.ServerFolderPath
            );
            formData.append(
              'oldImageFile',
              this.selectedCurItem.ServerFolderPath
            );
          }
        } else {
          console.log('4. 엘리먼트 자체가 없음');
          imgChange = false;
          formData.append('imageName', this.selectedCurItem.imageName);
          formData.append(
            'serverFolderPath',
            this.selectedCurItem.ServerFolderPath
          );
          formData.append(
            'oldImageFile',
            this.selectedCurItem.ServerFolderPath
          );
        }
      }

      if (this.receivedPosInfo) {
        formData.append('agency', this.receivedPosInfo.AGENCY_CODE);
        formData.append('fran', this.receivedPosInfo.FRANCHISE_CODE);
        formData.append('brand', this.receivedPosInfo.BRAND_CODE);
        formData.append('store', this.receivedPosInfo.STORE_CODE);
        formData.append('goodsid', this._edit_goods_code?.nativeElement.value);
        formData.append('pos', this.receivedPosInfo.POS_NO);
        formData.append(
          'goodsname',
          this._edit_goods_name?.nativeElement.value
        );
        formData.append('categoryBig', this.selectBigCategoryCode);
        formData.append('categoryMiddle', this.selectMiddleCategoryCode);
        formData.append('categorySmall', this.selectSmallCategoryCode);
        formData.append('salePrice', this._edit_price?.nativeElement.value);
        formData.append('eventSdate', '');
        formData.append('eventEdate', '');
        formData.append('eventPrice', '0');
        formData.append('eventYN', 'N');
        formData.append(
          'saleYN',
          this._edit_sale_yn?.nativeElement.checked ? 'Y' : 'N'
        );
        formData.append('custCD', '');
        formData.append(
          'taxYN',
          this._edit_taxYN?.nativeElement.checked ? 'Y' : 'N'
        );
        formData.append('bottleCD', '');
        formData.append('bottleAmt', '0');
        formData.append('deleteDate', '');
        formData.append('goodsBigNm', this.selectBigCategroyName);
        formData.append('goodsMiddileNm', this.selectMiddleCategroyName);
        formData.append(
          'juniorYN',
          this._edit_adult_yn?.nativeElement.checked ? 'N' : 'Y'
        );
        formData.append('pointSaveYN', 'N');
        formData.append('itemCommissionYN', 'N');
        formData.append('itemCommissionGB', '10');
        formData.append('itemCommissionRate', '0');
        formData.append('itemCashgateHead', '');
        formData.append('itemCashgateHeadNM', '');
        formData.append('itemCashgateStCD', '');
        formData.append('itemCashgateStNM', '');
        formData.append('itemCashgateStMethod', '');
        formData.append('itemCashgateSEQDFT', '0');
        formData.append('onlyCashYN', 'N');
        formData.append('korailPassDcType', '');
        formData.append('harmYN', 'N');
        formData.append('harmCN', '');
        formData.append('expCheckYN', 'N');
        formData.append('expCheckType', '');
        formData.append('expTime', '');
        formData.append('timeBarcodeType', '');
        formData.append('cookYN', 'N');
        formData.append('CookClassNo', '');
        formData.append('dcYN', 'N');
        formData.append('orderPrinterYN', 'N');
        formData.append('cultureYN', 'N');
        formData.append('description', '');
        formData.append('itemDetailNM', this.selectSmallCategroyName);
        formData.append(
          'hasOption',
          this._edit_optEnable?.nativeElement.checked ? 'Y' : 'N'
        );
        formData.append('offPrice', ratePrice);
        formData.append('offPercent', rate);
        formData.append('isDetail', 'N');
        //formData.append('imageName', '');
        formData.append('filePath', './img/goods/');
        //formData.append('serverFolderPath', '');
        formData.append('imageIsChange', `${imgChange}`);
        //formData.append('oldImageFile', '');
      }

      const controller = new AbortController();
      const timeout = setTimeout(() => {
        controller.abort();
      }, 60 * 1000); // 60초 타임아웃

      const url = `${this.utilsService.getAPIsUrl()}/goods/editGoods`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          // 'Content-Type': 'multipart/form-data' 넣지마시오!!
        },
        body: formData,
        signal: controller.signal,
      });

      const data = await response.json();

      console.log('Response:', data);
      console.log(data.header.res_code);

      if (data.header.res_code === 'ok') {
        console.log(data.header.res_msg);
        // this.toastr.success(
        //   data.header.res_msg + ' 상품' || '상품 수정에 성공하였습니다',
        //   '수정성공'
        // );
      } else {
        console.log(data.header.res_msg);
        this.toastr.error(
          data.header.res_msg + ' 상품' || '상품 수정에 실패하였습니다',
          '수정실패'
        );
      }
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      console.log('goods edit finally');

      console.groupEnd();
    }
  }

  async addLang(goods_id: string) {
    console.group('다국어 저장');

    try {
      const url = `${this.utilsService.getAPIsUrl()}/lang/addLang?agency=${
        this.receivedPosInfo?.AGENCY_CODE
      }&fran=${this.receivedPosInfo?.FRANCHISE_CODE}&brand=${
        this.receivedPosInfo?.BRAND_CODE
      }&store=${this.receivedPosInfo?.STORE_CODE}&pos=${
        this.receivedPosInfo?.POS_NO
      }&code=${goods_id}
	  &gubun=goods&big=${this.selectBigCategoryCode}&middle=${
        this.selectMiddleCategoryCode
      }&small=${this.selectSmallCategoryCode}&korean=${
        this._korean?.nativeElement.value
      }&english=${this._english?.nativeElement.value}&japanese=${
        this._japanese?.nativeElement.value
      }
	  &chinese=${this._chinese?.nativeElement.value}&lvl=0`;
      const method = 'POST';

      this.spinner.show();
      // 서버와 통신
      const response = await fetch(url, {
        method: method,
      });
      const data = await response.json();
      this.spinner.hide();
      if (data.header.res_code === 'ok') {
        console.log(data.header.res_msg);
        // this.toastr.success(
        //   data.header.res_msg + ' 다국어' || '다국어 저장에 성공하였습니다',
        //   '저장성공'
        // );
      } else {
        console.log(data.header.res_msg);
        this.toastr.error(
          data.header.res_msg + ' 다국어' || '다국어 저장에 실패하였습니다',
          '저장실패'
        );
      }

      return data;
    } catch (err) {
      console.error(err);
      this.spinner.hide();
      throw err;
    } finally {
      console.groupEnd();
      this.spinner.hide();
    }
  }

  async editLang(code: string) {
    console.group('수정 통신');
    try {
      const url = `${this.utilsService.getAPIsUrl()}/lang/editLang?agency=${
        this.receivedPosInfo?.AGENCY_CODE
      }&fran=${this.receivedPosInfo?.FRANCHISE_CODE}&brand=${
        this.receivedPosInfo?.BRAND_CODE
      }&store=${this.receivedPosInfo?.STORE_CODE}&pos=${
        this.receivedPosInfo?.POS_NO
      }&code=${code}
		&gubun=goods&big=${this.selectBigCategoryCode}&middle=${
        this.selectMiddleCategoryCode
      }&small=${this.selectSmallCategoryCode}&korean=${
        this._edit_korean?.nativeElement.value
      }&english=${this._edit_english?.nativeElement.value}&japanese=${
        this._edit_japanese?.nativeElement.value
      }
		&chinese=${this._edit_chinese?.nativeElement.value}&lvl=0`;

      const method = 'PUT';
      // 서버와 통신
      const response = await fetch(url, {
        method: method,
      });
      const data = await response.json();
      if (data.header.res_code === 'ok') {
        console.log(data.header.res_msg);
        // this.toastr.success(
        //   data.header.res_msg + ' 다국어' || '다국어 수정에 성공하였습니다',
        //   '수정성공'
        // );
      } else {
        console.log(data.header.res_msg);
        this.toastr.error(
          data.header.res_msg + ' 다국어' || '다국어 수정에 실패하였습니다',
          '수정실패'
        );
      }
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      console.groupEnd();
    }
  }

  async getOneLang(
    agency: string,
    fran: string,
    brand: string,
    store: string,
    pos: string,
    code: string
  ) {
    try {
      const url = `${this.utilsService.getAPIsUrl()}/lang/selectOneLang?agency=${agency}&fran=${fran}&brand=${brand}&store=${store}
		&pos=${pos}&gubun=goods&lvl=${0}&code=${code}`;
      //   const method = 'GET';

      this.startLoading();
      const response = await fetch(url);
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async getBigCategoryList() {
    try {
      const url = `${this.utilsService.getAPIsUrl()}/category/getCategoryList?agency=${
        this.receivedPosInfo?.AGENCY_CODE
      }&fran=${this.receivedPosInfo?.FRANCHISE_CODE}&brand=${
        this.receivedPosInfo?.BRAND_CODE
      }&store=${this.receivedPosInfo?.STORE_CODE}&pos=${
        this.receivedPosInfo?.POS_NO
      }&lvl=1`;
      // 서버와 통신

      this.startLoading();
      const response = await fetch(url);
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async getMiddleCategoryList(upper?: string) {
    try {
      const url = `${this.utilsService.getAPIsUrl()}/category/getCategoryList?agency=${
        this.receivedPosInfo?.AGENCY_CODE
      }&fran=${this.receivedPosInfo?.FRANCHISE_CODE}&brand=${
        this.receivedPosInfo?.BRAND_CODE
      }&store=${this.receivedPosInfo?.STORE_CODE}&pos=${
        this.receivedPosInfo?.POS_NO
      }&upper=${upper}&lvl=2`;

      // 서버와 통신
      console.log('중분류 조회', url);

      const response = await fetch(url);
      const data = await response.json();

      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getSmallCategoryList(upper?: string) {
    try {
      const url = `${this.utilsService.getAPIsUrl()}/category/getCategoryList?agency=${
        this.receivedPosInfo?.AGENCY_CODE
      }&fran=${this.receivedPosInfo?.FRANCHISE_CODE}&brand=${
        this.receivedPosInfo?.BRAND_CODE
      }&store=${this.receivedPosInfo?.STORE_CODE}&pos=${
        this.receivedPosInfo?.POS_NO
      }&upper=${upper}&lvl=3`;

      // 서버와 통신
      console.log('소분류 조회', url);
      this.startLoading();
      const response = await fetch(url);
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async reduceFileSize(
    file: File,
    maxWidth: number,
    maxHeight: number
  ): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async function (event) {
        const img = new Image();
        img.onload = async function () {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // 이미지 크기를 최대 크기에 맞게 조정
          let newWidth = img.width;
          let newHeight = img.height;
          if (newWidth > maxWidth) {
            newHeight *= maxWidth / newWidth;
            newWidth = maxWidth;
          }
          if (newHeight > maxHeight) {
            newWidth *= maxHeight / newHeight;
            newHeight = maxHeight;
          }

          canvas.width = newWidth;
          canvas.height = newHeight;

          // 캔버스에 이미지 그리기
          ctx?.drawImage(img, 0, 0, newWidth, newHeight);

          // 캔버스에서 이미지를 Blob으로 변환
          canvas.toBlob(
            function (blob) {
              if (blob) {
                // Blob을 파일로 변환
                const fileName = file.name; // 원본 파일 이름을 사용하거나 새로운 파일 이름을 지정할 수 있습니다.
                const options: any = { type: blob.type };
                const fileFromBlob = new File([blob], fileName, options);
                resolve(fileFromBlob);
              } else {
                reject(new Error('Blob 생성에 실패했습니다.'));
              }
            },
            file.type,
            // 'image/jpeg',
            0.9
          ); // JPEG 형식으로 압축, 품질은 0.9로 설정
        };
        img.onerror = reject;
        img.src = event.target?.result as string;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  toggleLang() {
    console.group('동일하게토글');

    console.log('원래값', this.isNameChecked);
    this.isNameChecked = !this.isNameChecked;
    console.log('변경된값', this.isNameChecked);

    if (this.isNameChecked) {
      if (this._goodsName) {
        console.log('동일하게', this._goodsName.nativeElement.value);
        if (this._korean) {
          this._korean.nativeElement.value =
            this._goodsName.nativeElement.value;
        }
        if (this._english) {
          this._english.nativeElement.value =
            this._goodsName.nativeElement.value;
        }
        if (this._japanese) {
          this._japanese.nativeElement.value =
            this._goodsName.nativeElement.value;
        }
        if (this._chinese) {
          this._chinese.nativeElement.value =
            this._goodsName.nativeElement.value;
        }
      }
    } else {
      if (this._goodsName) {
        console.log('동일하지 않게', this._goodsName.nativeElement.value);
        if (this._korean) {
          this._korean.nativeElement.value = '';
        }
        if (this._english) {
          this._english.nativeElement.value = '';
        }
        if (this._japanese) {
          this._japanese.nativeElement.value = '';
        }
        if (this._chinese) {
          this._chinese.nativeElement.value = '';
        }
      }
    }
    console.groupEnd();
  }

  toggleEditLang() {
    this.isEditNameChecked = !this.isEditNameChecked;

    if (this.isEditNameChecked) {
      if (this._edit_goods_name) {
        console.log('동일하게', this._edit_goods_name.nativeElement.value);
        if (this._edit_korean) {
          this._edit_korean.nativeElement.value =
            this._edit_goods_name.nativeElement.value;
        }
        if (this._edit_english) {
          this._edit_english.nativeElement.value =
            this._edit_goods_name.nativeElement.value;
        }
        if (this._edit_japanese) {
          this._edit_japanese.nativeElement.value =
            this._edit_goods_name.nativeElement.value;
        }
        if (this._edit_chinese) {
          this._edit_chinese.nativeElement.value =
            this._edit_goods_name.nativeElement.value;
        }
      }
    } else {
      if (this._edit_goods_name) {
        console.log('동일하지 않게', this._edit_goods_name.nativeElement.value);
        if (this._edit_korean) {
          this._edit_korean.nativeElement.value = '';
        }
        if (this._edit_english) {
          this._edit_english.nativeElement.value = '';
        }
        if (this._edit_japanese) {
          this._edit_japanese.nativeElement.value = '';
        }
        if (this._edit_chinese) {
          this._edit_chinese.nativeElement.value = '';
        }
      }
    }
  }
}
