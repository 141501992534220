<div class="main_wrap">
  <div class="top_select_bg">
    <app-side></app-side>
  </div>
  <div class="container">
    <div class="main_cont">
      <h1>하나시스 <br />상품등록 관리자</h1>
      <div class="menu_list">
        <ul>
          <li class="icon_1">
            <a routerLink="/goods/category">
              <span>카테고리 관리</span>
            </a>
          </li>
          <!-- <li class="icon_2">
              <a href="#">
                <span>메뉴 관리</span>
              </a>
            </li> -->
          <li class="icon_3">
            <a routerLink="/goods/goods">
              <span>상품 관리</span>
            </a>
          </li>
          <!-- <li class="icon_4">
              <a href="#">
                <span>옵션 관리</span>
              </a>
            </li> -->

          <li class="icon_2">
            <a routerLink="/goods/mygoods">
              <span>내상품 관리</span>
            </a>
          </li>
          <li class="icon_5">
            <a routerLink="/env/list">
              <span>환경설정</span>
            </a>
          </li>
          <!-- <li class="icon_6">
          <a routerLink="/help">
            <span>도움말</span>
          </a>
        </li> -->
        </ul>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
