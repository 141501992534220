import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main/main.component';
import { COMPOSITION_BUFFER_MODE, FormsModule } from '@angular/forms';
import { MainRoutingModule } from './main-routing.module';
// import { CommonsModule } from '../commons/commons.module';
// import { SideComponent } from '../commons/side/side.component'; // Adjust the path as needed
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [MainComponent],
  imports: [CommonModule, FormsModule, MainRoutingModule, SharedModule],
  providers: [{ provide: COMPOSITION_BUFFER_MODE, useValue: false }],
  schemas: [NO_ERRORS_SCHEMA],
})
export class MainModule {}
