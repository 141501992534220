import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { LoginRoutingModule } from './login-routing.module';
import { COMPOSITION_BUFFER_MODE, FormsModule } from '@angular/forms';

@NgModule({
  declarations: [LoginComponent],
  imports: [CommonModule, LoginRoutingModule, FormsModule],
  providers: [{ provide: COMPOSITION_BUFFER_MODE, useValue: false }],
  schemas: [NO_ERRORS_SCHEMA],
})
export class LoginModule {}
