<div class="top_select_area">
  <div class="inner">
    <button
      type="button"
      class="menu_open"
      #menuOpenButton
      (click)="OpenButton()"
    ></button>
    <a class="home" (click)="goMain()">home</a>
    <div class="store_list">
      <ul>
        <li>
          <div
            class="custom_select"
            #agency
            [ngStyle]="{ display: showAgencySelect ? 'block' : 'none' }"
          >
            <button type="button" class="select_list_open">대리점</button>
            <div class="select_list">
              <ul>
                <li><button type="button">1호점</button></li>
                <li><button type="button">2호점</button></li>
                <li><button type="button">3호점</button></li>
                <li><button type="button">4호점</button></li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <div
            class="custom_select"
            #fran
            [ngStyle]="{ display: showFranSelect ? 'block' : 'none' }"
          >
            <button type="button" class="select_list_open">프랜차이즈</button>
            <div class="select_list">
              <ul>
                <li><button type="button">1호점</button></li>
                <li><button type="button">2호점</button></li>
                <li><button type="button">3호점</button></li>
                <li><button type="button">4호점</button></li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <div
            class="custom_select"
            #brand
            [ngStyle]="{ display: showBrandSelect ? 'block' : 'none' }"
          >
            <button type="button" class="select_list_open">브랜드</button>
            <div class="select_list">
              <ul>
                <li><button type="button">1호점</button></li>
                <li><button type="button">2호점</button></li>
                <li><button type="button">3호점</button></li>
                <li><button type="button">4호점</button></li>
              </ul>
            </div>
          </div>
        </li>
        <li class="w_long">
          <div class="custom_select">
            <button type="button" class="select_list_open">
              {{ selectedStore }}
            </button>
            <div class="select_list">
              <ul>
                <!-- <li><button type="button">1호점</button></li>
                <li><button type="button">2호점</button></li>
                <li><button type="button">3호점</button></li>
                <li><button type="button">4호점</button></li> -->
                <li *ngFor="let item of store_info">
                  <button type="button" (click)="onStoreClick(item)">
                    {{ item.STORE_NM }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="w_short">
          <div class="custom_select" id="pos_select">
            <button type="button" class="select_list_open">
              {{ selectedPos }}
            </button>
            <div class="select_list">
              <ul>
                <li *ngFor="let item of pos_info">
                  <button type="button" (click)="onPosClick(item)">
                    {{ item.POS_NO }}
                  </button>
                </li>
                <!-- <li><button type="button">1호점</button></li>
                <li><button type="button">2호점</button></li>
                <li><button type="button">3호점</button></li>
                <li><button type="button">4호점</button></li> -->
              </ul>
            </div>
          </div>
        </li>
        <li>
          <input
            type="number"
            placeholder="사업자번호"
            [(ngModel)]="bizNum"
            disabled
          />
        </li>
      </ul>
    </div>
  </div>
  <!-- left_menu_area -->
  <div class="left_menu_area">
    <div class="top_area">
      <div class="top_close">
        <button type="button" class="left_menu_close" (click)="OnClose()">
          닫기
        </button>
      </div>
      <div class="txt">
        <!-- 하나시스 관리자님 <br />
        안녕하세요. -->
        {{ selectedStore }}
      </div>
      <div class="btn_area">
        <ul>
          <li>
            <a class="icon_home" (click)="goMain()">홈으로</a>
          </li>
          <li>
            <a class="icon_logout" (click)="logOut()">로그아웃</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="menu_list">
      <ul>
        <li>
          <a (click)="goCategory()">카테고리 관리</a>
        </li>
        <li><a (click)="goGoods()">상품 관리</a></li>
        <li><a (click)="goMyGoods()">내상품 관리</a></li>
        <!-- <li><a (click)="goHelps()">도움말</a></li> -->
        <li><a (click)="goEnv()">환경설정</a></li>
      </ul>
    </div>
  </div>
  <!-- //left_menu_area -->
  <router-outlet></router-outlet>
</div>
