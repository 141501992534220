import { Component, OnInit } from '@angular/core';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  UrlSegment,
} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from 'src/app/shared/utils.service';

@Component({
  selector: 'app-helps',
  templateUrl: './helps.component.html',
  styleUrls: ['./helps.component.scss'],
})
export class HelpsComponent implements OnInit {
  constructor(
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private toastr: ToastrService
  ) {}
  ngOnInit(): void {
    console.group('도움말');
    const login_info = window.localStorage.getItem('login_info') || null;
    if (!login_info) {
      this.router.navigate(['/login']);
    }
    console.groupEnd();
  }
}
