import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UtilsService } from '../../shared/utils.service';
import { SideComponent } from '../../commons/side/side.component';
import { ToastrService } from 'ngx-toastr';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  UrlSegment,
} from '@angular/router';

interface PosInfo {
  AGENCY_CODE: string;
  FRANCHISE_CODE: string;
  BRAND_CODE: string;
  STORE_CODE: string;
  POS_NO: string;
  POS_TYPE_CODE: number;
  MODEL_CODE: string;
  USE_YN: string;
}

@Component({
  selector: 'app-mygoods',
  templateUrl: './mygoods.component.html',
  styleUrls: ['./mygoods.component.scss'],
})
export class MygoodsComponent implements AfterViewInit, OnInit {
  @ViewChild('_goodsNm') _goodsNm: ElementRef | undefined;
  @ViewChild('_goodsId') _goodsId: ElementRef | undefined;
  @ViewChild('_goodsSaleYN') _goodsSaleYN: ElementRef | undefined;
  @ViewChild('_goodsOrder') _goodsOrder: ElementRef | undefined;
  //   @ViewChild('_goods_x') _goods_x: ElementRef | undefined;
  //   @ViewChild('_goods_y') _goods_y: ElementRef | undefined;
  @ViewChild('_goodsQty') _goodsQty: ElementRef | undefined;
  @ViewChild('_goodsPosX') _goodsPosX: ElementRef | undefined;
  @ViewChild('_goodsPosY') _goodsPosY: ElementRef | undefined;

  @ViewChild('_edit_goodsNm') _edit_goodsNm: ElementRef | undefined;
  @ViewChild('_edit_goodsId') _edit_goodsId: ElementRef | undefined;

  @ViewChild('_edit_goodsSaleYN') _edit_goodsSaleYN: ElementRef | undefined;
  @ViewChild('_edit_order') _edit_order: ElementRef | undefined;
  //@ViewChild('_edit_xSize') _edit_xSize: ElementRef | undefined;
  //@ViewChild('_edit_ySize') _edit_ySize: ElementRef | undefined;
  @ViewChild('_edit_stock') _edit_stock: ElementRef | undefined;
  @ViewChild('_edit_posX') _edit_posX: ElementRef | undefined;
  @ViewChild('_edit_posY') _edit_posY: ElementRef | undefined;

  @ViewChild('_edit_newYn') _edit_newYn: ElementRef | undefined;
  @ViewChild('_edit_bestYn') _edit_bestYn: ElementRef | undefined;
  @ViewChild('_edit_recomandYn') _edit_recomandYn: ElementRef | undefined;
  @ViewChild('_edit_soldout') _edit_soldout: ElementRef | undefined;

  receivedPosInfo: PosInfo | null = null;
  keyword: string = '';
  totCnt: string = '';

  myGoodsList: any = [];
  goodsList: any = [];

  states = ['신규', '인기', '추천', '품절'];

  selectedState: number = -1;

  goods_keyword: string = '';
  selectedIndex: number = -1;

  editMode: boolean = false;

  customSelects!: HTMLElement[];
  checkboxes!: HTMLInputElement[];
  modifyModeButton!: HTMLButtonElement;
  listWrap!: HTMLElement;

  curEditItem: any;

  curSelectedItem: any = {};

  soldoutYes: string = '내상품을 품절 처리 하시겠습니까?';
  sodloutNo: string = '내상품을 품절해지 처리 하시겠습니까?';

  isLoading: boolean = false;

  startLoading() {
    this.isLoading = true;
  }

  stopLoading() {
    this.isLoading = false;
  }

  constructor(
    private utilsService: UtilsService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    console.group('내상품');
    const login_info = window.localStorage.getItem('login_info') || null;
    if (!login_info) {
      this.router.navigate(['/login']);
    }
    console.groupEnd();
  }

  ngAfterViewInit(): void {
    console.group('MyGoods Init');
    console.log('MyGoods ngAfgerViewInit');

    const checkboxes = document.querySelectorAll(
      '.custom_checkbox input[type="checkbox"]'
    );

    console.log('checkbox len', checkboxes.length);

    if (checkboxes.length > 0) {
      checkboxes.forEach((checkbox) => {
        checkbox.addEventListener('change', (event) => {
          const li = (event.target as HTMLInputElement).closest('li');
          if ((event.target as HTMLInputElement).checked) {
            li?.classList.add('select');
          } else {
            li?.classList.remove('select');
          }
        });
      });
    }

    //자바 스크립트 관련
    this.customSelects = Array.from(
      document.querySelectorAll('.custom_select')
    ) as HTMLElement[];
    this.checkboxes = Array.from(
      document.querySelectorAll('.custom_checkbox input[type="checkbox"]')
    ) as HTMLInputElement[];
    this.modifyModeButton = document.querySelector(
      '.btn_modify_mode'
    ) as HTMLButtonElement;
    this.listWrap = document.querySelector('.list_wrap') as HTMLElement;

    this.initCustomSelect();
    console.groupEnd();
  }

  // 자식에게서 받는 데이터
  async onReceivePosInfo(posInfo: PosInfo) {
    console.group('OnRecivePosInfo');

    this.receivedPosInfo = posInfo;
    console.log('자식에게서 받아온값 ', this.receivedPosInfo);
    await this.search(posInfo);

    console.groupEnd();
  }

  goBack() {
    this.router.navigate(['/main']);
  }

  async onSearch() {
    console.group('검색버튼');
    console.log('검색');
    console.groupEnd();
    await this.search(this.receivedPosInfo);
  }

  async onGoodsSearch() {
    console.group('상품 검색버튼');
    console.log('검색');
    console.groupEnd();
    await this.goodsSearch(this.receivedPosInfo);
  }

  async search(item: any) {
    const data = await this.getTotCnt(item);
    console.log('서버에게서 받아온값', data);
    if (data) {
      if (data.header) {
        if (data.header.res_code === 'ok') {
          this.totCnt = data.data[0].myGoodsTotCnt;
        } else {
          console.log('에러 > ', data.header.res_msg);
          this.totCnt = '0';
        }
      } else {
        console.log('헤더가 없음');
        this.totCnt = '0';
      }
    } else {
      console.log('data 가 없음');
      this.totCnt = '0';
    }
    const myGoodsList = await this.getMyGoodsList(item);
    console.log('myGoodsList', myGoodsList);
    if (myGoodsList) {
      if (myGoodsList.header) {
        if (myGoodsList.header.res_code === 'ok') {
          this.myGoodsList = myGoodsList.data;
        } else {
          console.log('에러', myGoodsList.header.res_msg);
          this.myGoodsList = [];
        }
      } else {
        this.myGoodsList = [];
        console.log('헤더가 없음');
      }
    } else {
      this.myGoodsList = [];
      console.log('리스트가 없음');
    }
  }
  async getTotCnt(posInfo: PosInfo) {
    try {
      // 서버와 통신

      const url = `${this.utilsService.getAPIsUrl()}/mygoods/getMyGoodsTotCount?agency=${
        posInfo.AGENCY_CODE
      }&fran=${posInfo.FRANCHISE_CODE}&brand=${posInfo.BRAND_CODE}&store=${
        posInfo.STORE_CODE
      }&pos=${posInfo.POS_NO}&goods_nm=${this.keyword}`;

      this.startLoading();
      const response = await fetch(url);
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async getMyGoodsList(posInfo: PosInfo) {
    try {
      const url = `${this.utilsService.getAPIsUrl()}/mygoods/getMyGoodsListMobile?agency=${
        posInfo.AGENCY_CODE
      }&fran=${posInfo.FRANCHISE_CODE}&brand=${posInfo.BRAND_CODE}&store=${
        posInfo.STORE_CODE
      }&pos=${posInfo.POS_NO}&goods_nm=${this.keyword}`;
      this.startLoading();
      const response = await fetch(url);
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async goodsSearch(item: any) {
    const goodsList = await this.getGoodsList(item);
    console.log('goodsList', goodsList);
    if (goodsList) {
      if (goodsList.header) {
        if (goodsList.header.res_code === 'ok') {
          this.goodsList = goodsList.data;
        } else {
          console.log('에러', goodsList.header.res_msg);
          this.goodsList = [];
        }
      } else {
        this.goodsList = [];
        console.log('헤더가 없음');
      }
    } else {
      this.goodsList = [];
      console.log('리스트가 없음');
    }
  }

  async getGoodsList(posInfo: PosInfo) {
    try {
      // 서버와 통신
      const url = `${this.utilsService.getAPIsUrl()}/goods/getGoodsList?agency=${
        posInfo.AGENCY_CODE
      }&fran=${posInfo.FRANCHISE_CODE}&brand=${posInfo.BRAND_CODE}&store=${
        posInfo.STORE_CODE
      }&pos=${posInfo.POS_NO}&goods_nm=${this.goods_keyword}`;
      this.startLoading();
      const response = await fetch(url);
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async popup_open(name: string, type: string, data?: any) {
    console.group('버튼클릭', type);
    console.log('css => ', name, 'type => ', type);

    if (type === 'add') {
      const obj: any = document.querySelector(name);
      obj.classList.add('active');

      const resGoods = await this.goodsSearch(this.receivedPosInfo);
      this.goods_keyword = '';
      if (this._goodsNm) {
        this._goodsNm.nativeElement.value = '';
      }
      if (this._goodsId) {
        this._goodsId.nativeElement.value = '';
      }

      if (this._goodsSaleYN) {
        this._goodsSaleYN.nativeElement.checked = true;
      }

      if (this._goodsOrder) {
        //this._goodsOrder.nativeElement.value = '';
        const resNextOrder = await this.getNextOrder(this.receivedPosInfo!);

        console.log('수신받은값', resNextOrder);

        if (resNextOrder) {
          if (resNextOrder.header) {
            if (resNextOrder.header.res_code === 'ok') {
              this._goodsOrder.nativeElement.value =
                resNextOrder.data[0].NEXT_DISPORDER;
            } else {
              this._goodsOrder.nativeElement.value = '';
            }
          } else {
            this._goodsOrder.nativeElement.value = '';
          }
        } else {
          this._goodsOrder.nativeElement.value = '';
        }
      }

      //   if (this._goods_x) {
      //     this._goods_x.nativeElement.value = '';
      //   }

      //   if (this._goods_y) {
      //     this._goods_y.nativeElement.value = '';
      //   }

      if (this._goodsQty) {
        this._goodsQty.nativeElement.value = '';
      }

      if (this._goodsPosX) {
        this._goodsPosX.nativeElement.value = '';
      }

      if (this._goodsPosY) {
        this._goodsPosY.nativeElement.value = '';
      }

      const checkNew = document.getElementById('myGoods_state_0');
      if (checkNew instanceof HTMLInputElement) {
        checkNew.checked = false;
      }

      const checkBest = document.getElementById('myGoods_state_1');
      if (checkBest instanceof HTMLInputElement) {
        checkBest.checked = false;
      }

      const checkRecommand = document.getElementById('myGoods_state_2');
      if (checkRecommand instanceof HTMLInputElement) {
        checkRecommand.checked = false;
      }

      const checkSoldOut = document.getElementById('myGoods_state_3');
      if (checkSoldOut instanceof HTMLInputElement) {
        checkSoldOut.checked = false;
      }
    } else if (type === 'del') {
      let i = 0;

      const checkboxes = document.querySelectorAll(
        '.custom_checkbox input[type="checkbox"]'
      );

      checkboxes.forEach((checkbox) => {
        if ((checkbox as HTMLInputElement).checked) {
          const li = checkbox.closest('li');
          if (li) {
            console.log('있음');
            i++;
          } else {
            console.log('없음');
          }
        }
      });

      if (i < 1) {
        this.toastr.error('선택된 항목이 없습니다.', '선택오류');
        return;
      }

      console.log('checked checkbox cnt', i);

      const obj: any = document.querySelector(name);
      obj.classList.add('active');
    } else if (type === 'edit') {
      this.curEditItem = data;
      const obj: any = document.querySelector(name);
      obj.classList.add('active');

      console.log('data => ', data);
      if (this._edit_goodsNm) {
        this._edit_goodsNm.nativeElement.value = data.GOODS_NM;
      }

      if (this._edit_goodsId) {
        this._edit_goodsId.nativeElement.value = data.goodsId;
      }

      if (this._edit_goodsSaleYN) {
        this._edit_goodsSaleYN.nativeElement.checked =
          data.useYN === 'Y' ? true : false;
      }

      if (this._edit_order) {
        this._edit_order.nativeElement.value = data.dispOrder;
      }

      //   if (this._edit_xSize) {
      //     this._edit_xSize.nativeElement.value = data.xSize;
      //   }

      //   if (this._edit_ySize) {
      //     this._edit_ySize.nativeElement.value = data.ySize;
      //   }

      if (this._edit_stock) {
        this._edit_stock.nativeElement.value = data.stockQty;
      }

      if (this._edit_posX) {
        this._edit_posX.nativeElement.value = data.positionX;
      }

      if (this._edit_posY) {
        this._edit_posY.nativeElement.value = data.positionY;
      }

      if (this._edit_newYn) {
        this._edit_newYn.nativeElement.checked =
          data.newYN === 'Y' ? true : false;
      }

      if (this._edit_bestYn) {
        this._edit_bestYn.nativeElement.checked =
          data.bestYN === 'Y' ? true : false;
      }

      if (this._edit_recomandYn) {
        this._edit_recomandYn.nativeElement.value =
          data.recommandYN === 'Y' ? true : false;
      }

      if (this._edit_soldout) {
        this._edit_soldout.nativeElement.value =
          data.soldout === 'Y' ? true : false;
      }

      console.log('갱신할 데이터', this.curEditItem);
    } else if (type === 'soldout') {
      const obj: any = document.querySelector(name);
      obj.classList.add('active');
    }

    console.groupEnd();
  }

  async updateSoldout() {
    try {
      console.group('updateSoldOut');
      let soldout = '';

      if (this.curSelectedItem.soldout === 'Y') {
        soldout = 'N';
      } else {
        soldout = 'Y';
      }

      const method = 'PUT';
      const url = `
		${this.utilsService.getAPIsUrl()}/mygoods/updateSoldOut?agency=${
        this.curSelectedItem.AGENCY_CODE
      }&fran=${this.curSelectedItem.FRANCHISE_CODE}&brand=${
        this.curSelectedItem.BRAND_CODE
      }&store=${this.curSelectedItem.STORE_CD}&pos=${
        this.curSelectedItem.POS_NO
      }&goodsId=${this.curSelectedItem.goodsId}
		   &soldout=${soldout}
		  &posX=${this.curSelectedItem.positionX}&posY=${
        this.curSelectedItem.positionY
      }`;
      console.log('url', url);
      this.startLoading();
      // 서버와 통신
      const response = await fetch(url, {
        method: method,
      });

      console.groupEnd();
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  popup_close(name: string, type: string) {
    const obj: any = document.querySelector(name);
    obj.classList.remove('active');
  }

  onSoldOut(item: any) {
    console.group('soldout');
    console.log('item', item);
    this.curSelectedItem = item;
    this.popup_open('.soldout_popup', 'soldout');
    console.groupEnd();
  }

  toggleState(event: any, index: number) {
    console.group('toggleState');
    console.log('this.selectedState', this.selectedState, 'index', index);
    this.selectedState = this.selectedState === index ? -1 : index;
    console.log('this.selectedState', this.selectedState, 'index', index);

    console.groupEnd();
  }

  selectItem(index: number, item: any) {
    this.selectedIndex = index;
    console.group('상품선택');
    console.log('아이템', item);
    if (this._goodsNm) {
      this._goodsNm.nativeElement.value = item.GOODS_NM;
    }
    if (this._goodsId) {
      this._goodsId.nativeElement.value = item.PLU_CD;
    }

    console.groupEnd();
  }

  async onBtnSave() {
    console.group('저장버튼 클릭');
    if (this._goodsNm) {
      if (this._goodsNm.nativeElement.value === '') {
        this.toastr.error('상품명을 선택하세요', '입력오류');
        this._goodsNm.nativeElement.focus();
        console.groupEnd();
        return;
      }
    }

    if (this._goodsId) {
      if (this._goodsId.nativeElement.value === '') {
        this.toastr.error('상품코드를 선택하세요', '입력오류');
        this._goodsId.nativeElement.focus();
        console.groupEnd();
        return;
      }
    }

    if (this._goodsOrder) {
      if (this._goodsOrder.nativeElement.value === '') {
        this.toastr.error('순서를 입력하세요', '입력오류');
        this._goodsOrder.nativeElement.focus();
        console.groupEnd();
        return;
      }
    }

    // if (this._goods_x) {
    //   if (this._goods_x.nativeElement.value === '') {
    //     this.toastr.error('크기 X 를 입력하세요', '입력오류');
    //     this._goods_x.nativeElement.focus();
    //     console.groupEnd();
    //     return;
    //   }
    // }

    // if (this._goods_y) {
    //   if (this._goods_y.nativeElement.value === '') {
    //     this.toastr.error('크기 Y 를 입력하세요', '입력오류');
    //     this._goods_y.nativeElement.focus();
    //     console.groupEnd();
    //     return;
    //   }
    // }

    if (this._goodsQty) {
      if (this._goodsQty.nativeElement.value === '') {
        this.toastr.error('재고수량 을 입력하세요', '입력오류');
        this._goodsQty.nativeElement.focus();
        console.groupEnd();
        return;
      }
    }

    if (this._goodsPosX) {
      if (this._goodsPosX.nativeElement.value === '') {
        this.toastr.error('위치 X  를 입력하세요', '입력오류');
        this._goodsPosX.nativeElement.focus();
        console.groupEnd();
        return;
      }
    }

    if (this._goodsPosY) {
      if (this._goodsPosY.nativeElement.value === '') {
        this.toastr.error('위치 Y  를 입력하세요', '입력오류');
        this._goodsPosY.nativeElement.focus();
        console.groupEnd();
        return;
      }
    }

    let saleYn = '';

    if (this._goodsSaleYN) {
      saleYn = this._goodsSaleYN.nativeElement.checked ? 'Y' : 'N';
    }

    let newYn = '';

    const checkNew = document.getElementById('myGoods_state_0');
    if (checkNew instanceof HTMLInputElement) {
      newYn = checkNew.checked ? 'Y' : 'N';
    }

    let bestYn = '';
    const checkBest = document.getElementById('myGoods_state_1');
    if (checkBest instanceof HTMLInputElement) {
      bestYn = checkBest.checked ? 'Y' : 'N';
    }

    let recommandYn = '';
    const checkRecommand = document.getElementById('myGoods_state_2');
    if (checkRecommand instanceof HTMLInputElement) {
      recommandYn = checkRecommand.checked ? 'Y' : 'N';
    }

    let soldOut = '';
    const checkSoldOut = document.getElementById('myGoods_state_3');
    if (checkSoldOut instanceof HTMLInputElement) {
      soldOut = checkSoldOut.checked ? 'Y' : 'N';
    }

    const resMyGoods = await this.saveMyGoods(
      this.receivedPosInfo!,
      this._goodsId?.nativeElement.value,
      //   this._goods_x?.nativeElement.value,
      //   this._goods_y?.nativeElement.value,
      saleYn,
      newYn,
      bestYn,
      recommandYn,
      soldOut,
      this._goodsOrder?.nativeElement.value,
      this._goodsQty?.nativeElement.value,
      this._goodsPosX?.nativeElement.value,
      this._goodsPosY?.nativeElement.value
    );

    if (resMyGoods) {
      if (resMyGoods.header) {
        if (resMyGoods.header.res_code === 'ok') {
          this.toastr.success(
            resMyGoods.header.res_msg || '저장에 성공하였습니다.',
            '저장성공'
          );
          this.search(this.receivedPosInfo);
        } else {
          this.toastr.error(
            resMyGoods.header.res_msg || '저장에 실패하였습니다.',
            '저장오류'
          );
          this.search(this.receivedPosInfo);
        }
      } else {
        this.toastr.error('통신 헤더가 없습니다', '저장오류');
        this.search(this.receivedPosInfo);
      }
    } else {
      this.toastr.error('서버로 부터 받은 데이터가 없습니다.', '저장오류');
      this.search(this.receivedPosInfo);
    }

    console.groupEnd();
    this.popup_close('.myGoods_popup', 'add');
  }

  async saveMyGoods(
    posInfo: PosInfo,
    goodsId: string,
    // xSize: string,
    // ySize: string,
    saleYN: string,
    newYN: string,
    bestYN: string,
    recommandYN: string,
    soldout: string,
    order: string,
    stock: string,
    x: string,
    y: string
  ) {
    try {
      const method = 'POST';
      const url = `
	  ${this.utilsService.getAPIsUrl()}/mygoods/addMyGoods?agency=${
        posInfo.AGENCY_CODE
      }&fran=${posInfo.FRANCHISE_CODE}&brand=${posInfo.BRAND_CODE}&store=${
        posInfo.STORE_CODE
      }&pos=${posInfo.POS_NO}&goodsId=${goodsId}
	 	&xSize=${1}&ySize=${1}&useYN=${saleYN}&newYN=${newYN}&bestYN=${bestYN}&recommandYN=${recommandYN}&soldout=${soldout}&dispOrder=${order}&stockQty=${stock}
		&positionX=${x}&positionY=${y}`;

      this.startLoading();
      // 서버와 통신
      const response = await fetch(url, {
        method: method,
      });
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  onEditChange() {
    console.group('편집버튼클릭');
    const wrap = document.getElementById('list_wraps');

    if (wrap) {
      if (this.editMode === true) {
        console.log('수정모드');
        wrap?.classList.remove('modify_mode');
        this.editMode = false;
      } else {
        console.log('수정모드 아님');
        wrap.classList.add('modify_mode');
        this.editMode = true;
      }
    } else {
      console.log('null');
    }

    const editBtn = document.getElementById('editBtn');
    if (editBtn) {
      if (editBtn.classList.contains('cancel')) {
        console.log('cancel');
        editBtn.classList.remove('cancel');
        editBtn.textContent = '편집';
      } else if (editBtn.classList.contains('btn_modify_mode')) {
        console.log('modify_mode');
        editBtn.classList.add('cancel');
        editBtn.textContent = '편집취소';
      }
    }
    console.groupEnd();
  }

  initCustomSelect(): void {
    this.customSelects.forEach((select) => {
      const button = select.querySelector(
        '.select_list_open'
      ) as HTMLButtonElement;
      const options = Array.from(
        select.querySelectorAll('.select_list button')
      ) as HTMLButtonElement[];

      button.addEventListener('click', () => {
        this.customSelects.forEach((s) => {
          if (s !== select) {
            console.log('active');
            s.classList.remove('active');
          }
        });
        select.classList.toggle('active');
      });

      options.forEach((option) => {
        option.addEventListener('click', () => {
          console.log('option');
          button.textContent = option.textContent;
          select.classList.remove('active');
        });
      });
    });

    document.addEventListener('click', (event: any) => {
      if (!event.target.closest('.custom_select')) {
        this.customSelects.forEach((select) => {
          select.classList.remove('active');
        });
      }
    });

    // custom select2

    document.querySelectorAll('.select_list_open').forEach((button) => {
      button.addEventListener('click', () => {
        const customSelect = button.closest('.custom_select2');
        if (customSelect) {
          const options = customSelect.querySelectorAll('.select_list button');
          customSelect.classList.add('active');
          options.forEach((option) => {
            option.addEventListener('click', () => {
              button.textContent = option.textContent;
              customSelect.classList.remove('active');
            });
          });
        }
      });
    });

    document.querySelectorAll('.select_close').forEach(function (button) {
      button.addEventListener('click', function () {
        const customSelect = button.closest('.custom_select2');
        if (customSelect) {
          customSelect.classList.remove('active');
        }
      });
    });
  }

  btnAllClick() {
    console.group('전체선택 클릭');
    const allChk = document.getElementById('allChk') as HTMLInputElement;
    const checkboxes = document.querySelectorAll(
      '.custom_checkbox input[type="checkbox"]'
    );

    checkboxes.forEach((checkbox) => {
      (checkbox as HTMLInputElement).checked = allChk.checked;
    });
    console.groupEnd();
  }

  async soldout_cancel(name: string) {
    const obj: any = document.querySelector(name);
    obj.classList.remove('active');
  }

  async soldout_ok(name: string) {
    const obj: any = document.querySelector(name);
    obj.classList.remove('active');
    const resSoldOut = await this.updateSoldout();
    if (resSoldOut) {
      if (resSoldOut.header) {
        if (resSoldOut.header.res_code === 'ok') {
          this.toastr.success(resSoldOut.header.res_msg + ' 품절', '수정성공');
          this.search(this.receivedPosInfo);
        } else {
          this.toastr.error(resSoldOut.header.res_msg + ' 품절', '수정실패');
          this.search(this.receivedPosInfo);
        }
      } else {
        this.toastr.error('헤더정보가 없습니다. 품절', '수정실패');
        this.search(this.receivedPosInfo);
      }
    } else {
      this.toastr.error('수신정보가 없습니다. 품절', '수정실패');
      this.search(this.receivedPosInfo);
    }
  }

  async popup_ok(name: string) {
    console.group('삭제 OK - 확인 버튼');

    const obj: any = document.querySelector(name);
    obj.classList.remove('active');

    const checkboxes = document.querySelectorAll(
      '.custom_checkbox input[type="checkbox"]'
    );

    let goodsCnt = 0;
    checkboxes.forEach(async (checkbox) => {
      if ((checkbox as HTMLInputElement).checked) {
        const li = checkbox.closest('li');
        if (li) {
          const dataValue = checkbox.getAttribute('data-datas');

          if (dataValue) {
            const itemData = JSON.parse(dataValue);
            console.log('Item data:', itemData);
            const resGoods = await this.delMyGoods(itemData);
            if (resGoods) {
              if (resGoods.header) {
                if (resGoods.header.res_code === 'ok') {
                  goodsCnt++;
                } else {
                }
              }
            }
            console.log('resGoods', resGoods);

            if (goodsCnt > 0) {
              this.toastr.success(
                `상품 삭제가 성공하였습니다. 삭제 ${goodsCnt} 건`,
                '삭제 성공'
              );
            } else {
              this.toastr.error('상품 삭제가 실패하였습니다.', '삭제 오류');
            }

            await this.search(this.receivedPosInfo!);
          } else {
            console.log('No data-datas attribute found');
            await this.search(this.receivedPosInfo!);
          }
        } else {
          console.log('없음');
          await this.search(this.receivedPosInfo!);
        }
      }
    });
    console.groupEnd();
  }

  async delMyGoods(item: any) {
    try {
      const method = 'DELETE';
      const url = `
	  ${this.utilsService.getAPIsUrl()}/mygoods/deleteMyGoods?agency=${
        item.AGENCY_CODE
      }&fran=${item.FRANCHISE_CODE}&brand=${item.BRAND_CODE}&store=${
        item.STORE_CD
      }&pos=${item.POS_NO}&goodsId=${item.goodsId}&positionX=${
        item.positionX
      }&positionY=${item.positionY}
	  `;
      this.startLoading();
      const response = await fetch(url, {
        method: method,
      });
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async onEdit() {
    if (this._edit_goodsNm?.nativeElement.value.trim() === '') {
      this.toastr.error('상품명이 없습니다.', '입력오류');
      this._edit_goodsNm?.nativeElement.focus();
      return;
    }

    if (this._edit_goodsId?.nativeElement.value.trim() === '') {
      this.toastr.error('상품코드가 없습니다.', '입력오류');
      this._edit_goodsId?.nativeElement.focus();
      return;
    }

    if (this._edit_order?.nativeElement.value.trim() === '') {
      this.toastr.error('순서를 입력하세요', '입력오류');
      this._edit_order?.nativeElement.focus();
      return;
    }

    // if (this._edit_xSize?.nativeElement.value.trim() === '') {
    //   this.toastr.error('크기 x 를 입력하세요', '입력오류');
    //   this._edit_xSize.nativeElement.focus();
    //   return;
    // }

    // if (this._edit_ySize?.nativeElement.value.trim() === '') {
    //   this.toastr.error('크기 y 를 입력하세요', '입력오류');
    //   this._edit_ySize.nativeElement.focus();
    //   return;
    // }

    if (this._edit_stock?.nativeElement.value.trim() === '') {
      this.toastr.error('재고수량을 입력하세요', '입력오류');
      this._edit_stock.nativeElement.focus();
      return;
    }

    if (this._edit_posX?.nativeElement.value.trim() === '') {
      this.toastr.error('위치 X를 입력하세요', '입력오류');
      this._edit_posX.nativeElement.focus();
      return;
    }

    if (this._edit_posY?.nativeElement.value.trim() === '') {
      this.toastr.error('위치 Y를 입력하세요', '입력오류');
      this._edit_posY.nativeElement.focus();
      return;
    }
    console.group('수정하기 버튼 클릭');
    const resMyGoods = await this.editMyGoods(
      this.receivedPosInfo!,
      this._edit_goodsId?.nativeElement.value.trim(),
      //this._edit_xSize?.nativeElement.value.trim(),
      //this._edit_ySize?.nativeElement.value.trim(),
      this._edit_goodsSaleYN?.nativeElement.checked === true ? 'Y' : 'N',
      this._edit_newYn?.nativeElement.checked === true ? 'Y' : 'N',
      this._edit_bestYn?.nativeElement.checked === true ? 'Y' : 'N',
      this._edit_recomandYn?.nativeElement.checked === true ? 'Y' : 'N',
      this._edit_soldout?.nativeElement.checked === true ? 'Y' : 'N',
      this._edit_order?.nativeElement.value.trim(),
      this._edit_stock?.nativeElement.value.trim(),
      this._edit_posX?.nativeElement.value.trim(),
      this._edit_posY?.nativeElement.value.trim()
    );

    if (resMyGoods) {
      if (resMyGoods.header) {
        if (resMyGoods.header.res_code === 'ok') {
          this.toastr.success(
            resMyGoods.header.res_msg || '수정에 성공하였습니다.',
            '수정성공'
          );
          this.search(this.receivedPosInfo);
        } else {
          this.toastr.error(
            resMyGoods.header.res_msg || '수정에 실패하였습니다.',
            '수정오류'
          );
          this.search(this.receivedPosInfo);
        }
      } else {
        this.toastr.error('통신 헤더가 없습니다', '수정오류');
        this.search(this.receivedPosInfo);
      }
    } else {
      this.toastr.error('서버로 부터 받은 데이터가 없습니다.', '수정오류');
      this.search(this.receivedPosInfo);
    }

    this.popup_close('.modify_popup', 'edit');
    console.groupEnd();
  }

  getItemData(item: any) {
    return JSON.stringify(item);
  }

  async editMyGoods(
    posInfo: PosInfo,
    goodsId: string,
    //xSize: string,
    //ySize: string,
    saleYN: string,
    newYN: string,
    bestYN: string,
    recommandYN: string,
    soldout: string,
    order: string,
    stock: string,
    x: string,
    y: string
  ) {
    try {
      const url = `${this.utilsService.getAPIsUrl()}/mygoods/updateMyGoods?agency=${
        posInfo.AGENCY_CODE
      }&fran=${posInfo.FRANCHISE_CODE}&brand=${posInfo.BRAND_CODE}&store=${
        posInfo.STORE_CODE
      }&pos=${posInfo.POS_NO}&goodsId=${goodsId}
	  &xSize=${1}&ySize=${1}&useYN=${saleYN}&newYN=${newYN}&bestYN=${bestYN}&recommandYN=${recommandYN}&soldoutYN=${soldout}&order=${order}&stock=${stock}
	 &x=${x}&y=${y}&oriX=${this.curEditItem.positionX}&oriY=${
        this.curEditItem.positionY
      } 
   `;
      const method = 'PUT';

      console.log('MyGoods url ', url);

      this.startLoading();
      // 서버와 통신
      const response = await fetch(url, {
        method: method,
      });
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async getNextOrder(item: any) {
    try {
      console.log('item', item);

      const url = `${this.utilsService.getAPIsUrl()}/mygoods/getNextOrder?agency=${
        item.AGENCY_CODE
      }&fran=${item.FRANCHISE_CODE}&brand=${item.BRAND_CODE}&store=${
        item.STORE_CODE
      }&pos=${item.POS_NO}
		`;
      console.log(url);
      this.startLoading();
      // 서버와 통신
      const response = await fetch(url, {
        method: 'GET',
      });

      const res = await response.json();
      this.stopLoading();
      return res;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }
}
