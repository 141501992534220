<div class="top_select_bg">
  <!-- <div class="top_select_area"> -->
  <app-side (sendPosInfo)="onReceivePosInfo($event)"></app-side>
  <!-- //left_menu_area -->
  <!-- </div> -->
</div>

<section class="container pb_0">
  <div class="sub_cont">
    <div class="page_top_title">
      <a class="page_back" (click)="goBack()">뒤로</a>
      <h1>내상품 관리</h1>
      <div class="ml-auto">
        <button
          type="button"
          class="icon_plus"
          (click)="popup_open('.myGoods_popup', 'add')"
        >
          내상품 추가
        </button>
      </div>
    </div>
    <!-- search -->
    <div class="search_araa">
      <div class="search">
        <input
          type="text"
          placeholder="검색어를 입력해주세요."
          [(ngModel)]="keyword"
          name="keyword"
          (keyup.enter)="onSearch()"
        />
        <button type="button" class="btn_search" (click)="onSearch()">
          검색
        </button>
      </div>
    </div>
    <!-- //search -->
  </div>
  <div class="list_wrap" id="list_wraps">
    <div class="sub_cont">
      <div class="list_top">
        <div class="total">
          상품 <strong>{{ totCnt }}</strong>
        </div>
        <div class="right_btn">
          <button
            type="button"
            class="btn btn-blue-bd btn_modify_mode"
            (click)="onEditChange()"
            id="editBtn"
          >
            편집
          </button>
        </div>
      </div>
      <!-- 편집모드 -->
      <div class="control_area">
        <div class="custom_checkbox">
          <input type="checkbox" id="allChk" (click)="btnAllClick()" />
          <label for="allChk">전체선택</label>
        </div>
        <button
          type="button"
          class="all_delete"
          (click)="popup_open('.delete_popup', 'del')"
        >
          삭제
        </button>
      </div>
      <!-- //편집모드 -->
      <div class="list_area">
        <ul>
          <li *ngFor="let item of myGoodsList; let i = index">
            <div class="custom_checkbox">
              <input
                type="checkbox"
                [id]="'chk_' + (i + 1)"
                [attr.data-datas]="getItemData(item)"
              />
              <label [for]="'chk_' + (i + 1)"></label>
            </div>
            <div class="inner">
              <div class="num">{{ i + 1 }}</div>
              <div class="img_area">
                <img src="{{ item.ServerFolderPath }}" alt="" />
              </div>
              <div class="txt" (click)="onSoldOut(item)">
                <span>{{ item.goodsId }}</span>
                <strong>{{ item.GOODS_NM }}</strong>
                <div class="info">
                  <ul>
                    <li>
                      <div class="tit">사용유무</div>
                      <div class="state">{{ item.useYN }}</div>
                    </li>
                    <li>
                      <div class="tit" style="color: blue">상태</div>
                      <div
                        class="state"
                        [style.color]="item.soldout === 'Y' ? 'red' : '#666666'"
                      >
                        {{ item.state }}
                      </div>
                    </li>
                    <li>
                      <div class="tit">위치</div>
                      <div class="state">
                        ( {{ item.positionX }} , {{ item.positionY }} )
                      </div>
                    </li>
                    <li>
                      <div class="tit">재고</div>
                      <div class="state">{{ item.stockQty }}</div>
                    </li>
                    <li>
                      <div class="tit">순서</div>
                      <div class="state">{{ item.dispOrder }}</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <button
              type="button"
              class="btn_modify"
              (click)="popup_open('.modify_popup', 'edit', item)"
            >
              수정
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- 삭제팝업 -->
  <div class="alert_popup delete_popup">
    <div class="popup_content">
      <div class="txt_area">
        <p>리스트를 삭제 하시겠습니까?</p>
      </div>
      <div class="btn_area">
        <ul>
          <li>
            <button
              type="button"
              class="btn btn-gray-bd"
              (click)="popup_close('.delete_popup', 'del')"
            >
              취소
            </button>
          </li>
          <li>
            <button
              type="button"
              class="btn btn-blue-bg"
              (click)="popup_ok('.delete_popup')"
            >
              확인
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- //삭제팝업 -->

  <!-- 품절팝업 -->
  <div class="alert_popup soldout_popup">
    <div class="popup_content">
      <div class="txt_area">
        <p>{{ curSelectedItem.soldout === "Y" ? sodloutNo : soldoutYes }}</p>
      </div>
      <div class="btn_area">
        <ul>
          <li>
            <button
              type="button"
              class="btn btn-gray-bd"
              (click)="soldout_cancel('.soldout_popup')"
            >
              취소
            </button>
          </li>
          <li>
            <button
              type="button"
              class="btn btn-blue-bg"
              (click)="soldout_ok('.soldout_popup')"
            >
              확인
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- //삭제팝업 -->

  <!-- 수정 팝업 -->
  <div class="popup modify_popup">
    <div class="popup_content">
      <div class="title_area">
        <h1>수정</h1>
        <button
          type="button"
          class="btn_close"
          (click)="popup_close('.modify_popup', 'edit')"
        ></button>
      </div>
      <div class="popup_inner">
        <ul class="form_list">
          <li>
            <div class="tit">선택상품</div>
            <div class="input_area select_product">
              <input type="text" value="" disabled #_edit_goodsNm />
              <input type="text" value="" disabled #_edit_goodsId />
            </div>
          </li>
          <li class="d-flex">
            <div class="tit">판매여부</div>
            <div class="ml-auto">
              <label class="toggle" for="use_yn1">
                <input
                  class="toggle-checkbox"
                  type="checkbox"
                  id="use_yn1"
                  #_edit_goodsSaleYN
                />
                <div class="toggle-switch"></div>
                <span class="toggle-on">Y</span>
                <span class="toggle-off">N</span>
              </label>
            </div>
          </li>
          <li>
            <div class="tit">순서</div>
            <div class="input_area">
              <input type="number" #_edit_order />
            </div>
          </li>

          <!-- <li class="d-flex">
            <div class="tit">크기</div>
            <div class="scale">
              <span>X</span>
              <input type="number" #_edit_xSize />
              <span>Y</span>
              <input type="number" #_edit_ySize />
            </div>
          </li> -->
          <li>
            <div class="tit">재고수량</div>
            <div class="input_area">
              <input type="number" #_edit_stock />
            </div>
          </li>
          <li class="d-flex">
            <div class="tit">해당상품 위치</div>
            <div class="scale">
              <span>X</span>
              <input type="number" #_edit_posX />
              <span>Y</span>
              <input type="number" #_edit_posY />
            </div>
          </li>
          <li>
            <div class="tit">상태</div>
            <div class="check_list">
              <ul>
                <li>
                  <div class="custom_checkbox">
                    <input type="checkbox" id="state_1" #_edit_newYn />
                    <label for="state_1">신규</label>
                  </div>
                </li>
                <li>
                  <div class="custom_checkbox">
                    <input type="checkbox" id="state_2" #_edit_bestYn />
                    <label for="state_2">인기</label>
                  </div>
                </li>
                <li>
                  <div class="custom_checkbox">
                    <input type="checkbox" id="state_3" #_edit_recomandYn />
                    <label for="state_3">추천</label>
                  </div>
                </li>
                <li>
                  <div class="custom_checkbox">
                    <input type="checkbox" id="state_4" #_edit_soldout />
                    <label for="state_4">품절</label>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="fixed_btn">
        <div>
          <button type="button" class="btn btn-blue-bg" (click)="onEdit()">
            수정하기
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- //수정 팝업 -->

  <!-- 내상품등록 팝업 -->
  <div class="popup myGoods_popup">
    <div class="popup_content">
      <div class="title_area">
        <h1>내 상품 등록</h1>
        <button
          type="button"
          class="btn_close"
          (click)="popup_close('.myGoods_popup', 'add')"
        ></button>
      </div>

      <div class="popup_inner">
        <div class="search_area">
          <input
            type="text"
            placeholder="검색어를 입력하세요."
            [(ngModel)]="goods_keyword"
            name="goods_keyword"
            (keyup.enter)="onGoodsSearch()"
          />
          <button type="button" class="btn_search" (click)="onGoodsSearch()">
            검색
          </button>
        </div>
        <div class="mb_table">
          <ul>
            <li
              *ngFor="let item of goodsList; let i = index"
              [class.select]="selectedIndex === i"
              (click)="selectItem(i, item)"
            >
              <div class="info_1">
                <div class="num">{{ i + 1 }}</div>
                <div class="code">{{ item.PLU_CD }}</div>
                <div class="name">{{ item.GOODS_NM }}</div>
              </div>
              <div class="d-flex">
                <div class="img_area">
                  <img src="{{ item.ServerFolderPath }}" alt="" />
                </div>
                <div class="info_3">
                  <ul>
                    <li>
                      <div class="tit">분류</div>
                      <div class="txt">
                        {{ item.ITEM_HEAD_NM }} > {{ item.ITEM_MEDIUM_NM }} >
                        {{ item.ITEM_DETAIL_NM }}
                      </div>
                    </li>
                    <li>
                      <div class="tit">판매가격</div>
                      <div class="txt">
                        <strong>{{ item.SALEPRICE | number : "1.0-0" }}</strong
                        >원
                      </div>
                    </li>
                    <li>
                      <div class="tit">성인유무</div>
                      <div class="txt">
                        {{ item.JUNIOR_YN === "Y" ? "아니요" : "예" }}
                      </div>
                    </li>
                    <li>
                      <div class="tit">옵션여부</div>
                      <div class="txt">
                        {{ item.hasOption === "Y" ? "예" : "아니요" }}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
            </li>
          </ul>
        </div>

        <ul class="form_list">
          <li>
            <div class="tit">선택상품</div>
            <div class="input_area select_product">
              <input
                type="text"
                value=""
                placeholder="상품명"
                disabled
                #_goodsNm
              />
              <input
                type="text"
                value=""
                placeholder="상품코드"
                disabled
                #_goodsId
              />
            </div>
          </li>
          <li></li>
          <li class="d-flex">
            <div class="tit">판매여부</div>
            <div class="ml-auto">
              <label class="toggle" for="use_yn2">
                <input
                  class="toggle-checkbox"
                  type="checkbox"
                  id="use_yn2"
                  #_goodsSaleYN
                />
                <div class="toggle-switch"></div>
                <span class="toggle-on">Y</span>
                <span class="toggle-off">N</span>
              </label>
            </div>
          </li>

          <li>
            <div class="tit">순서</div>
            <div class="input_area">
              <input type="number" #_goodsOrder />
            </div>
          </li>

          <!-- <li class="d-flex">
            <div class="tit">크기</div>
            <div class="scale">
              <span>X</span>
              <input type="number" #_goods_x />
              <span>Y</span>
              <input type="number" #_goods_y />
            </div>
          </li> -->
          <li>
            <div class="tit">재고수량</div>
            <div class="input_area">
              <input type="number" #_goodsQty />
            </div>
          </li>
          <li class="d-flex">
            <div class="tit">해당상품 위치</div>
            <div class="scale">
              <span>X</span>
              <input type="number" #_goodsPosX />
              <span>Y</span>
              <input type="number" #_goodsPosY />
            </div>
          </li>
          <li>
            <div class="tit">상태</div>
            <div class="check_list">
              <ul>
                <li *ngFor="let state of states; let i = index">
                  <div class="custom_checkbox">
                    <input
                      type="checkbox"
                      [id]="'myGoods_state_' + i"
                      [checked]="selectedState === i"
                      (change)="toggleState($event, i)"
                    />
                    <label [for]="'myGoods_state_' + i">{{ state }}</label>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="fixed_btn">
        <div>
          <button type="button" class="btn btn-blue-bg" (click)="onBtnSave()">
            저장하기
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- //내상품등록 팝업 -->
  <div [class]="'loding ' + (isLoading ? 'active' : '')">
    <!-- active 클래스 추가시 활성화 -->
    <div>
      <div class="loader"></div>
      <span class="loding_txt">Loding...</span>
    </div>
  </div>
  <!-- //loding -->
</section>
