import {
  AfterViewInit,
  Component,
  Input,
  ViewChildren,
  ViewChild,
  QueryList,
  ElementRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UtilsService } from '../../shared/utils.service';

interface PosInfo {
  AGENCY_CODE: string;
  FRANCHISE_CODE: string;
  BRAND_CODE: string;
  STORE_CODE: string;
  POS_NO: string;
  POS_TYPE_CODE: number;
  MODEL_CODE: string;
  USE_YN: string;
}

@Component({
  selector: 'app-side',
  templateUrl: './side.component.html',
  styleUrls: ['./side.component.scss'],
})
export class SideComponent implements AfterViewInit {
  @ViewChildren('.custom_select') customSelects!: QueryList<ElementRef>;
  @Output() sendPosInfo = new EventEmitter<PosInfo>();
  //   @ViewChild('agency') agencyElement!: ElementRef;
  isLinkHovered = false;
  isSideMenu = false;
  showAgencySelect = false;
  showFranSelect = false;
  showBrandSelect = false;
  // 스토어 전체값
  store_info: any;
  bizNum: string = '';
  // 선택된 매장 번호
  selectedStore: string = '';
  // 선택된 포스번호
  selectedPos: string = '';
  // 버튼 하나 누를때마다 선택된 storeInfo
  curStoreInfo: any;
  // 포스 전체값
  pos_info: any;
  // 버튼 하나 누를때 마다 선택된 posInfo
  curPosInfo: any;

  onLinkMouseOver() {
    this.isLinkHovered = true;
    console.log(this.isLinkHovered);
    console.log('Mouse over event triggered on the link');
  }

  onLinkMouseOut() {
    this.isLinkHovered = false;
    console.log(this.isLinkHovered);
    console.log('Mouse out event triggered on the link');
  }

  constructor(
    private router: ActivatedRoute,
    private route: Router,
    private utilsService: UtilsService
  ) {}

  async ngOnInit() {
    console.group('사이드메뉴&헤더메뉴');
    console.log('ngOnInit');
    // 매장 정보 가져 오기
    this.store_info = window.localStorage.getItem('store_info');
    if (this.store_info) {
      this.store_info = JSON.parse(this.store_info);
      this.bizNum = this.store_info[0].SAUP_NO;
      this.selectedStore = this.store_info[0].STORE_NM;
      this.curStoreInfo = this.store_info[0];
      const curStore = JSON.stringify(this.curStoreInfo);
      window.localStorage.setItem('cur_store_info', curStore);
    } else {
      this.store_info = [];
      this.bizNum = '';
      this.selectedStore = '';
      this.curStoreInfo = {};
    }
    console.log('store_info', this.store_info, this.store_info.length);
    console.groupEnd();
  }

  async ngAfterViewInit(): Promise<void> {
    console.group('사이드메뉴&헤더메뉴');
    console.log('ngAfterViewInit');

    let loginInfo: any = window.localStorage.getItem('login_info');
    if (loginInfo) {
      loginInfo = JSON.parse(loginInfo);
    } else {
      loginInfo = {};
    }

    console.log(loginInfo);
    if (loginInfo) {
      if (loginInfo.AUTH_CD === '4' || loginInfo.AUTH_CD === 4) {
        console.log('점주권한');
        this.showAgencySelect = false;
        this.showFranSelect = false;
        this.showBrandSelect = false;
      }

      console.log('storeItem', this.store_info);

      const pos_info = await this.getPosInfo(
        this.store_info[0].AGENCY_CODE,
        this.store_info[0].FRANCHISE_CODE,
        this.store_info[0].BRAND_CODE,
        this.store_info[0].STORE_CODE
      );

      console.log('pos_info', pos_info);
      if (pos_info.length > 0) console.log(pos_info[0]);
      if (pos_info.header) {
        if (pos_info.header.res_code === 'ok') {
          this.pos_info = pos_info.data;
          this.selectedPos = pos_info.data[0].POS_NO;
          this.curPosInfo = pos_info.data[0];
        } else {
          this.pos_info = [];
          this.selectedPos = '';
          this.curPosInfo = {};
        }
      }
    }

    //this.initializeCustomSelects();

    //확인
    console.group('내부정보');
    console.log('store_info', this.store_info);
    console.log('bizNum', this.bizNum);
    console.log('selectedStore', this.selectedStore);
    console.log('selectedPos', this.selectedPos);
    console.log('curStoreInfo', this.curStoreInfo);
    console.log('pos_info', this.pos_info);
    console.log('curPosInfo', this.curPosInfo);

    console.groupEnd();
    const posInfo: PosInfo = this.curPosInfo;

    this.sendPosInfo.emit(posInfo);
    console.groupEnd();
  }

  ngOnChanges() {
    console.log('ngOnChanges called in ChildComponent');
  }

  goMain() {
    //console.log('get url', window.location.pathname);
    //console.log('goMain click', this.currentUrl);
    const curPath = window.location.pathname;

    if (curPath !== '/main') {
      console.log('메인이 아님');
      this.route.navigate(['/main']);
    } else {
      console.log('메인임');
      const menuCloseButton = document.querySelector(
        '.left_menu_close'
      ) as HTMLElement;
      menuCloseButton.click();
    }
  }

  logOut() {
    window.localStorage.removeItem('login_info');
    window.localStorage.removeItem('store_info');
    window.localStorage.setItem('auto', 'false');
    this.route.navigate(['/login']);
  }

  goCategory() {
    console.log('goCateogy click');
    const curPath = window.location.pathname;
    if (curPath !== '/goods/category') {
      this.route.navigate(['/goods/category']);
    } else {
      const menuCloseButton = document.querySelector(
        '.left_menu_close'
      ) as HTMLElement;
      menuCloseButton.click();
    }
  }

  goGoods() {
    this.route.navigate(['/goods/goods']);
  }

  goMyGoods() {
    this.route.navigate(['/goods/mygoods']);
  }

  goHelps() {
    this.route.navigate(['/helps/list']);
  }

  goEnv() {
    this.route.navigate(['/env/list']);
  }

  OpenButton() {
    console.log('OpenButton');
    const leftMenuArea = document.querySelector(
      '.left_menu_area'
    ) as HTMLElement;
    //this.initializeCustomSelects();
    //this.initializeMenuToggle();
    if (this.isSideMenu === true) {
      leftMenuArea.classList.remove('active');
      this.isSideMenu = false;
    } else {
      leftMenuArea.classList.add('active');
      this.isSideMenu = true;
    }
  }

  OnClose() {
    console.log('OnClose');

    const leftMenuArea = document.querySelector(
      '.left_menu_area'
    ) as HTMLElement;
    leftMenuArea.classList.remove('active');
    this.isSideMenu = false;
  }

  async onStoreClick(storeItem: any) {
    console.log('storeItem', storeItem);
    this.bizNum = storeItem.SAUP_NO;
    this.curStoreInfo = storeItem;
    this.selectedStore = storeItem.STORE_NM;
    const pos_info = await this.getPosInfo(
      storeItem.AGENCY_CODE,
      storeItem.FRANCHISE_CODE,
      storeItem.BRAND_CODE,
      storeItem.STORE_CODE
    );
    console.log('pos_info', pos_info);
    if (pos_info.length > 0) console.log(pos_info[0]);
    if (pos_info.header) {
      if (pos_info.header.res_code === 'ok') {
        this.pos_info = pos_info.data;
        this.selectedPos = pos_info.data[0].POS_NO;
        this.curPosInfo = pos_info.data[0];
      } else {
        this.pos_info = [];
        this.selectedPos = '';
        this.curPosInfo = {};
      }
    }

    console.log('curStoreInfo');
    //this.initializeCustomSelects();
    const posInfo: PosInfo = this.curPosInfo;

    this.sendPosInfo.emit(posInfo);
  }

  onPosClick(posItem: any) {
    console.log('posItem', posItem);
    this.selectedPos = posItem.POS_NO;
    this.curPosInfo = posItem;

    const posSelectElement = document.getElementById('pos_select');
    posSelectElement?.classList.remove('active');

    const posInfo: PosInfo = this.curPosInfo;

    this.sendPosInfo.emit(posInfo);
  }

  async getPosInfo(
    agency: string,
    fran: string,
    brand: string,
    store: string
  ): Promise<any> {
    try {
      // 서버와 통신
      const url = `${this.utilsService.getAPIsUrl()}/pos/getPosSelectBox?agency=${agency}&fran=${fran}&brand=${brand}&store=${store}`;
      console.log('url', url);

      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  initializeCustomSelect(element: HTMLElement) {
    // 각각의 custom_select 엘리먼트에 대한 이벤트 핸들링 로직 구현
  }

  initializeCustomSelects(): void {
    const customSelects = document.querySelectorAll('.custom_select');

    customSelects.forEach((select) => {
      const button = select.querySelector('.select_list_open') as HTMLElement;
      const options = select.querySelectorAll('.select_list button');

      if (button) {
        button.addEventListener('click', () => {
          customSelects.forEach((s) => {
            console.log('click 1');
            if (s !== select) {
              s.classList.remove('active');
            }
          });
          select.classList.toggle('active');
        });
      }

      options.forEach((option) => {
        option.addEventListener('click', () => {
          console.log('click 2');
          if (button) {
            button.textContent = option.textContent;
          }
          select.classList.remove('active');
        });
      });
    });

    document.addEventListener('click', (event) => {
      if (!(event.target as HTMLElement).closest('.custom_select')) {
        customSelects.forEach((select) => {
          select.classList.remove('active');
        });
      }
    });
  }

  initializeMenuToggle(): void {
    const menuOpenButton = document.querySelector('.menu_open') as HTMLElement;
    const leftMenuArea = document.querySelector(
      '.left_menu_area'
    ) as HTMLElement;
    const menuCloseButton = document.querySelector(
      '.left_menu_close'
    ) as HTMLElement;

    if (menuOpenButton) {
      menuOpenButton.addEventListener('click', () => {
        if (leftMenuArea) {
          leftMenuArea.classList.add('active');
        }
      });
    }

    if (menuCloseButton) {
      menuCloseButton.addEventListener('click', () => {
        if (leftMenuArea) {
          console.log('menuColseButton Click');
          leftMenuArea.classList.remove('active');
        }
      });
    }
  }

  goHome() {}
}
