import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private _gBigInit: boolean = false;
  get gBigInit(): boolean {
    return this._gBigInit;
  }

  set gBigInit(value: boolean) {
    this._gBigInit = value;
  }

  gBigInitDelete(): boolean {
    const temp = this._gBigInit;
    this._gBigInit = false;
    return temp;
  }
  constructor() {}

  getAPIsUrl() {
    return 'https://apis.hanaapi.com';
    // return 'http://localhost:8080';
  }
}
