import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpsComponent } from './helps/helps.component';
import { HelpsRoutingModule } from './helps-routing.module';
import { COMPOSITION_BUFFER_MODE, FormsModule } from '@angular/forms';
import { HelpsViewComponent } from './helps-view/helps-view.component';

@NgModule({
  declarations: [HelpsComponent, HelpsViewComponent],
  imports: [CommonModule, HelpsRoutingModule, FormsModule],
  providers: [{ provide: COMPOSITION_BUFFER_MODE, useValue: false }],
})
export class HelpsModule {}
