import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SideComponent } from '../../commons/side/side.component';
import { UtilsService } from '../../shared/utils.service';
import { ToastrService } from 'ngx-toastr';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  UrlSegment,
} from '@angular/router';

interface PosInfo {
  AGENCY_CODE: string;
  FRANCHISE_CODE: string;
  BRAND_CODE: string;
  STORE_CODE: string;
  POS_NO: string;
  POS_TYPE_CODE: number;
  MODEL_CODE: string;
  USE_YN: string;
}
@Component({
  selector: 'app-env',
  templateUrl: './env.component.html',
  styleUrls: ['./env.component.scss'],
})
export class EnvComponent implements AfterViewInit, OnInit {
  [x: string]: any;
  @ViewChild(SideComponent) sideComponent!: SideComponent;
  receivedPosInfo: PosInfo | null = null;

  keyword: string = '';

  envList: any = [];

  @ViewChild('_id') _id: ElementRef | undefined;
  @ViewChild('_name') _name: ElementRef | undefined;
  @ViewChild('_value') _value: ElementRef | undefined;
  @ViewChild('_flag') _flag: ElementRef | undefined;
  @ViewChild('_optName1') _optName1: ElementRef | undefined;
  @ViewChild('_optValue1') _optValue1: ElementRef | undefined;
  @ViewChild('_optName2') _optName2: ElementRef | undefined;
  @ViewChild('_optValue2') _optValue2: ElementRef | undefined;
  @ViewChild('_optName3') _optName3: ElementRef | undefined;
  @ViewChild('_optValue3') _optValue3: ElementRef | undefined;
  @ViewChild('_optName4') _optName4: ElementRef | undefined;
  @ViewChild('_optValue4') _optValue4: ElementRef | undefined;
  @ViewChild('_optName5') _optName5: ElementRef | undefined;
  @ViewChild('_optValue5') _optValue5: ElementRef | undefined;
  @ViewChild('_optName6') _optName6: ElementRef | undefined;
  @ViewChild('_optValue6') _optValue6: ElementRef | undefined;
  @ViewChild('_optName7') _optName7: ElementRef | undefined;
  @ViewChild('_optValue7') _optValue7: ElementRef | undefined;
  @ViewChild('_optName8') _optName8: ElementRef | undefined;
  @ViewChild('_optValue8') _optValue8: ElementRef | undefined;
  @ViewChild('_optName9') _optName9: ElementRef | undefined;
  @ViewChild('_optValue9') _optValue9: ElementRef | undefined;
  @ViewChild('_optName10') _optName10: ElementRef | undefined;
  @ViewChild('_optValue10') _optValue10: ElementRef | undefined;

  customSelects!: HTMLElement[];
  checkboxes!: HTMLInputElement[];
  modifyModeButton!: HTMLButtonElement;
  listWrap!: HTMLElement;

  isLoading: boolean = false;

  startLoading() {
    this.isLoading = true;
  }

  stopLoading() {
    this.isLoading = false;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    console.group('환경설정');
    const login_info = window.localStorage.getItem('login_info') || null;
    if (!login_info) {
      this.router.navigate(['/login']);
    }
    console.groupEnd();
  }

  ngAfterViewInit(): void {
    console.log('goods ngAfgerViewInit >>');

    const checkboxes = document.querySelectorAll(
      '.custom_checkbox input[type="checkbox"]'
    );

    console.log('checkbox len', checkboxes.length);

    if (checkboxes.length > 0) {
      checkboxes.forEach((checkbox) => {
        checkbox.addEventListener('change', (event) => {
          const li = (event.target as HTMLInputElement).closest('li');
          if ((event.target as HTMLInputElement).checked) {
            li?.classList.add('select');
          } else {
            li?.classList.remove('select');
          }
        });
      });
    }

    //자바 스크립트 관련
    this.customSelects = Array.from(
      document.querySelectorAll('.custom_select')
    ) as HTMLElement[];
    this.checkboxes = Array.from(
      document.querySelectorAll('.custom_checkbox input[type="checkbox"]')
    ) as HTMLInputElement[];
    this.modifyModeButton = document.querySelector(
      '.btn_modify_mode'
    ) as HTMLButtonElement;
    this.listWrap = document.querySelector('.list_wrap') as HTMLElement;

    this.initCustomSelect();
  }

  async onReceivePosInfo(posInfo: PosInfo) {
    this.receivedPosInfo = posInfo;
    console.log('자식에게서 받아온값 ', this.receivedPosInfo);
    await this.getEnvCheck(posInfo);
    await this.search(posInfo);
  }

  goBack() {
    this.router.navigate(['/main']);
  }

  async onSearch() {
    await this.search(this.receivedPosInfo);
  }

  async onDetail(item: any, name: string) {
    console.group('상세');
    console.log('item', item);
    const obj: any = document.querySelector(name);
    obj.classList.add('active');
    //await this.search(this.receivedPosInfo);

    const newItem = Object.fromEntries(
      Object.entries(item).map(([key, value]) => [key.toLowerCase(), value])
    );

    console.log('newTitem', newItem);

    if (this._id) {
      this._id.nativeElement.value = newItem['id'];
    }

    if (this._name) {
      this._name.nativeElement.value = newItem['name'];
    }

    if (this._value) {
      this._value.nativeElement.value = newItem['value'];
    }

    if (this._flag) {
      this._flag.nativeElement.innerHTML = newItem['flag'];
    }

    if (this._optName1) {
      this._optName1.nativeElement.value = newItem['option1_name'];
    }

    if (this._optValue1) {
      this._optValue1.nativeElement.value = newItem['option1_value'];
    }

    if (this._optName2) {
      this._optName2.nativeElement.value = newItem['option2_name'];
    }

    if (this._optValue2) {
      this._optValue2.nativeElement.value = newItem['option2_value'];
    }

    if (this._optName3) {
      this._optName3.nativeElement.value = newItem['option3_name'];
    }

    if (this._optValue3) {
      this._optValue3.nativeElement.value = newItem['option3_value'];
    }

    if (this._optName4) {
      this._optName4.nativeElement.value = newItem['option4_name'];
    }

    if (this._optValue4) {
      this._optValue4.nativeElement.value = newItem['option4_value'];
    }

    if (this._optName5) {
      this._optName5.nativeElement.value = newItem['option5_name'];
    }

    if (this._optValue5) {
      this._optValue5.nativeElement.value = newItem['option5_value'];
    }

    if (this._optName6) {
      this._optName6.nativeElement.value = newItem['option6_name'];
    }

    if (this._optValue6) {
      this._optValue6.nativeElement.value = newItem['option6_value'];
    }

    if (this._optName7) {
      this._optName7.nativeElement.value = newItem['option7_name'];
    }

    if (this._optValue7) {
      this._optValue7.nativeElement.value = newItem['option7_value'];
    }

    if (this._optName8) {
      this._optName8.nativeElement.value = newItem['option8_name'];
    }

    if (this._optValue8) {
      this._optValue8.nativeElement.value = newItem['option8_value'];
    }

    if (this._optName9) {
      this._optName9.nativeElement.value = newItem['option9_name'];
    }

    if (this._optValue9) {
      this._optValue9.nativeElement.value = newItem['option9_value'];
    }

    if (this._optName10) {
      this._optName10.nativeElement.value = newItem['option10_name'];
    }

    if (this._optValue10) {
      this._optValue10.nativeElement.value = newItem['option10_value'];
    }

    console.groupEnd();
  }

  async search(item: any) {
    const envList = await this.getEnvList(item, this.keyword);
    console.log('envList', envList);
    if (envList) {
      if (envList.header) {
        if (envList.header.res_code === 'ok') {
          this.envList = envList.data;
        } else {
          console.log('에러', envList.header.res_msg);
          this.envList = [];
        }
      } else {
        console.log('헤더가 없음');
        this.envList = [];
      }
    } else {
      console.log('리스트가 없음');
      this.envList = [];
    }
  }

  async getEnvList(posInfo: PosInfo, keyword: string) {
    try {
      // 서버와 통신
      const url = `${this.utilsService.getAPIsUrl()}/env/getEnv?agency=${
        posInfo.AGENCY_CODE
      }&fran=${posInfo.FRANCHISE_CODE}&brand=${posInfo.BRAND_CODE}&store=${
        posInfo.STORE_CODE
      }&pos=${posInfo.POS_NO}&keyword=${this.keyword}`;
      console.log('url', url);
      this.startLoading();
      const response = await fetch(url);
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  //

  async editEnv(
    posInfo: PosInfo,
    val1: string,
    val2: string,
    val3: string,
    val4: string,
    val5: string,
    val6: string,
    val7: string,
    val8: string,
    val9: string,
    val10: string,
    id: string
  ) {
    try {
      // 서버와 통신
      const url = `${this.utilsService.getAPIsUrl()}/env/editEnv?agency=${
        posInfo.AGENCY_CODE
      }&fran=${posInfo.FRANCHISE_CODE}&brand=${posInfo.BRAND_CODE}&store=${
        posInfo.STORE_CODE
      }&pos=${
        posInfo.POS_NO
      }&value1=${val1}&value2=${val2}&value3=${val3}&value4=${val4}&value5=${val5}&value6=${val6}&value7=${val7}&value8=${val8}&value9=${val9}&value10=${val10}&id=${id}`;

      console.log('환경설정 수정', url);
      this.startLoading();
      const response = await fetch(url, { method: 'PUT' });
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async getEnvCheck(posInfo: PosInfo) {
    try {
      const url = `${this.utilsService.getAPIsUrl()}/env/getEnvCheck?agency=${
        posInfo.AGENCY_CODE
      }&fran=${posInfo.FRANCHISE_CODE}&brand=${posInfo.BRAND_CODE}&store=${
        posInfo.STORE_CODE
      }&pos=${posInfo.POS_NO}`;
      this.startLoading();
      const response = await fetch(url);
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  popup_open(name: string) {
    const obj: any = document.querySelector(name);
    obj.classList.add('active');
  }

  popup_close(name: string) {
    const obj: any = document.querySelector(name);
    obj.classList.remove('active');
  }

  async onSave() {
    console.group('적용하기');
    console.log('적용하기버튼클릭', '수정이라고 보면된다.');

    const resEditRen = await this.editEnv(
      this.receivedPosInfo!,
      this._optValue1?.nativeElement.value,
      this._optValue2?.nativeElement.value,
      this._optValue3?.nativeElement.value,
      this._optValue4?.nativeElement.value,
      this._optValue5?.nativeElement.value,
      this._optValue6?.nativeElement.value,
      this._optValue7?.nativeElement.value,
      this._optValue8?.nativeElement.value,
      this._optValue9?.nativeElement.value,
      this._optValue10?.nativeElement.value,
      this._id?.nativeElement.value
    );
    if (resEditRen) {
      if (resEditRen.header) {
        if (resEditRen.header.res_code === 'ok') {
          this.toastr.success(
            resEditRen.header.res_msg + ' 환경설정' || '수정완료 하였습니다.',
            '수정완료'
          );
          await this.search(this.receivedPosInfo);
        } else {
          this.toastr.error(
            resEditRen.header.res_msg + ' 환경설정' ||
              '수정에 실패 하였습니다.',
            '수정오류'
          );
          await this.search(this.receivedPosInfo);
        }
      } else {
        this.toastr.error(
          '수신된 헤더값이 없습니다.' + ' 환경설정' ||
            '수정에 실패 하였습니다.',
          '수정오류'
        );
        await this.search(this.receivedPosInfo);
      }
    } else {
      this.toastr.error(
        '수신된 정보가 없습니다.' + ' 환경설정' || '수정에 실패 하였습니다.',
        '수정오류'
      );
      await this.search(this.receivedPosInfo);
    }

    this.popup_close('.add_popup');
    console.groupEnd();
  }

  initCustomSelect(): void {
    this.customSelects.forEach((select) => {
      const button = select.querySelector(
        '.select_list_open'
      ) as HTMLButtonElement;
      const options = Array.from(
        select.querySelectorAll('.select_list button')
      ) as HTMLButtonElement[];

      button.addEventListener('click', () => {
        this.customSelects.forEach((s) => {
          if (s !== select) {
            console.log('active');
            s.classList.remove('active');
          }
        });
        select.classList.toggle('active');
      });

      options.forEach((option) => {
        option.addEventListener('click', () => {
          console.log('option');
          button.textContent = option.textContent;
          select.classList.remove('active');
        });
      });
    });

    document.addEventListener('click', (event: any) => {
      if (!event.target.closest('.custom_select')) {
        this.customSelects.forEach((select) => {
          select.classList.remove('active');
        });
      }
    });

    // custom select2

    document.querySelectorAll('.select_list_open').forEach((button) => {
      button.addEventListener('click', () => {
        const customSelect = button.closest('.custom_select2');
        if (customSelect) {
          const options = customSelect.querySelectorAll('.select_list button');
          customSelect.classList.add('active');
          options.forEach((option) => {
            option.addEventListener('click', () => {
              button.textContent = option.textContent;
              customSelect.classList.remove('active');
            });
          });
        }
      });
    });

    document.querySelectorAll('.select_close').forEach(function (button) {
      button.addEventListener('click', function () {
        const customSelect = button.closest('.custom_select2');
        if (customSelect) {
          customSelect.classList.remove('active');
        }
      });
    });
  }
}
