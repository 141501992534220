import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilsService } from 'src/app/shared/utils.service';
import { Router } from '@angular/router';
const controller = new AbortController();
const { signal } = controller;
import { ToastrService } from 'ngx-toastr';
import { IfStmt } from '@angular/compiler';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('idInput', { static: true })
  idInput!: ElementRef<HTMLInputElement>;

  @ViewChild('passInput', { static: true })
  passInput!: ElementRef<HTMLInputElement>;

  @ViewChild('idRemember', { static: true })
  idRemember!: ElementRef<HTMLInputElement>;

  @ViewChild('autoLogin', { static: true })
  autoLogin!: ElementRef<HTMLInputElement>;

  id: string = '';
  pass: string = '';
  remember: boolean = false;
  auto: boolean = false;

  logoUrl: SafeResourceUrl;

  isLoading: boolean = false;

  startLoading() {
    this.isLoading = true;
  }

  stopLoading() {
    this.isLoading = false;
  }

  constructor(
    private utilsService: UtilsService,
    private router: Router,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer
  ) {
    this.logoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      'assets/img/logo.png'
    );
  }

  ngOnInit(): void {
    const login_info = window.localStorage.getItem('login_info') || null;
    console.log('login_info', login_info);
    const id = window.localStorage.getItem('id') || '';
    console.log('id', id);
    const remember = window.localStorage.getItem('remember') || 'false';
    console.log('remember', remember);
    const auto = window.localStorage.getItem('auto') || 'false';
    console.log('auto', auto);

    this.remember = remember === 'true' ? true : false;
    if (remember === 'true') {
      this.id = id;
    } else {
      this.id = '';
    }

    this.auto = auto === 'true';
    if (login_info) {
      this.router.navigate(['/main']);
    }
  }

  async btnLoginClick() {
    console.group('로그인버튼클릭 ');
    console.log('id', this.id);
    console.log('pass', this.pass);
    console.log('remember', this.remember);
    console.log('auto', this.auto);

    console.groupEnd();

    if (this.id === '') {
      this.toastr.error('아이디를 입력하세요', '입력오류');
      this.idInput.nativeElement.focus();
      return;
    }

    if (this.pass === '') {
      this.toastr.error('패스워드를 입력하세요', '입력오류');
      this.passInput.nativeElement.focus();
      return;
    }

    const url = `${this.utilsService.getAPIsUrl()}/login/getLogin?id=${
      this.id
    }&pass=${this.pass}`;
    const method = 'POST';

    console.log('로그인', method, url);

    // setTimeout(() => {
    //   controller.abort(); // 60 * 500초 후 요청 취소
    // }, 60 * 50000);

    this.startLoading();
    fetch(url, {
      method: method,
      signal,
    })
      .then((response) => response.json())
      .then(async (data) => {
        // 성공적으로 데이터를 가져왔을 때의 처리
        console.log('Data fetched: ', data);
        if (data.header) {
          console.log('data.header', data.header);
          if (data.header.res_code === 'ok') {
            // 로그인 인포, 스토어 인포 저장
            console.log('data', data.data);
            if (data.data.AUTH_CD === 4 || data.data.AUTH_CD === '4') {
              this.stopLoading();
            } else {
              this.toastr.error('점주용 앱입니다.', '권한오류');
              this.stopLoading();
              return;
            }
            var vObj = JSON.stringify(data.data);
            window.localStorage.setItem('login_info', vObj);
            window.localStorage.setItem('remember', `${this.remember}`);
            window.localStorage.setItem('auto', `${this.auto}`);
            window.localStorage.setItem('id', data.data.ID);
            console.log('Login Form > handleSumit ', '이동준비');
            const store_info = await this.getStoreInfo();
            console.log('store_info', store_info);
            const vStore = JSON.stringify(store_info.data);
            window.localStorage.setItem('store_info', vStore);
            const curStore = JSON.stringify(store_info.data[0]);
            window.localStorage.setItem('cur_store_info', curStore);
            // 이동
            //navigate("/category", { replace: true });
            this.stopLoading();
            this.router.navigate(['/main']);
            console.log('Login Form > handleSumit ', 'navigate 호출');
          } else {
            // 에러 메시지 표현
            this.toastr.error(
              data.header.res_msg || '아이디나 패스워드가 틀립니다',
              '입력오류'
            );
            this.stopLoading();
          }
        } else {
          console.error('header 값이 없음');
          this.toastr.error('에러가 발생했습니다', '통신오류');
          this.stopLoading();
        }
      })
      .catch((err) => {
        if (err.name === 'AbortError') {
          console.error('Request timed out');
          this.toastr.error('타임아웃', '시간초가');
          this.stopLoading();
        } else {
          console.error(err);
          this.toastr.error(err, '오류');
          this.stopLoading();
        }
      })
      .finally(() => {
        console.log('finally =>');
        this.stopLoading();
      });
  }

  async getStoreInfo(): Promise<any> {
    try {
      this.startLoading();
      // 서버와 통신
      const response = await fetch(
        `${this.utilsService.getAPIsUrl()}/store/getUseInStores?id=${this.id}`
      );
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }
}
