<div class="top_select_bg">
  <app-side (sendPosInfo)="onReceivePosInfo($event)"></app-side>
</div>

<section class="container pb_0">
  <div class="sub_cont">
    <div class="page_top_title">
      <a class="page_back" (click)="goBack()">뒤로</a>
      <h1>카테고리 관리</h1>
      <div class="ml-auto">
        <button
          type="button"
          class="icon_plus"
          (click)="popupOpen('.add_popup')"
        >
          대분류 추가
        </button>
      </div>
    </div>
    <!-- search -->
    <div class="search_araa">
      <div class="search">
        <input
          type="text"
          placeholder="검색어를 입력해주세요."
          [(ngModel)]="keyword"
          (keyup.enter)="onSearch()"
        />
        <button type="button" class="btn_search" (click)="onSearch()">
          검색
        </button>
      </div>
    </div>
    <!-- //search -->
  </div>
  <div class="list_wrap" id="list_wraps">
    <div class="sub_cont">
      <div class="list_top">
        <div class="total">
          대분류 <strong>{{ totCnt }}</strong>
        </div>
        <div class="right_btn">
          <button
            type="button"
            class="btn btn-blue-bd btn_modify_mode"
            (click)="onEditChange()"
            id="editBtn"
          >
            편집
          </button>
        </div>
      </div>
      <!-- 편집모드 -->
      <div class="control_area">
        <div class="custom_checkbox">
          <input type="checkbox" id="allChk" (click)="btnAllClick()" />
          <label for="allChk">전체선택</label>
        </div>
        <button
          type="button"
          class="all_delete"
          (click)="popup_open('.delete_popup')"
        >
          삭제
        </button>
      </div>
      <!-- //편집모드 -->
      <div class="list_area">
        <ul>
          <li
            *ngFor="let item of categoryList; let i = index"
            (click)="onListClick(item)"
          >
            <div class="custom_checkbox">
              <input
                type="checkbox"
                [id]="'chk_' + (i + 1)"
                [attr.data-datas]="getItemData(item)"
              />
              <label [for]="'chk_' + (i + 1)"></label>
            </div>
            <div class="inner">
              <div class="num">{{ i + 1 }}</div>
              <div class="txt">
                <span>순서 : {{ item.DISPORDER }}</span>
                <span>{{ item.CATEGORYCODE }}</span>
                <strong>{{ item.CATEGORYNAME }}</strong>
              </div>
            </div>
            <button
              type="button"
              class="btn_modify"
              [attr.data-datas]="getItemData(item)"
              (click)="onEdit('.modify_popup', item)"
            >
              수정
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- 삭제팝업 -->
  <div class="alert_popup delete_popup">
    <div class="popup_content">
      <div class="txt_area">
        <p>리스트를 삭제 하시겠습니까?</p>
      </div>
      <div class="btn_area">
        <ul>
          <li>
            <button
              type="button"
              class="btn btn-gray-bd"
              (click)="popup_close('.delete_popup')"
            >
              취소
            </button>
          </li>
          <li>
            <button
              type="button"
              class="btn btn-blue-bg"
              (click)="popup_ok('.delete_popup')"
            >
              확인
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- //삭제팝업 -->

  <!-- 수정 팝업 -->
  <div class="popup modify_popup">
    <div class="popup_content">
      <div class="title_area">
        <h1>수정</h1>
        <button
          type="button"
          class="btn_close"
          (click)="popupClose('.modify_popup')"
        ></button>
      </div>
      <div class="popup_inner">
        <ul class="form_list">
          <li>
            <div class="tit">대분류 값</div>
            <div class="input_area">
              <input
                type="text"
                disabled
                placeholder="자동 생성"
                [(ngModel)]="_categoryCode"
                id="_categoryCode"
              />
            </div>
          </li>
          <li>
            <div class="tit">대분류 명</div>
            <div class="input_area">
              <input
                type="text"
                [(ngModel)]="_categoryName"
                id="_categoryName"
              />
            </div>
          </li>
          <li class="d-flex">
            <div class="tit">사용여부</div>
            <div class="ml-auto">
              <label class="toggle" for="use_yn">
                <input
                  class="toggle-checkbox"
                  type="checkbox"
                  id="use_yn"
                  [(ngModel)]="_useYN"
                />
                <div class="toggle-switch"></div>
                <span class="toggle-on">Y</span>
                <span class="toggle-off">N</span>
              </label>
            </div>
          </li>
          <li>
            <div class="tit">순서</div>
            <div class="input_area">
              <input type="number" [(ngModel)]="_order" id="_order" />
            </div>
          </li>
          <li class="d-flex">
            <div class="tit" style="width: 60%">메뉴 크기</div>
            <div class="scale d-flex">
              <span>X</span>
              <input type="number" [(ngModel)]="_x" id="_x" />
              <span>Y</span>
              <input type="number" [(ngModel)]="_y" id="_y" />
            </div>
          </li>
          <li>
            <div class="tit">상위레벨</div>
            <div class="input_area">
              <input type="text" [(ngModel)]="_upper" id="_upper" disabled />
            </div>
          </li>
          <li class="d-flex">
            <div class="tit">대분류와 동일하게</div>
            <div class="ml-auto" (click)="toggleEditLang()">
              <label class="toggle" for="sameName1">
                <input
                  class="toggle-checkbox"
                  type="checkbox"
                  id="sameName"
                  [(ngModel)]="isEditNameChecked"
                />
                <div class="toggle-switch"></div>
                <span class="toggle-on">Y</span>
                <span class="toggle-off">N</span>
              </label>
            </div>
          </li>
          <li>
            <div class="tit">다국어 ( 한국어 / 영어 / 일본 / 중국어 )</div>
            <div class="input_area input_list">
              <div class="multilingual">
                <!-- 클래스 추가 -->
                <div>
                  <span class="label_txt">한국어</span>
                  <input
                    type="text"
                    placeholder="한국어"
                    [(ngModel)]="_korean"
                    id="_korean"
                  />
                </div>
                <div>
                  <span class="label_txt">영어</span>
                  <input
                    type="text"
                    placeholder="영어"
                    [(ngModel)]="_english"
                    id="_english"
                  />
                </div>
                <div>
                  <span class="label_txt">일본어</span>
                  <input
                    type="text"
                    placeholder="일본어"
                    [(ngModel)]="_japanese"
                    id="_japanese"
                  />
                </div>
                <div>
                  <span class="label_txt">중국어</span>
                  <input
                    type="text"
                    placeholder="중국어"
                    [(ngModel)]="_chinese"
                    id="_chinese"
                  />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="fixed_btn">
        <div>
          <button type="button" class="btn btn-blue-bg" (click)="onEditClick()">
            수정하기
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- //수정 팝업 -->

  <!-- 대분류추가 팝업 -->
  <div class="popup add_popup">
    <div class="popup_content">
      <div class="title_area">
        <h1>대분류 추가</h1>
        <button
          type="button"
          class="btn_close"
          (click)="popupClose('.add_popup')"
        ></button>
      </div>
      <div class="popup_inner">
        <ul class="form_list">
          <li>
            <div class="tit">대분류 값</div>
            <div class="input_area">
              <input
                type="text"
                disabled
                placeholder="자동 생성"
                [(ngModel)]="categoryCode"
              />
            </div>
          </li>
          <li>
            <div class="tit">대분류 명</div>
            <div class="input_area">
              <input type="text" [(ngModel)]="categoryName" id="categoryName" />
            </div>
          </li>
          <li class="d-flex">
            <div class="tit">사용여부</div>
            <div class="ml-auto" (click)="toggleState()">
              <label class="toggle" for="use_yn">
                <input
                  class="toggle-checkbox"
                  type="checkbox"
                  id="use_yn"
                  [(ngModel)]="isChecked"
                  #useYN
                />
                <div class="toggle-switch"></div>
                <span class="toggle-on">Y</span>
                <span class="toggle-off">N</span>
              </label>
            </div>
          </li>
          <li>
            <div class="tit">순서</div>
            <div class="input_area">
              <input type="number" [(ngModel)]="order" id="order" />
            </div>
          </li>
          <li class="d-flex">
            <div class="tit" style="width: 60%">메뉴 크기</div>
            <div class="scale d-flex">
              <span>X</span>
              <input type="number" [(ngModel)]="x" id="x" />
              <span>Y</span>
              <input type="number" [(ngModel)]="y" id="y" />
            </div>
          </li>
          <li>
            <div class="tit">상위레벨</div>
            <div class="input_area">
              <input
                type="text"
                disabled
                placeholder="상위레벨 없음"
                [(ngModel)]="upper"
              />
            </div>
          </li>
          <li class="d-flex">
            <div class="tit">대분류와 동일하게</div>
            <div class="ml-auto" (click)="toggleLang()">
              <label class="toggle" for="sameName1">
                <input
                  class="toggle-checkbox"
                  type="checkbox"
                  id="sameName"
                  [(ngModel)]="isNameChecked"
                  #_sameName
                />
                <div class="toggle-switch"></div>
                <span class="toggle-on">Y</span>
                <span class="toggle-off">N</span>
              </label>
            </div>
          </li>
          <li>
            <div class="tit">다국어 ( 한국어 / 영어 / 일본 / 중국어 )</div>
            <div class="input_area input_list">
              <div class="multilingual">
                <!-- 클래스 추가 -->
                <div>
                  <span class="label_txt">한국어</span>
                  <input
                    type="text"
                    placeholder="한국어"
                    [(ngModel)]="korean"
                    id="korean"
                  />
                </div>
                <div>
                  <span class="label_txt">영어</span>
                  <input
                    type="text"
                    placeholder="영어"
                    [(ngModel)]="english"
                    id="english"
                  />
                </div>
                <div>
                  <span class="label_txt">일본어</span>
                  <input
                    type="text"
                    placeholder="일본어"
                    [(ngModel)]="japanese"
                    id="japanese"
                  />
                </div>
                <div>
                  <span class="label_txt">중국어</span>
                  <input
                    type="text"
                    placeholder="중국어"
                    [(ngModel)]="chinese"
                    id="chinese"
                  />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="fixed_btn">
        <div>
          <button type="button" class="btn btn-blue-bg" (click)="onSave()">
            등록하기
          </button>
          <!-- disabled 속성 삭제시 활성화 -->
        </div>
      </div>
    </div>
  </div>
  <!-- //대분류추가 팝업 -->
  <!-- loding -->
  <div [class]="'loding ' + (isLoading ? 'active' : '')">
    <!-- active 클래스 추가시 활성화 -->
    <div>
      <div class="loader"></div>
      <span class="loding_txt">Loding...</span>
    </div>
  </div>
  <!-- //loding -->
</section>
