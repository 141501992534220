import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvComponent } from './env/env.component';
import { EnvRoutingModule } from './env-routing.module';
import { COMPOSITION_BUFFER_MODE, FormsModule } from '@angular/forms';
import { SideComponent } from '../commons/side/side.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [EnvComponent],
  imports: [CommonModule, EnvRoutingModule, FormsModule, SharedModule],
  providers: [{ provide: COMPOSITION_BUFFER_MODE, useValue: false }],
  schemas: [NO_ERRORS_SCHEMA],
})
export class EnvModule {}
