import {
  Component,
  ViewChild,
  AfterViewInit,
  ElementRef,
  OnInit,
} from '@angular/core';
import { SideComponent } from '../../commons/side/side.component';
import { Location } from '@angular/common';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  UrlSegment,
} from '@angular/router';
import { filter, map, distinctUntilChanged } from 'rxjs/operators';
import { UtilsService } from '../../shared/utils.service';
import { ToastrService } from 'ngx-toastr';
interface PosInfo {
  AGENCY_CODE: string;
  FRANCHISE_CODE: string;
  BRAND_CODE: string;
  STORE_CODE: string;
  POS_NO: string;
  POS_TYPE_CODE: number;
  MODEL_CODE: string;
  USE_YN: string;
}

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements AfterViewInit, OnInit {
  [x: string]: any;
  @ViewChild(SideComponent) sideComponent!: SideComponent;

  //@ViewChild('listWrap', { static: true }) listWrap!: ElementRef;

  @ViewChild('_sameName') _sameName: ElementRef | undefined;

  //
  private previousUrl!: string;
  categoryList: any;

  receivedPosInfo: PosInfo | null = null;
  keyword: string = '';
  totCnt: string = '';

  customSelects!: HTMLElement[];
  checkboxes!: HTMLInputElement[];
  modifyModeButton!: HTMLButtonElement;
  listWrap!: HTMLElement;

  isChecked: boolean = true;

  init: boolean = false;

  editMode: boolean = false;
  editModeBtn: boolean = false;

  isNameChecked: boolean = false;
  isEditNameChecked: boolean = false;

  // 입력 관련 변수들
  categoryCode: string = '';
  categoryName: string = '';
  useYN: string = '';
  order = '';
  x = '';
  y = '';
  upper: string = '';
  korean: string = '';
  english: string = '';
  japanese: string = '';
  chinese: string = '';

  _categoryCode: string = '';
  _categoryName: string = '';
  _useYN: string = '';
  _order = '';
  _x = '';
  _y = '';
  _upper: string = '';
  _korean: string = '';
  _english: string = '';
  _japanese: string = '';
  _chinese: string = '';

  isLoading: boolean = false;

  startLoading() {
    this.isLoading = true;
  }

  stopLoading() {
    this.isLoading = false;
  }

  async onListClick(data: any) {
    if (this.editMode === true) {
      return;
    }

    console.group('리스트');
    console.log('리스트 클릭', data);

    this['router'].navigate(['/goods/middlecategory'], {
      queryParams: { data: JSON.stringify(data) },
    });
    console.groupEnd();
  }

  //order
  async onSave() {
    console.group('저장');
    console.log('저장버튼 클릭');

    const categoryNameInput = document.getElementById('categoryName');
    if (this.categoryName === '') {
      this.toastr.error('대분류 명을 입력하세요.', '입력오류');
      categoryNameInput?.focus();
      return;
    }

    const orderInput = document.getElementById('order');
    if (this.order === '') {
      this.toastr.error('순서를 입력하세요.', '입력오류');
      orderInput?.focus();
      return;
    }

    const xInput = document.getElementById('x');
    if (this.x === '') {
      this.toastr.error('크기 x를 입력하세요.', '입력오류');
      xInput?.focus();
      return;
    }

    const yInput = document.getElementById('y');
    if (this.y === '') {
      this.toastr.error('크기 y를 입력하세요.', '입력오류');
      yInput?.focus();
      return;
    }

    const koreanInput = document.getElementById('korean');
    if (this.korean === '') {
      this.toastr.error('한국어를 입력하세요.', '입력오류');
      koreanInput?.focus();
      return;
    }

    const englishInput = document.getElementById('english');
    if (this.english === '') {
      this.toastr.error('영어를 입력하세요.', '입력오류');
      englishInput?.focus();
      return;
    }

    const japaneseInput = document.getElementById('japanese');
    if (this.japanese === '') {
      this.toastr.error('일본어를 입력하세요.', '입력오류');
      japaneseInput?.focus();
      return;
    }

    const chineseInput = document.getElementById('chinese');
    if (this.chinese === '') {
      this.toastr.error('중국어를 입력하세요.', '입력오류');
      chineseInput?.focus();
      return;
    }

    const data = {
      agency: this.receivedPosInfo?.AGENCY_CODE,
      fran: this.receivedPosInfo?.FRANCHISE_CODE,
      brand: this.receivedPosInfo?.BRAND_CODE,
      store: this.receivedPosInfo?.STORE_CODE,
      pos: this.receivedPosInfo?.POS_NO,
      code: '',
      name: this.categoryName,
      lvl: '1',
      upper: '',
      disorder: this.order,
      x: this.x,
      y: this.y,
      useYN: this.useYN ? 'Y' : 'N',
    };

    const resCategory = await this.saveCategory(data, '1');
    console.log('resCategory');

    let bRes: boolean = false;
    let nextCategoryId: string = '';

    let cnt: number = 0;
    let bCategoryErr: boolean = false;
    let categoryMsg: string = '';
    let msg: string = '';

    if (resCategory) {
      if (resCategory.header) {
        if (resCategory.header.res_code === 'ok') {
          bRes = true;
          nextCategoryId = resCategory.data;
          //   this.toastr.success('카테고리가 저장되었습니다.', '저장 성공');
          categoryMsg = '카테고리가 저장되었습니다';
          msg = '저장 성공';
          cnt++;
        } else {
          this.toastr.error(resCategory.header.res_msg, '저장 오류');
          categoryMsg = resCategory.header.res_msg;
          msg = '저장 오류';
          bCategoryErr = true;
          return;
        }
      } else {
        this.toastr.error('카테고리 헤더 값이 없습니다.', '저장 오류');
        categoryMsg = '카테고리 헤더 값이 없습니다.';
        msg = '저장 오류';
        bCategoryErr = true;
        return;
      }
    } else {
      //   this.toastr.error('카데코리 저장 오류 입니다.', '저장 오류');
      categoryMsg = '카테고리 저장 오류 입니다.';
      msg = '저장오류';
      bCategoryErr = true;
      return;
    }

    const resLang = await this.saveLang(nextCategoryId, '1');
    console.log(resLang);

    if (resLang) {
      if (resLang.header) {
        if (resLang.header.res_code === 'ok') {
          //this.toastr.success('다국어가 저장되었습니다.', '저장 성공');
          cnt++;
          await this.search(this.receivedPosInfo!);
        } else {
          this.toastr.error(resLang.header.res_msg, '저장 오류');
          await this.search(this.receivedPosInfo!);
          return;
        }
      } else {
        this.toastr.error('다국어 헤더 값이 없습니다.', '저장 오류');
        await this.search(this.receivedPosInfo!);
        return;
      }
    } else {
      this.toastr.error('다국어 저장 오류 입니다.', '저장 오류');
      await this.search(this.receivedPosInfo!);
      return;
    }

    if (cnt >= 2) {
      this.toastr.success('저장되었습니다.', '저장 성공');
    }

    // 입력 클리어
    //this.clearInput();
    this.popupClose('.add_popup');
    console.groupEnd();
  }

  async onEditClick() {
    console.group('수정');
    console.log('수정 저장 버튼 클릭');

    const categoryCodeInput = document.getElementById('_categoryCode');
    if (this._categoryCode === '') {
      this.toastr.error('대분류 코드가 없습니다..', '입력오류');
      categoryCodeInput?.focus();
      return;
    }

    const categoryNameInput = document.getElementById('_categoryName');
    if (this._categoryName === '') {
      this.toastr.error('대분류 명을 입력하세요.', '입력오류');
      categoryNameInput?.focus();
      return;
    }

    const orderInput = document.getElementById('_order');

    console.log('this._order', this._order);

    if (this._order === '' || this._order === null) {
      this.toastr.error('순서를 입력하세요.', '입력오류');
      orderInput?.focus();
      return;
    }

    const xInput = document.getElementById('_x');
    if (this._x === '') {
      this.toastr.error('크기 x를 입력하세요.', '입력오류');
      xInput?.focus();
      return;
    }

    const yInput = document.getElementById('_y');
    if (this._y === '') {
      this.toastr.error('크기 y를 입력하세요.', '입력오류');
      yInput?.focus();
      return;
    }

    const koreanInput = document.getElementById('_korean');
    if (this._korean === '') {
      this.toastr.error('한국어를 입력하세요.', '입력오류');
      koreanInput?.focus();
      return;
    }

    const englishInput = document.getElementById('_english');
    if (this._english === '') {
      this.toastr.error('영어를 입력하세요.', '입력오류');
      englishInput?.focus();
      return;
    }

    const japaneseInput = document.getElementById('_japanese');
    if (this._japanese === '') {
      this.toastr.error('일본어를 입력하세요.', '입력오류');
      japaneseInput?.focus();
      return;
    }

    const chineseInput = document.getElementById('_chinese');
    if (this._chinese === '') {
      this.toastr.error('중국어를 입력하세요.', '입력오류');
      chineseInput?.focus();
      return;
    }

    const categoryData = {
      agency: this.receivedPosInfo?.AGENCY_CODE,
      fran: this.receivedPosInfo?.FRANCHISE_CODE,
      brand: this.receivedPosInfo?.BRAND_CODE,
      store: this.receivedPosInfo?.STORE_CODE,
      pos: this.receivedPosInfo?.POS_NO,
      code: this._categoryCode,
      name: this._categoryName,
      lvl: '1',
      upper: '',
      disorder: this._order,
      x: this._x,
      y: this._y,
      useYN: this._useYN,
    };

    let cnt: number = 0;

    const resCategory = await this.editCategory(categoryData);
    console.log('resCategory', resCategory);
    if (resCategory) {
      if (resCategory.header) {
        if (resCategory.header.res_code === 'ok') {
          cnt++;
          //   this.toastr.success('카테고리가 수정되었습니다.', '수장 성공');
        } else {
          this.toastr.error(resCategory.header.res_msg, '수정 오류');
          return;
        }
      } else {
        this.toastr.error('카테고리 헤더 값이 없습니다.', '수정 오류');
        return;
      }
    } else {
      this.toastr.error('카데코리 저장 오류 입니다.', '수장 오류');
      return;
    }

    const langData = {
      agency: this.receivedPosInfo?.AGENCY_CODE,
      fran: this.receivedPosInfo?.FRANCHISE_CODE,
      brand: this.receivedPosInfo?.BRAND_CODE,
      store: this.receivedPosInfo?.STORE_CODE,
      pos: this.receivedPosInfo?.POS_NO,
      code: this._categoryCode,
      gubun: 'category',
      big: '',
      middle: '',
      small: '',
      korean: this._english,
      english: this._english,
      japanese: this._japanese,
      chinese: this._chinese,
      lvl: '1',
    };
    const resLang = await this.editLang(langData);
    console.log('resLang', resLang);

    if (resLang) {
      if (resLang.header) {
        if (resLang.header.res_code === 'ok') {
          //   this.toastr.success('다국어가 수정되었습니다.', '수정 성공');
          cnt++;
          await this.search(this.receivedPosInfo!);
        } else {
          this.toastr.error(resLang.header.res_msg, '수정 오류');
          await this.search(this.receivedPosInfo!);
          return;
        }
      } else {
        this.toastr.error('카테고리 헤더 값이 없습니다.', '수정 오류');
        await this.search(this.receivedPosInfo!);
        return;
      }
    } else {
      this.toastr.error('카데코리 저장 오류 입니다.', '수정 오류');
      await this.search(this.receivedPosInfo!);
      return;
    }

    if (cnt >= 2) {
      this.toastr.success(
        '카테고리(대분류) 과 다국어 수정에 성공하였습니다',
        '수정성공'
      );
    }

    console.log('마지막=>');
    console.groupEnd();

    this.popupClose('.modify_popup');
  }

  async onEdit(name: string, data: any) {
    console.group('수정');
    console.log('수정 버튼 클릭');

    var popup: any = document.querySelector(name);
    popup.classList.add('active');

    // 값 넣기
    const oneLang = await this.getOneLang(data);
    console.log('oneLange', oneLang);

    if (oneLang) {
      if (oneLang.header) {
        if (oneLang.header.res_code === 'ok') {
          this._korean = oneLang.data[0].KOREAN;
          if (this._korean) this._korean = this._korean.trim();
          this._english = oneLang.data[0].ENGLISH;
          if (this._english) this._english = this._english.trim();
          this._japanese = oneLang.data[0].JAPANESE;
          if (this._japanese) this._japanese = this._japanese.trim();
          this._chinese = oneLang.data[0].CHINESE;
          if (this._chinese) this._chinese = this._chinese.trim();
        } else {
          this._korean = '';
          this._english = '';
          this._japanese = '';
          this._chinese = '';
        }
      } else {
        this._korean = '';
        this._english = '';
        this._japanese = '';
        this._chinese = '';
      }
    } else {
      this._korean = '';
      this._english = '';
      this._japanese = '';
      this._chinese = '';
    }

    this._categoryCode = data.CATEGORYCODE;
    this._categoryName = data.CATEGORYNAME;
    this._useYN = data.USEYN;
    this._order = data.DISPORDER;
    this._x = data.XARRAY;
    this._y = data.YARRAY;
    this._upper = data.UPPERCATEGORYCODE;

    console.groupEnd();
  }

  toggleState() {
    this.isChecked = !this.isChecked;
  }

  toggleLang() {
    this.isNameChecked = !this.isNameChecked;

    if (this.isNameChecked) {
      console.log('동일하게', this.categoryName);
      this.korean = this.categoryName;
      this.english = this.categoryName;
      this.japanese = this.categoryName;
      this.chinese = this.categoryName;
    } else {
      console.log('동일하지 않게', this.categoryName);
      this.korean = '';
      this.english = '';
      this.japanese = '';
      this.chinese = '';
    }
  }

  toggleEditLang() {
    this.isEditNameChecked = !this.isEditNameChecked;

    if (this.isEditNameChecked) {
      console.log('동일하게', this._categoryName);
      this._korean = this._categoryName;
      this._english = this._categoryName;
      this._japanese = this._categoryName;
      this._chinese = this._categoryName;
    } else {
      console.log('동일하지 않게', this.categoryName);
      this._korean = '';
      this._english = '';
      this._japanese = '';
      this._chinese = '';
    }
  }

  btnAllClick() {
    console.log('전체선택 클릭');
    const allChk = document.getElementById('allChk') as HTMLInputElement;
    const checkboxes = document.querySelectorAll(
      '.custom_checkbox input[type="checkbox"]'
    );

    checkboxes.forEach((checkbox) => {
      (checkbox as HTMLInputElement).checked = allChk.checked;
    });
  }

  popup_open(name: string) {
    let i = 0;

    const checkboxes = document.querySelectorAll(
      '.custom_checkbox input[type="checkbox"]'
    );

    checkboxes.forEach((checkbox) => {
      if ((checkbox as HTMLInputElement).checked) {
        const li = checkbox.closest('li');
        if (li) {
          console.log('있음');
          i++;
        } else {
          console.log('없음');
        }
      }
    });

    if (i < 1) {
      this.toastr.error('선택된 항목이 없습니다.', '선택오류');
      return;
    }

    console.log('checked checkbox cnt', i);

    const obj: any = document.querySelector(name);
    obj.classList.add('active');
  }
  popup_close(name: string) {
    const obj: any = document.querySelector(name);
    obj.classList.remove('active');
  }

  async popup_ok(name: string) {
    const obj: any = document.querySelector(name);
    obj.classList.remove('active');

    const checkboxes = document.querySelectorAll(
      '.custom_checkbox input[type="checkbox"]'
    );

    checkboxes.forEach(async (checkbox) => {
      let categoryCnt = 0;
      let langCnt = 0;
      if ((checkbox as HTMLInputElement).checked) {
        const li = checkbox.closest('li');
        if (li) {
          const dataValue = checkbox.getAttribute('data-datas');

          if (dataValue) {
            const itemData = JSON.parse(dataValue);
            console.log('Item data:', itemData);

            const categoryList = await this.getCategoryMiddleList(
              itemData,
              itemData.CATEGORYCODE,
              '2'
            );
            console.log('categoryList', categoryList);
            if (categoryList) {
              if (categoryList.header) {
                if (categoryList.header.res_code === 'ok') {
                  this.toastr.error(
                    '중분류가 존재 합니다. 중분류 삭제후 다시 시도하세요',
                    '삭제오류'
                  );
                  return;
                } else {
                  console.log('에러 > ', categoryList.header.res_msg);
                }
              } else {
                console.log('헤더가 없음 > ');
              }
            } else {
              console.log('리스트가 없음 > ');
            }

            const resCategory = await this.deleteCategory(itemData);
            if (resCategory) {
              if (resCategory.header) {
                if (resCategory.header.res_code === 'ok') {
                  categoryCnt++;
                } else {
                }
              } else {
              }
            }
            console.log('resCategory', resCategory);
            const resLang = await this.deleteLang(itemData);
            if (resLang) {
              if (resLang.header) {
                if (resLang.header.res_code === 'ok') {
                  langCnt++;
                } else {
                }
              } else {
              }
            }
            console.log('resLang', resLang);

            if (categoryCnt > 0 && langCnt > 0) {
              this.toastr.success(
                `카테고리 및 다국어 삭제가 성공하였습니다.`,
                '삭제 성공'
              );
            } else {
              this.toastr.error(
                '카테고리 및 다국어 삭제가 실패하였습니다.',
                '삭제 오류'
              );
            }

            await this.search(this.receivedPosInfo!);
            // const data = await this.getTotCnt(this.receivedPosInfo!, '1');
            // if (data) {
            //   if (data.header) {
            //     if (data.header.res_code === 'ok') {
            //       this.totCnt = data.data[0].cnt;
            //     } else {
            //       console.log('에러', data.header.res_msg);
            //     }
            //   } else {
            //     console.log('헤더가 없음');
            //   }
            // } else {
            //   console.log('data 가 없음');
            // }
            // await this.getCategoryList(this.receivedPosInfo!, '1');
          } else {
            console.log('No data-datas attribute found');
            await this.search(this.receivedPosInfo!);
            // const data = await this.getTotCnt(this.receivedPosInfo!, '1');
            // if (data) {
            //   if (data.header) {
            //     if (data.header.res_code === 'ok') {
            //       this.totCnt = data.data[0].cnt;
            //     } else {
            //       console.log('에러', data.header.res_msg);
            //     }
            //   } else {
            //     console.log('헤더가 없음');
            //   }
            // } else {
            //   console.log('data 가 없음');
            // }
            // await this.getCategoryList(this.receivedPosInfo!, '1');
          }
        } else {
          console.log('없음');
          await this.search(this.receivedPosInfo!);
          //   await this.getTotCnt(this.receivedPosInfo!, '1');
          //   await this.getCategoryList(this.receivedPosInfo!, '1');
        }
      }
    });
  }

  async search(item: any) {
    const data = await this.getTotCnt(item, '1');
    console.log('서버에게서 받아온값', data);
    if (data) {
      if (data.header) {
        if (data.header.res_code === 'ok') {
          this.totCnt = data.data[0].cnt;
        } else {
          console.log('에러 > ', data.header.res_msg);
        }
      } else {
        console.log('헤더가 없음');
      }
    } else {
      console.log('data 가 없음');
    }

    const categoryList = await this.getCategoryList(item, '1');
    console.log('categoryList', categoryList);
    if (categoryList) {
      if (categoryList.header) {
        if (categoryList.header.res_code === 'ok') {
          this.categoryList = categoryList.data;
        } else {
          console.log('에러', categoryList.header.res_msg);
          this.categoryList = [];
        }
      } else {
        console.log('헤더가 없음');
        this.categoryList = [];
      }
    } else {
      console.log('리스트가 없음');
      this.categoryList = [];
    }
  }

  async deleteCategory(item: any) {
    try {
      //서버와 통신
      this.startLoading();
      const response = await fetch(
        `${this.utilsService.getAPIsUrl()}/category/deleteCategory?agency=${
          item.AGENCY_CODE
        }
      	&fran=${item.FRANCHISE_CODE}&brand=${item.BRAND_CODE}&store=${
          item.STORE_CD
        }
      	&pos=${item.POS_NO}&lvl=${item.CATEGORYLVL}&code=${
          item.CATEGORYCODE
        }&upper=${item.UPPERCATEGORYCODE}`,
        { method: 'DELETE' }
      );
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async deleteLang(item: any) {
    try {
      this.startLoading();
      //서버와 통신
      const response = await fetch(
        `${this.utilsService.getAPIsUrl()}/lang/deleteLang?agency=${
          item.AGENCY_CODE
        }&fran=${item.FRANCHISE_CODE}&brand=${item.BRAND_CODE}&store=${
          item.STORE_CD
        }&pos=${item.POS_NO}&code=${
          item.CATEGORYCODE
        }&gubun=category&big=&middle=&small=&lvl=${item.CATEGORYLVL}`,
        { method: 'DELETE' }
      );
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  getItemData(item: any) {
    return JSON.stringify(item);
  }

  async onSearch() {
    await this.search(this.receivedPosInfo);
  }

  async onReceivePosInfo(posInfo: PosInfo) {
    this.receivedPosInfo = posInfo;
    console.log('자식에게서 받아온값 ', this.receivedPosInfo);
    await this.search(posInfo);
    // const data = await this.getTotCnt(posInfo, '1');
    // console.log('서버에게서 받아온값', data);
    // if (data) {
    //   if (data.header) {
    //     if (data.header.res_code === 'ok') {
    //       this.totCnt = data.data[0].cnt;
    //     } else {
    //       console.log('에러', data.header.res_msg);
    //     }
    //   } else {
    //     console.log('헤더가 없음');
    //   }
    // } else {
    //   console.log('data 가 없음');
    // }

    // const categoryList = await this.getCategoryList(posInfo, '1');
    // console.log('categoryList', categoryList);
    // if (categoryList) {
    //   if (categoryList.header) {
    //     if (categoryList.header.res_code === 'ok') {
    //       this.categoryList = categoryList.data;
    //     } else {
    //       console.log('에러', categoryList.header.res_msg);
    //     }
    //   } else {
    //     console.log('헤더가 없음');
    //   }
    // } else {
    //   console.log('리스트가 없음');
    // }
  }

  constructor(
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private toastr: ToastrService
  ) {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        ),
        map(() => this.getFullUrl(this.activatedRoute.root))
      )
      .subscribe((url) => {
        this.previousUrl = url;
      });
  }

  async getTotCnt(posInfo: PosInfo, lvl: string) {
    try {
      this.startLoading();
      // 서버와 통신
      const response = await fetch(
        `${this.utilsService.getAPIsUrl()}/category/getCategoryCnt?agency=${
          posInfo.AGENCY_CODE
        }&fran=${posInfo.FRANCHISE_CODE}&brand=${posInfo.BRAND_CODE}&store=${
          posInfo.STORE_CODE
        }&pos=${posInfo.POS_NO}&lvl=${lvl}&category_name=${this.keyword}`
      );
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async getCategoryList(posInfo: PosInfo, lvl: string) {
    try {
      this.startLoading();
      // 서버와 통신
      const response = await fetch(
        `${this.utilsService.getAPIsUrl()}/category/getCategoryList?agency=${
          posInfo.AGENCY_CODE
        }&fran=${posInfo.FRANCHISE_CODE}&brand=${posInfo.BRAND_CODE}&store=${
          posInfo.STORE_CODE
        }&pos=${posInfo.POS_NO}&lvl=${lvl}&category_name=${this.keyword}`
      );
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async getCategoryMiddleList(posInfo: any, upper: string, lvl: string) {
    try {
      // 서버와 통신
      const url = `${this.utilsService.getAPIsUrl()}/category/getCategoryList?agency=${
        posInfo.AGENCY_CODE
      }&fran=${posInfo.FRANCHISE_CODE}&brand=${posInfo.BRAND_CODE}&store=${
        posInfo.STORE_CD
      }&pos=${posInfo.POS_NO}&upper=${upper}&lvl=${lvl}&category_name=${
        this.keyword
      }`;

      console.log('url', url);

      this.startLoading();
      const response = await fetch(url);
      const data = await response.json();
      this.stopLoading();
      return data;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async getOneLang(data: any) {
    try {
      const url = `${this.utilsService.getAPIsUrl()}/lang/selectOneLang?agency=${
        data.AGENCY_CODE
      }&fran=${data.FRANCHISE_CODE}&brand=${data.BRAND_CODE}&store=${
        data.STORE_CD
      }
		&pos=${data.POS_NO}&gubun=category&lvl=${data.CATEGORYLVL}&code=${
        data.CATEGORYCODE
      }`;

      console.log('url', url);

      this.startLoading();
      // 서버와 통신
      const response = await fetch(url);
      const res = await response.json();
      this.stopLoading();
      return res;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async saveCategory(data: any, lvl: string) {
    try {
      const url = `${this.utilsService.getAPIsUrl()}/category/saveCategory`;

      this.startLoading();
      // 서버와 통신
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      this.stopLoading();
      return res;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async editCategory(data: any) {
    try {
      const url = `${this.utilsService.getAPIsUrl()}/category/updateCategory`;

      this.startLoading();
      // 서버와 통신
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      this.stopLoading();
      return res;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async saveLang(code: string, lvl: string) {
    try {
      const url = `${this.utilsService.getAPIsUrl()}/lang/addLang?agency=${
        this.receivedPosInfo?.AGENCY_CODE
      }
	  &fran=${this.receivedPosInfo?.FRANCHISE_CODE}
	  &brand=${this.receivedPosInfo?.BRAND_CODE}
	  &store=${this.receivedPosInfo?.STORE_CODE}
	  &pos=${this.receivedPosInfo?.POS_NO}
	  &code=${code}
	  &gubun=category&big=&middle=&small=
	  &korean=${this.korean}
	  &english=${this.english}
	  &japanese=${this.japanese}
	  &chinese=${this.chinese}
	  &lvl=${lvl}`;

      this.startLoading();
      // 서버와 통신
      const response = await fetch(url, {
        method: 'POST',
      });
      const res = await response.json();
      this.stopLoading();
      return res;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  async editLang(data: any) {
    try {
      const url = `${this.utilsService.getAPIsUrl()}/lang/editLang?agency=${
        data.agency
      }&fran=${data.fran}&brand=${data.brand}&store=${data.store}&pos=${
        data.pos
      }&code=${data.code}
	  &gubun=category&big=&middle=&small=&korean=${data.korean}&english=${
        data.english
      }&japanese=${data.japanese}
	  &chinese=${data.chinese}
	  &lvl=${data.lvl} 
	  `;

      this.startLoading();
      // 서버와 통신
      const response = await fetch(url, {
        method: 'PUT',
      });
      const res = await response.json();
      this.stopLoading();
      return res;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }

  private getFullUrl(route: ActivatedRoute): string {
    if (route.firstChild) {
      return this.getFullUrl(route.firstChild);
    } else {
      const segments: UrlSegment[] = route.snapshot.url;
      return segments.map((segment) => segment.path).join('/');
    }
  }

  onEditChange() {
    console.log('change');

    const wrap = document.getElementById('list_wraps');

    if (wrap) {
      if (this.editMode === true) {
        console.log('수정모드');
        wrap?.classList.remove('modify_mode');
        this.editMode = false;
      } else {
        console.log('수정모드 아님');
        wrap.classList.add('modify_mode');
        this.editMode = true;
      }
    } else {
      console.log('null');
    }

    const editBtn = document.getElementById('editBtn');
    if (editBtn) {
      if (editBtn.classList.contains('cancel')) {
        console.log('cancel');
        editBtn.classList.remove('cancel');
        editBtn.textContent = '편집';
      } else if (editBtn.classList.contains('btn_modify_mode')) {
        console.log('modify_mode');
        editBtn.classList.add('cancel');
        editBtn.textContent = '편집취소';
      }
    }
  }

  ngOnInit(): void {
    console.group('카테고리-대');
    const login_info = window.localStorage.getItem('login_info') || null;
    if (!login_info) {
      this.router.navigate(['/login']);
    }
    console.groupEnd();
  }

  ngAfterViewInit(): void {
    console.log('category ngAfgerViewInit');
    const checkboxes = document.querySelectorAll(
      '.custom_checkbox input[type="checkbox"]'
    );

    console.log('checkbox len', checkboxes.length);

    if (checkboxes.length > 0) {
      checkboxes.forEach((checkbox) => {
        checkbox.addEventListener('change', (event) => {
          const li = (event.target as HTMLInputElement).closest('li');
          if ((event.target as HTMLInputElement).checked) {
            li?.classList.add('select');
          } else {
            li?.classList.remove('select');
          }
        });
      });
    }

    //자바 스크립트 관련
    this.customSelects = Array.from(
      document.querySelectorAll('.custom_select')
    ) as HTMLElement[];
    this.checkboxes = Array.from(
      document.querySelectorAll('.custom_checkbox input[type="checkbox"]')
    ) as HTMLInputElement[];
    this.modifyModeButton = document.querySelector(
      '.btn_modify_mode'
    ) as HTMLButtonElement;
    this.listWrap = document.querySelector('.list_wrap') as HTMLElement;

    this.initCustomSelect();

    //this.initModifyMode();
  }

  initCustomSelect(): void {
    this.customSelects.forEach((select) => {
      const button = select.querySelector(
        '.select_list_open'
      ) as HTMLButtonElement;
      const options = Array.from(
        select.querySelectorAll('.select_list button')
      ) as HTMLButtonElement[];

      button.addEventListener('click', () => {
        this.customSelects.forEach((s) => {
          if (s !== select) {
            console.log('active');
            s.classList.remove('active');
          }
        });
        select.classList.toggle('active');
      });

      options.forEach((option) => {
        option.addEventListener('click', () => {
          console.log('option');
          button.textContent = option.textContent;
          select.classList.remove('active');
        });
      });
    });

    document.addEventListener('click', (event: any) => {
      if (!event.target.closest('.custom_select')) {
        this.customSelects.forEach((select) => {
          select.classList.remove('active');
        });
      }
    });
  }

  initCheckboxes(): void {
    this.checkboxes.forEach((checkbox) => {
      console.log('checkbox');
      checkbox.addEventListener('change', (event) => {
        console.log('change');
        const li = checkbox.closest('li') as HTMLElement;
        //const li = (event.target as HTMLInputElement).closest('li');
        if (li) {
          if (checkbox.checked) {
            li.classList.add('select');
          } else {
            li.classList.remove('select');
          }
        } else {
          console.log('li null');
        }
      });
    });
  }

  initModifyMode(): void {
    this.modifyModeButton.addEventListener('click', () => {
      this.listWrap.classList.toggle('modify_mode');
      if (this.listWrap.classList.contains('modify_mode')) {
        this.modifyModeButton.classList.add('cancel');
        this.modifyModeButton.textContent = '편집취소';
      } else {
        this.modifyModeButton.classList.remove('cancel');
        this.modifyModeButton.textContent = '편집';
      }

      const checkboxes = document.querySelectorAll(
        '.custom_checkbox input[type="checkbox"]'
      );

      checkboxes.forEach((checkbox) => {
        (checkbox as HTMLInputElement).checked = false;
      });
    });
  }

  async popupOpen(name: string) {
    console.log('popupOpen click');
    var popup: any = document.querySelector(name);
    popup.classList.add('active');

    const resNextOrder = await this.getNextOrder(
      this.receivedPosInfo!,
      '1',
      ''
    );

    console.log('수신받은값', resNextOrder);

    if (resNextOrder) {
      if (resNextOrder.header) {
        if (resNextOrder.header.res_code === 'ok') {
          this.order = resNextOrder.data[0].NEXT_DISPORDER;
        } else {
          this.order = '';
        }
      } else {
        this.order = '';
      }
    } else {
      this.order = '';
    }
  }

  popupClose(name: string) {
    console.log('popupClose click');
    var popup: any = document.querySelector(name);
    popup.classList.remove('active');
    this.clearInput();
  }

  goBack() {
    this.router.navigate(['/main']);
  }

  clearInput() {
    this.categoryCode = '';
    this.categoryName = '';
    this.useYN = '';
    this.order = '';
    this.x = '';
    this.y = '';
    this.upper = '';
    this.korean = '';
    this.english = '';
    this.japanese = '';
    this.chinese = '';
  }

  async getNextOrder(item: any, lvl: string, upper: string) {
    try {
      console.log('item', item);

      const url = `${this.utilsService.getAPIsUrl()}/category/getNextOrder?agency=${
        item.AGENCY_CODE
      }&fran=${item.FRANCHISE_CODE}&brand=${item.BRAND_CODE}&store=${
        item.STORE_CODE
      }&pos=${item.POS_NO}&lvl=${lvl}&upper=${upper}
		`;
      console.log(url);
      this.startLoading();
      // 서버와 통신
      const response = await fetch(url, {
        method: 'GET',
      });

      const res = await response.json();
      this.stopLoading();
      return res;
    } catch (error) {
      console.error(error);
      this.stopLoading();
      throw error;
    }
  }
}
