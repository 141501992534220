import { Component } from '@angular/core';

@Component({
  selector: 'app-helps-view',
  templateUrl: './helps-view.component.html',
  styleUrls: ['./helps-view.component.scss']
})
export class HelpsViewComponent {

}
