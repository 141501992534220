import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SideComponent } from './../../commons/side/side.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements AfterViewInit, OnInit {
  @ViewChild(SideComponent) sideComponent!: SideComponent;
  currentUrl!: string;

  customSelects!: HTMLElement[];
  checkboxes!: HTMLInputElement[];
  modifyModeButton!: HTMLButtonElement;
  listWrap!: HTMLElement;

  constructor(private route: ActivatedRoute, private router: Router) {
    //this.currentUrl = this.route.snapshot.url.join('/');
    //console.log('main 생성자 this.currentUrl ', this.currentUrl);
  }

  ngOnInit() {
    console.group('메인');
    const login_info = window.localStorage.getItem('login_info') || null;
    if (!login_info) {
      this.router.navigate(['/login']);
    }
    console.groupEnd();

    this.currentUrl = this.route.snapshot.url.join('/');
    console.log('ngOnInit this.currentUrl ', this.currentUrl);
  }

  ngAfterViewInit(): void {
    console.log('main ngAfgerViewInit');

    const checkboxes = document.querySelectorAll(
      '.custom_checkbox input[type="checkbox"]'
    );

    console.log('checkbox len', checkboxes.length);

    if (checkboxes.length > 0) {
      checkboxes.forEach((checkbox) => {
        checkbox.addEventListener('change', (event) => {
          const li = (event.target as HTMLInputElement).closest('li');
          if ((event.target as HTMLInputElement).checked) {
            li?.classList.add('select');
          } else {
            li?.classList.remove('select');
          }
        });
      });
    }

    //자바 스크립트 관련
    this.customSelects = Array.from(
      document.querySelectorAll('.custom_select')
    ) as HTMLElement[];
    this.checkboxes = Array.from(
      document.querySelectorAll('.custom_checkbox input[type="checkbox"]')
    ) as HTMLInputElement[];
    this.modifyModeButton = document.querySelector(
      '.btn_modify_mode'
    ) as HTMLButtonElement;
    this.listWrap = document.querySelector('.list_wrap') as HTMLElement;

    this.initCustomSelect();
    console.groupEnd();
  }

  initCustomSelect(): void {
    this.customSelects.forEach((select) => {
      const button = select.querySelector(
        '.select_list_open'
      ) as HTMLButtonElement;
      const options = Array.from(
        select.querySelectorAll('.select_list button')
      ) as HTMLButtonElement[];

      button.addEventListener('click', () => {
        this.customSelects.forEach((s) => {
          if (s !== select) {
            console.log('active');
            s.classList.remove('active');
          }
        });
        select.classList.toggle('active');
      });

      options.forEach((option) => {
        option.addEventListener('click', () => {
          console.log('option');
          button.textContent = option.textContent;
          select.classList.remove('active');
        });
      });
    });

    document.addEventListener('click', (event: any) => {
      if (!event.target.closest('.custom_select')) {
        this.customSelects.forEach((select) => {
          select.classList.remove('active');
        });
      }
    });

    // custom select2

    document.querySelectorAll('.select_list_open').forEach((button) => {
      button.addEventListener('click', () => {
        const customSelect = button.closest('.custom_select2');
        if (customSelect) {
          const options = customSelect.querySelectorAll('.select_list button');
          customSelect.classList.add('active');
          options.forEach((option) => {
            option.addEventListener('click', () => {
              button.textContent = option.textContent;
              customSelect.classList.remove('active');
            });
          });
        }
      });
    });

    document.querySelectorAll('.select_close').forEach(function (button) {
      button.addEventListener('click', function () {
        const customSelect = button.closest('.custom_select2');
        if (customSelect) {
          customSelect.classList.remove('active');
        }
      });
    });
  }
}
