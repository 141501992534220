<!DOCTYPE html>
<html lang="ko" xml:lang="ko">
  <head>
    <title>하나시스 상품등록 관리자</title>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta
      name="viewport"
      content="width=device-width, user-scalable=0, initial-scale=1, minimum-scale=1, maximum-scale=1"
    />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="description" content="" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="" />
    <meta property="og:description" content="" />
    <meta property="og:image" content="../img/logo.png" />
    <meta property="og:url" content="" />
    <link rel="canonical" href="" />
    <!-- <link rel="stylesheet" href="./css/help.css" /> -->
  </head>

  <body>
    <div class="top_select_bg">
      <div class="top_select_area">
        <div class="inner">
          <button type="button" class="menu_open"></button>
          <div class="store_list">
            <ul>
              <li>
                <div class="custom_select">
                  <button type="button" class="select_list_open">대리점</button>
                  <div class="select_list">
                    <ul>
                      <li><button type="button">1호점</button></li>
                      <li><button type="button">2호점</button></li>
                      <li><button type="button">3호점</button></li>
                      <li><button type="button">4호점</button></li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <div class="custom_select">
                  <button type="button" class="select_list_open">
                    프랜차이즈
                  </button>
                  <div class="select_list">
                    <ul>
                      <li><button type="button">1호점</button></li>
                      <li><button type="button">2호점</button></li>
                      <li><button type="button">3호점</button></li>
                      <li><button type="button">4호점</button></li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <div class="custom_select">
                  <button type="button" class="select_list_open">브랜드</button>
                  <div class="select_list">
                    <ul>
                      <li><button type="button">1호점</button></li>
                      <li><button type="button">2호점</button></li>
                      <li><button type="button">3호점</button></li>
                      <li><button type="button">4호점</button></li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <div class="custom_select">
                  <button type="button" class="select_list_open">
                    매장선택
                  </button>
                  <div class="select_list">
                    <ul>
                      <li><button type="button">1호점</button></li>
                      <li><button type="button">2호점</button></li>
                      <li><button type="button">3호점</button></li>
                      <li><button type="button">4호점</button></li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <div class="custom_select">
                  <button type="button" class="select_list_open">
                    포스번호
                  </button>
                  <div class="select_list">
                    <ul>
                      <li><button type="button">1호점</button></li>
                      <li><button type="button">2호점</button></li>
                      <li><button type="button">3호점</button></li>
                      <li><button type="button">4호점</button></li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <input type="number" placeholder="사업자번호" />
              </li>
            </ul>
          </div>
        </div>

        <!-- left_menu_area -->
        <div class="left_menu_area">
          <div class="top_area">
            <div class="top_close">
              <button type="button" class="left_menu_close">닫기</button>
            </div>
            <div class="txt">
              하나시스 관리자님 <br />
              안녕하세요.
            </div>
            <div class="btn_area">
              <ul>
                <li><a href="main.html" class="icon_home">홈으로</a></li>
                <li><a href="main.html" class="icon_logout">로그아웃</a></li>
              </ul>
            </div>
          </div>
          <div class="menu_list">
            <ul>
              <li><a href="#">카테고리 관리</a></li>
              <li><a href="#">메뉴 관리</a></li>
              <li><a href="#">상품 관리</a></li>
              <li><a href="#">옵션 관리</a></li>
              <li><a href="#">도움말</a></li>
              <li><a href="#">환경설정</a></li>
            </ul>
          </div>
        </div>
        <!-- //left_menu_area -->
      </div>
    </div>

    <section class="container pb_0">
      <div class="sub_cont">
        <div class="page_top_title">
          <a href="" class="page_back">뒤로</a>
          <h1>도움말</h1>
        </div>
        <!-- search -->
        <div class="search_araa">
          <div class="search">
            <input type="text" placeholder="검색어를 입력해주세요." />
            <button type="button" class="btn_search">검색</button>
          </div>
        </div>
        <!-- //search -->
      </div>
      <div class="gray_bg">
        <div class="sub_cont">
          <div class="help_list">
            <ul>
              <li>
                <a href="help_view.html">
                  <span class="num">01</span>
                  <div class="tit">카테고리 관리 관련</div>
                </a>
              </li>
              <li>
                <a href="help_view.html">
                  <span class="num">02</span>
                  <div class="tit">카테고리 관리 관련</div>
                </a>
              </li>
              <li>
                <a href="help_view.html">
                  <span class="num">03</span>
                  <div class="tit">카테고리 관리 관련</div>
                </a>
              </li>
              <li>
                <a href="help_view.html">
                  <span class="num">04</span>
                  <div class="tit">카테고리 관리 관련</div>
                </a>
              </li>
              <li>
                <a href="help_view.html">
                  <span class="num">05</span>
                  <div class="tit">카테고리 관리 관련</div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <script>
      document.addEventListener("DOMContentLoaded", function () {
        const customSelects = document.querySelectorAll(".custom_select");
        customSelects.forEach((select) => {
          const button = select.querySelector(".select_list_open");
          const options = select.querySelectorAll(".select_list button");
          button.addEventListener("click", () => {
            customSelects.forEach((s) => {
              if (s !== select) {
                s.classList.remove("active");
              }
            });
            select.classList.toggle("active");
          });
          options.forEach((option) => {
            option.addEventListener("click", () => {
              button.textContent = option.textContent;
              select.classList.remove("active");
            });
          });
        });
        document.addEventListener("click", (event) => {
          if (!event.target.closest(".custom_select")) {
            customSelects.forEach((select) => {
              select.classList.remove("active");
            });
          }
        });
        document
          .querySelector(".menu_open")
          .addEventListener("click", function () {
            document.querySelector(".left_menu_area").classList.add("active");
          });
        document
          .querySelector(".left_menu_close")
          .addEventListener("click", function () {
            document
              .querySelector(".left_menu_area")
              .classList.remove("active");
          });
      });

      //popup
      function popup_open(name) {
        var name = document.querySelector(name);
        name.classList.add("active");
      }
      function popup_close(name) {
        var name = document.querySelector(name);
        name.classList.remove("active");
      }
    </script>
  </body>
</html>
