<div class="top_select_bg">
  <app-side (sendPosInfo)="onReceivePosInfo($event)"></app-side>
</div>

<section class="container pb_0">
  <div class="sub_cont">
    <div class="page_top_title">
      <a class="page_back" (click)="goBack()">뒤로</a>
      <h1>상품 관리</h1>
      <div class="ml-auto">
        <button
          type="button"
          class="icon_plus"
          (click)="popup_add_modify('.add_popup')"
        >
          신규 추가
        </button>
      </div>
    </div>
    <!-- search -->
    <div class="search_araa">
      <div class="search">
        <input
          type="text"
          placeholder="검색어를 입력해주세요."
          [(ngModel)]="keyword"
          name="keyword"
          (keyup.enter)="onSearch()"
        />
        <button type="button" class="btn_search" (click)="onSearch()">
          검색
        </button>
      </div>
    </div>
    <!-- //search -->
  </div>
  <div class="list_wrap" id="list_wraps">
    <div class="sub_cont">
      <div class="list_top">
        <div class="total">
          상품 <strong>{{ totCnt }}</strong>
        </div>
        <div class="right_btn">
          <button
            type="button"
            class="btn btn-blue-bd btn_modify_mode"
            (click)="onEditChange()"
            id="editBtn"
          >
            편집
          </button>
        </div>
      </div>
      <!-- 편집모드 -->
      <div class="control_area">
        <div class="custom_checkbox">
          <input type="checkbox" id="allChk" (click)="btnAllClick()" />
          <label for="allChk">전체선택</label>
        </div>
        <button
          type="button"
          class="all_delete"
          (click)="popup_open('.delete_popup')"
        >
          삭제
        </button>
      </div>
      <!-- //편집모드 -->
      <div class="list_area">
        <ul>
          <li *ngFor="let item of goodsList; let i = index">
            <div class="custom_checkbox">
              <input
                type="checkbox"
                [id]="'chk_' + (i + 1)"
                [attr.data-datas]="getItemData(item)"
              />
              <label [for]="'chk_' + (i + 1)"></label>
            </div>
            <div class="inner">
              <div class="img_area">
                <img src="{{ item.ServerFolderPath }}" alt="" />
              </div>
              <div class="num">{{ i + 1 }}</div>
              <div class="txt">
                <span>{{ item.PLU_CD }}</span>
                <strong>{{ item.GOODS_NM }}</strong>
              </div>
            </div>
            <button
              type="button"
              class="btn_modify"
              (click)="popup_open2('.modify_popup', item)"
              [attr.data-datas]="getItemData(item)"
            >
              수정
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="fixed_top_bottom">
    <ul>
      <li><button type="button" class="btn_top"></button></li>
      <li><button type="button" class="btn_down"></button></li>
    </ul>
  </div>

  <!-- 삭제팝업 -->
  <div class="alert_popup delete_popup">
    <div class="popup_content">
      <div class="txt_area">
        <p>리스트를 삭제 하시겠습니까?</p>
      </div>
      <div class="btn_area">
        <ul>
          <li>
            <button
              type="button"
              class="btn btn-gray-bd"
              (click)="popup_close('.delete_popup')"
            >
              취소
            </button>
          </li>
          <li>
            <button
              type="button"
              class="btn btn-blue-bg"
              (click)="popup_ok('.delete_popup')"
            >
              확인
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- //삭제팝업 -->

  <!-- 수정 팝업 -->
  <div class="popup modify_popup">
    <div class="popup_content">
      <div class="title_area">
        <h1>수정</h1>
        <button
          type="button"
          class="btn_close"
          (click)="popup_close('.modify_popup')"
        ></button>
      </div>
      <div class="popup_inner">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent2"
          [maintainAspectRatio]="true"
          [aspectRatio]="3 / 4"
          format="png"
          nClose
          (imageCropped)="imageCropped2($event)"
          (imageLoaded)="imageLoaded2($event)"
          (cropperReady)="cropperReady2()"
          (loadImageFailed)="loadImageFailed2()"
          backgroundColor="white"
        ></image-cropper>
        <div class="photo_area">
          <div class="photo_inner">
            <div>
              <div class="img_area">
                <img [src]="croppedImage2" alt="" #_editImg />
              </div>
              <input
                type="file"
                capture="camera"
                id="photo_registration"
                accept="image/png, image/jpeg, image/gif,image/bmp"
                #_editImgFile
                (change)="onFileImgSelect($event)"
              />
              <label for="photo_registration__" (click)="onImgBtnChange($event)"
                >이미지 변경하기</label
              >
            </div>
          </div>
        </div>
        <ul class="form_list">
          <li>
            <div class="tit">대분류</div>
            <div class="input_area">
              <div class="custom_select2">
                <button
                  type="button"
                  class="select_list_open"
                  #_edit_bigCategory
                  value="{{ selectBigCategoryCode }}"
                >
                  {{ selectBigCategroyName }}
                </button>
                <div class="select_list">
                  <div class="select_close"></div>
                  <ul>
                    <li *ngFor="let item of bigCategoryList; let i = index">
                      <button
                        type="button"
                        value="{{ item.CATEGORYCODE }}"
                        (click)="bigBtnDetailClick(item)"
                      >
                        {{ item.CATEGORYNAME }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>

          <li>
            <div class="tit">중분류</div>
            <div class="input_area">
              <div class="custom_select2">
                <button
                  type="button"
                  class="select_list_open"
                  #_edit_middleCategory
                  value="{{ selectMiddleCategoryCode }}"
                >
                  {{ selectMiddleCategroyName }}
                </button>
                <div class="select_list">
                  <div class="select_close"></div>
                  <ul>
                    <li *ngFor="let item of middleCategoryList; let i = index">
                      <button
                        type="button"
                        value="{{ item.CATEGRORYCODE }}"
                        (click)="middleBtnDetailClick(item)"
                      >
                        {{ item.CATEGORYNAME }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="tit">소분류</div>
            <div class="input_area">
              <div class="custom_select2">
                <button
                  type="button"
                  class="select_list_open"
                  #_edit_smallCategory
                  value="{{ selectSmallCategoryCode }}"
                >
                  {{ selectSmallCategroyName }}
                </button>
                <div class="select_list">
                  <div class="select_close"></div>
                  <ul>
                    <li *ngFor="let item of smallCategoryList; let i = index">
                      <button type="button" value="{{ item.CATEGRORYCODE }}">
                        {{ item.CATEGORYNAME }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>

          <li>
            <div class="tit">상품명</div>
            <div class="input_area">
              <input type="text" #_edit_goods_name />
            </div>
          </li>
          <li class="d-flex">
            <div class="tit">상품명과 동일하게</div>
            <div class="ml-auto" (click)="toggleEditLang()">
              <label class="toggle" for="sameName1">
                <input
                  class="toggle-checkbox"
                  type="checkbox"
                  id="sameName"
                  [(ngModel)]="isEditNameChecked"
                  #_editNameCheck
                />
                <div class="toggle-switch"></div>
                <span class="toggle-on">Y</span>
                <span class="toggle-off">N</span>
              </label>
            </div>
          </li>
          <li>
            <div class="tit">다국어</div>
            <div class="input_area input_list">
              <div class="multilingual">
                <!-- 클래스 추가 -->
                <div>
                  <span class="label_txt">한국어</span>
                  <input type="text" placeholder="한국어" #_edit_korean />
                </div>
                <div>
                  <span class="label_txt">영어</span>
                  <input type="text" placeholder="영어" #_edit_english />
                </div>
                <div>
                  <span class="label_txt">일본어</span>
                  <input type="text" placeholder="일본어" #_edit_japanese />
                </div>
                <div>
                  <span class="label_txt">중국어</span>
                  <input type="text" placeholder="중국어" #_edit_chinese />
                </div>
              </div>
            </div>
          </li>

          <li>
            <div class="tit">상품코드</div>
            <div class="input_area">
              <input type="text" #_edit_goods_code disabled />
            </div>
          </li>
          <li class="d-flex">
            <div class="tit">판매여부</div>
            <div class="ml-auto">
              <label class="toggle" for="use_yn1">
                <input
                  class="toggle-checkbox"
                  type="checkbox"
                  id="use_yn1"
                  #_edit_sale_yn
                />
                <div class="toggle-switch"></div>
                <span class="toggle-on">Y</span>
                <span class="toggle-off">N</span>
              </label>
            </div>
          </li>
          <li class="d-flex">
            <div class="tit">옵션가능</div>
            <div class="ml-auto">
              <label class="toggle" for="use_yn7">
                <input
                  class="toggle-checkbox"
                  type="checkbox"
                  id="use_yn7"
                  #_edit_optEnable
                />
                <div class="toggle-switch"></div>
                <span class="toggle-on">Y</span>
                <span class="toggle-off">N</span>
              </label>
            </div>
          </li>
          <li class="d-flex">
            <div class="tit">성인유무</div>
            <div class="ml-auto">
              <label class="toggle" for="use_yn2">
                <input
                  class="toggle-checkbox"
                  type="checkbox"
                  id="use_yn2"
                  #_edit_adult_yn
                />
                <div class="toggle-switch"></div>
                <span class="toggle-on">Y</span>
                <span class="toggle-off">N</span>
              </label>
            </div>
          </li>
          <li class="d-flex">
            <div class="tit">과세여부</div>
            <div class="radio_list">
              <ul>
                <li>
                  <div class="custom_radio2">
                    <input
                      type="radio"
                      name="taxation"
                      id="taxation_y"
                      checked
                      #_edit_taxYN
                    />
                    <label for="taxation_y">과세</label>
                  </div>
                </li>
                <li>
                  <div class="custom_radio2">
                    <input type="radio" name="taxation" id="taxation_n" />
                    <label for="taxation_n">비과세</label>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div class="tit">가격</div>
            <div class="input_area">
              <input type="number" #_edit_price />
            </div>
          </li>
          <li>
            <div class="tit">할인율</div>
            <div class="input_area">
              <input type="number" #_edit_rate />
            </div>
          </li>
          <li>
            <div class="tit">할인금액</div>
            <div class="input_area">
              <input type="number" #_edit_ratePrice />
            </div>
          </li>
        </ul>
      </div>
      <div class="fixed_btn">
        <div>
          <button type="button" class="btn btn-blue-bg" (click)="onEdit()">
            수정하기
          </button>
        </div>
      </div>
    </div>

    <div class="fixed_top_bottom">
      <ul>
        <li><button type="button" class="btn_top"></button></li>
        <li><button type="button" class="btn_down"></button></li>
      </ul>
    </div>
  </div>
  <!-- //수정 팝업 -->

  <!-- 신규 추가 팝업 -->
  <div class="popup add_popup">
    <div class="popup_content">
      <div class="title_area">
        <h1>신규 추가</h1>

        <button
          type="button"
          class="btn_close"
          (click)="popup_close('.add_popup')"
        ></button>
      </div>
      <div class="popup_inner">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="3 / 4"
          format="png"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded($event)"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
        <div class="photo_area">
          <div class="photo_inner">
            <div>
              <div class="img_area">
                <img alt="" #_add_img [src]="croppedImage" />
                <button
                  type="button"
                  class="img_delete"
                  #_img_del
                  (click)="onImgDel()"
                  [style.display]="
                    isImageDeleteButtonVisible ? 'inline-block' : 'none'
                  "
                >
                  삭제
                </button>
              </div>
              <div class="photo_none">
                <strong>사진등록</strong>
                <span>최대 500k이하</span>
              </div>
              <input
                type="file"
                capture="camera"
                id="photo_registration"
                accept="image/png, image/jpeg, image/gif,image/bmp"
                #_imgFile
                (change)="onFileSelected($event)"
              />
              <label (click)="onFileClick($event)">이미지 등록하기</label>
            </div>
          </div>
        </div>
        <ul class="form_list">
          <li>
            <div class="tit">대분류</div>
            <div class="input_area">
              <div class="custom_select2">
                <button
                  type="button"
                  class="select_list_open"
                  #_bigCategory
                  value="{{ selectBigCategoryCode }}"
                >
                  {{ selectBigCategroyName }}
                </button>
                <div class="select_list">
                  <div class="select_close"></div>
                  <ul>
                    <li *ngFor="let item of bigCategoryList; let i = index">
                      <button
                        type="button"
                        value="{{ item.CATEGORYCODE }}"
                        (click)="bigBtnDetailClick(item)"
                      >
                        {{ item.CATEGORYNAME }}
                      </button>
                    </li>
                    <!-- <li><button type="button">한솔전자담배</button></li>
                    <li><button type="button">한솔전자담배1</button></li>
                    <li><button type="button">한솔전자담배2</button></li> -->
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="tit">중분류</div>
            <div class="input_area">
              <div class="custom_select2">
                <button
                  type="button"
                  class="select_list_open"
                  #_middleCategory
                  value="{{ selectMiddleCategoryCode }}"
                >
                  {{ selectMiddleCategroyName }}
                </button>
                <div class="select_list">
                  <div class="select_close"></div>
                  <ul>
                    <li *ngFor="let item of middleCategoryList; let i = index">
                      <button
                        type="button"
                        value="{{ item.CATEGRORYCODE }}"
                        (click)="middleBtnDetailClick(item)"
                      >
                        {{ item.CATEGORYNAME }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="tit">소분류</div>
            <div class="input_area">
              <div class="custom_select2">
                <button
                  type="button"
                  class="select_list_open"
                  #_smallCategory
                  value="{{ selectSmallCategoryCode }}"
                >
                  {{ selectSmallCategroyName }}
                </button>
                <div class="select_list">
                  <div class="select_close"></div>
                  <ul>
                    <li *ngFor="let item of smallCategoryList; let i = index">
                      <button type="button" value="{{ item.CATEGRORYCODE }}">
                        {{ item.CATEGORYNAME }}
                      </button>
                    </li>
                    <!-- <li><button type="button">말보로담배</button></li>
                    <li><button type="button">말보로담배1</button></li>
                    <li><button type="button">말보로담배2</button></li> -->
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="tit">상품명</div>
            <div class="input_area">
              <input type="text" #_goodsName />
            </div>
          </li>
          <li class="d-flex">
            <div class="tit">상품명과 동일하게</div>
            <div class="ml-auto" (click)="toggleLang()">
              <label class="toggle" for="sameName1">
                <input
                  class="toggle-checkbox"
                  type="checkbox"
                  id="sameName"
                  [(ngModel)]="isNameChecked"
                  #_sameName
                />
                <div class="toggle-switch"></div>
                <span class="toggle-on">Y</span>
                <span class="toggle-off">N</span>
              </label>
            </div>
          </li>
          <li>
            <div class="tit">다국어</div>
            <div class="input_area input_list">
              <div class="multilingual">
                <!-- 클래스 추가 -->
                <div>
                  <span class="label_txt">한국어</span
                  ><input type="text" placeholder="한국어" #_korean />
                </div>
                <div>
                  <span class="label_txt">영어</span
                  ><input type="text" placeholder="영어" #_english />
                </div>
                <div>
                  <span class="label_txt">일본어</span
                  ><input type="text" placeholder="일본어" #_japanese />
                </div>
                <div>
                  <span class="label_txt">중국어</span
                  ><input type="text" placeholder="중국어" #_chinese />
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="tit">상품코드</div>
            <div class="input_area">
              <input type="text" #_itemCode disabled />
            </div>
          </li>
          <li class="d-flex">
            <div class="tit">판매여부</div>
            <div class="ml-auto">
              <label class="toggle" for="use_yn4">
                <input
                  class="toggle-checkbox"
                  type="checkbox"
                  id="use_yn4"
                  checked
                  #_useYN
                />
                <div class="toggle-switch"></div>
                <span class="toggle-on">Y</span>
                <span class="toggle-off">N</span>
              </label>
            </div>
          </li>
          <li class="d-flex">
            <div class="tit">옵션가능</div>
            <div class="ml-auto">
              <label class="toggle" for="use_yn5">
                <input
                  class="toggle-checkbox"
                  type="checkbox"
                  id="use_yn5"
                  #_optEnable
                />
                <div class="toggle-switch"></div>
                <span class="toggle-on">Y</span>
                <span class="toggle-off">N</span>
              </label>
            </div>
          </li>
          <li class="d-flex">
            <div class="tit">성인유무</div>
            <div class="ml-auto">
              <label class="toggle" for="use_yn6">
                <input
                  class="toggle-checkbox"
                  type="checkbox"
                  id="use_yn6"
                  #_adultYN
                />
                <div class="toggle-switch"></div>
                <span class="toggle-on">Y</span>
                <span class="toggle-off">N</span>
              </label>
            </div>
          </li>
          <li class="d-flex">
            <div class="tit">과세여부</div>
            <div class="radio_list">
              <ul>
                <li>
                  <div class="custom_radio2">
                    <input
                      type="radio"
                      name="taxation"
                      id="taxation_y"
                      checked
                      #_taxYN
                    />
                    <label for="taxation_y">과세</label>
                  </div>
                </li>
                <li>
                  <div class="custom_radio2">
                    <input type="radio" name="taxation" id="taxation_n" />
                    <label for="taxation_n">비과세</label>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div class="tit">가격</div>
            <div class="input_area">
              <input type="number" #_price />
            </div>
          </li>
          <li>
            <div class="tit">할인율</div>
            <div class="input_area">
              <input type="number" #_rate />
            </div>
          </li>
          <li>
            <div class="tit">할인금액</div>
            <div class="input_area">
              <input type="number" #_ratePrice />
            </div>
          </li>
        </ul>
      </div>
      <div class="fixed_btn">
        <div>
          <button type="button" class="btn btn-blue-bg" (click)="onBtnSave()">
            등록하기
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- //신규 추가 팝업 -->

  <!-- 상세 팝업 -->
  <div class="popup detail_popup">
    <div class="popup_content">
      <div class="title_area">
        <button
          type="button"
          class="page_back"
          onclick="popup_close('.detail_popup');"
        ></button>
        <h1>한솔전자담배</h1>
        <button type="button" class="btn_modify">수정</button>
      </div>
      <div class="popup_inner">
        <div class="img_area">
          <img src="../../../assets/img/menu_img.png" alt="" />
        </div>
        <div class="detail_list">
          <ul>
            <li>
              <div class="tit">상품코드</div>
              <div class="txt">254122</div>
            </li>
            <li>
              <div class="tit">상품명</div>
              <div class="txt">한솔전자담배</div>
            </li>
            <li>
              <div class="tit">사용여부</div>
              <div class="txt">예</div>
            </li>
            <li>
              <div class="tit">성인유무</div>
              <div class="txt">예</div>
            </li>
            <li>
              <div class="tit">순서</div>
              <div class="txt">한솔전자담배</div>
            </li>
            <li>
              <div class="tit">크기</div>
              <div class="txt">X: 4 Y: 4</div>
            </li>
            <li>
              <div class="tit">재고수량</div>
              <div class="txt">20</div>
            </li>
            <li>
              <div class="tit">해당상품 위치</div>
              <div class="txt">X: 4 Y: 4</div>
            </li>
            <li>
              <div class="tit">상태</div>
              <div class="txt">신규</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- //상세 팝업 -->
  <!-- loding -->
  <div [class]="'loding ' + (isLoading ? 'active' : '')">
    <!-- <div class="active"> -->
    <!-- active 클래스 추가시 활성화 -->
    <div>
      <div class="loader"></div>
      <span class="loding_txt">Loding...</span>
    </div>
  </div>
  <!-- //loding -->
</section>
