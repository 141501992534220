import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryComponent } from './category/category.component';
import { GoodsComponent } from './goods/goods.component';
import { MygoodsComponent } from './mygoods/mygoods.component';
import { GoodsRoutingModule } from './goods-routing.module';
import { COMPOSITION_BUFFER_MODE, FormsModule } from '@angular/forms';
import { SideComponent } from '../commons/side/side.component';
import { SharedModule } from '../shared/shared.module';
import { MiddleCategoryComponent } from './middle-category/middle-category.component';
import { SmallCategoryComponent } from './small-category/small-category.component';
import {
  ImageCropperComponent,
  ImageCroppedEvent,
  LoadedImage,
} from 'ngx-image-cropper';

@NgModule({
  declarations: [
    CategoryComponent,
    GoodsComponent,
    MygoodsComponent,
    MiddleCategoryComponent,
    SmallCategoryComponent,
  ],
  imports: [
    CommonModule,
    GoodsRoutingModule,
    FormsModule,
    SharedModule,
    ImageCropperComponent,
  ],
  providers: [{ provide: COMPOSITION_BUFFER_MODE, useValue: false }],
  schemas: [NO_ERRORS_SCHEMA],
})
export class GoodsModule {}
