import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { COMPOSITION_BUFFER_MODE, FormsModule } from '@angular/forms';
import { SideComponent } from '../commons/side/side.component';

@NgModule({
  declarations: [SideComponent],
  imports: [CommonModule, FormsModule],
  providers: [{ provide: COMPOSITION_BUFFER_MODE, useValue: false }],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [SideComponent],
})
export class SharedModule {}
